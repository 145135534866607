import { Component, OnInit } from '@angular/core';

import { LoginService } from 'src/app/service/login.service';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
	items: ToolbarItem[] = [
		{
			label: 'Sources',
			icon: 'article',
			route: '/source/list',
			showOnMobile: true
		},
		{
			label: 'Categories',
			icon: 'category',
			route: '/category/list',
			showOnMobile: false
		},
		{
			label: 'Stories',
			icon: 'auto_stories',
			route: '/story/list',
			showOnMobile: false
		}
	]

	moreItems: ToolbarItem[] = [
		{
			label: 'Authors',
			icon: 'history_edu',
			route: '/author/list',
			showOnMobile: true
		},
		{
			label: 'Bookmarks',
			icon: 'bookmarks',
			route: '/bookmarks/list',
			showOnMobile: true
		},
		{
			label: 'Organizations',
			icon: 'account_tree',
			route: '/organization/list',
			showOnMobile: true
		},
		{
			label: 'References',
			icon: 'link',
			route: '/reference/list',
			showOnMobile: true
		},
		{
			label: 'About',
			icon: 'info',
			route: '/about',
			showOnMobile: true
		},
		{
			label: 'Feedback',
			icon: 'rate_review',
			route: '/survey',
			showOnMobile: true
		}
	]

	constructor(private loginService: LoginService) { }

	ngOnInit(): void {
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

}

export interface ToolbarItem {
	label: string;
	icon: string;
	route: string;
	showOnMobile: boolean;
}