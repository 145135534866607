import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PaginatorComponent } from '../paging/paginator.component';

import { StorageService, StorageProperty } from 'src/app/service/storage.service';
import { OrganizationService } from '../../service/organization.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { Organization } from '../../model/organization';

import { LoginService } from 'src/app/service/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-organization-list',
	templateUrl: './organization-list.component.html',
	styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent extends PaginatorComponent<Organization> implements OnInit {
	orgList: Organization[] = [];
	orgColumns: string[] = ['name', 'site'];

	constructor(private orgService: OrganizationService,
				private storageService: StorageService,
				private navigationService: NavigationService,
				private loginService: LoginService,
				private snackBar: MatSnackBar,
				private router: Router) {
					super();
				}

	public ngOnInit(): void {
		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			//
			// page
			const pageIndex = this.storageService.getItem<number>(StorageProperty.ORGANIZATION_PAGE_INDEX);
			if (pageIndex) {
				this.pageIndex = pageIndex;
			}		
		}
		else {
			this.storageService.clearItem(StorageProperty.ORGANIZATION_PAGE_INDEX);
		}

		super.ngOnInit();
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	public onOrganizationSelected(organization: Organization): void {
		this.router.navigate(['/organization', organization.id]);
	}

	protected getData(): void {
		this.isLoading = true;
		this.orgService.getList(this.pageIndex + 1, this.pageSize).subscribe({
			next: (response) => {
				this.loadData(response);			
			},
			error: (error) => {
				console.error('Error:', error.message);
				this.snackBar.open(error.message, 'Dismiss', {
					duration: 2000
				});
			},
			complete: () => {
				this.isLoading = false;
			}
		});
	}

    protected onPageChange(pageIndex: number): void {
		this.storageService.setItem(StorageProperty.ORGANIZATION_PAGE_INDEX, pageIndex);
	}

}
