import { Component, ErrorHandler, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginService } from '../../service/login.service';
import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	form: FormGroup;
	userId: string;
	password: string;
	constructor(private loginService: LoginService,
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private router: Router) { }

	ngOnInit() {
		this.form = this.formBuilder.group({
			login: ['', Validators.required],
			password: ['', Validators.required]
		});
	}

	login(): void {
		const login = this.form.controls.login.value;
		const password = this.form.controls.password.value;

		this.loginService.login(login, password).subscribe(
			_response => {
				this.router.navigate(['']);
			},
			error => { 
				ErrorHelper.handleError(error, this.snackBar);
			 }
		);
	}
}
