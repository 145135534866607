import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Info, InfoFilters } from '../model/info';
import { PagingResponse } from 'src/app/model/paging-response';

import { TagUtility } from '../model/tag';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class InfoserviceService {
	private baseUrl: string = environment.serverUrl;

	constructor(protected http: HttpClient) { }

	public getById(id: number): Observable<Info> {
		const url = `${this.baseUrl}/info/${id}`;

		return this.http.get<Info>(url);
	}

	public getList(
		page: number = 1,
		limit: number = 10,
		filters?: InfoFilters
	): Observable<PagingResponse<Info>> {
		let params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		if (filters) {
			if (filters.tags) {
				filters.tags.forEach(tag => {
					params = params.append('tags', tag.name);
				});
			}
			
			if (filters.sourceTags) {
				filters.sourceTags.forEach(tag => {
					params = params.append('sourceTags', tag.name);
				});
			}
		}

		return this.http.get<PagingResponse<Info>>(`${this.baseUrl}info`, { params });
	}

	public save(sourceId: number, info: Info): Observable<Info> {
		//
		// sort and uppercase all tags
		info.tags = TagUtility.sortAndUpperCase(info.tags);

		return this.http.post<Info>(`${this.baseUrl}/sources/${sourceId}/info`, info);
	}

	public update(info: Info): Observable<Info> {
				const url = `${this.baseUrl}info/${info.id}`;

		//
		// sort and uppercase all tags
		info.tags = TagUtility.sortAndUpperCase(info.tags);
				console.log(url);
		return this.http.put<Info>(`${this.baseUrl}info/${info.id}`, info);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}info/${id}`;

		return this.http.delete(url);
	}
}