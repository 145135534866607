import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	public setItem(key: string, value: any): void {
		window.sessionStorage.setItem(key, JSON.stringify(value));
	}

	public getItem<T>(key: string): T | null {
		try {
			return JSON.parse(window.sessionStorage.getItem(key)!) as T;
		}
		catch (error) {
			return null;
		}
	}
	
	public clearItem(key: string): void {
		window.sessionStorage.removeItem(key);
	}
}

export enum StorageProperty {
	AUTHOR_PAGE_INDEX = 'AUTHOR_PAGE_INDEX',
	AUTHOR_FILTERS = 'AUTHOR_FILTERS',
	BOOKMARKS_PAGE_INDEX = 'BOOKMARKS_PAGE_INDEX',
	CATEGORY_PAGE_INDEX = 'CATEGORY_PAGE_INDEX',
	CATEGORY_TAG_FILTER = 'CATEGORY_TAG_FILTER',
	CATEGORY_FILTERS = 'CATEGORY_FILTERS',
	INFO_FILTERS = 'INFO_FILTER',
	INFO_TAGS_FILTER = 'INFO_TAGS_FILTER',
	INFO_SOURCE_TAGS_FILTER = 'INFO_SOURCE_TAGS_FILTER',
	INFO_PAGE_INDEX = 'INFO_PAGE_INDEX',
	ORGANIZATION_PAGE_INDEX = 'INFO_PAGE_INDEX',
	ORGANIZATION_FILTERS = 'ORGANIZATION_FILTERS',
	REFERENCE_FILTERS = 'REFERENCE_FILTERS',
	REFERENCE_RANK_FILTER = 'REFERENCE_RANK_FILTER',
	REFERENCE_STATUS_FILTER = 'REFERENCE_STATUS_FILTER',
	REFERENCE_TAGS_FILTER = 'REFERENCE_TAGS_FILTER',
	REFERENCE_PAGE_INDEX = 'REFERENCE_PAGE_INDEX',
	SOURCE_FILTERS = 'SOURCE_FILTERS',
	SOURCE_TAGS_FILTER = 'SOURCE_TAGS_FILTER',
	SOURCE_AUTHOR_FILTER = 'SOURCE_AUTHOR_FILTER',
	SOURCE_ORGANIZATION_FILTER = 'SOURCE_ORGANIZATION_FILTER',
	SOURCE_PAGE_INDEX = 'SOURCE_PAGE_INDEX',
	STORY_CATEGORY_FILTER = 'STORY_CATEGORY_FILTER',
	SOURCE_RANK_FILTER = 'SOURCE_RANK_FILTER',
	STORY_PAGE_INDEX = 'STORY_PAGE_INDEX',
	USER = 'USER'
}