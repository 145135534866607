import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Bookmarks } from '../model/bookmarks';
import { User } from '../model/user';

import { PagingResponse } from '../model/paging-response';

import { environment } from 'src/environments/environment';
import { Info } from '../model/info';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	})
};

@Injectable({
	providedIn: 'root'
})
export class BookmarksService {
	private baseUrl: string = environment.serverUrl + 'bookmarks';

	constructor(protected http: HttpClient) {};

	public getById(id: number): Observable<Bookmarks> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.get<Bookmarks>(url);
	}

	public getList(page: number = 1, limit: number = 10): Observable<PagingResponse<Bookmarks>> {
		const params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		return this.http.get<PagingResponse<Bookmarks>>(`${this.baseUrl}`, { params });
	}

	public getExcerptsById(id: number): Observable<Info[]> {
		const url = `${this.baseUrl}/${id}/excerpts`;

		return this.http.get<Info[]>(url);
	}

	public search(user: User): Observable<Bookmarks[]> {
		const url = `${this.baseUrl}/${user.id}`;

		return this.http.get<Bookmarks[]>(url, httpOptions);
	}

	public searchByLabel(label: string): Observable<Bookmarks[]> {
		const url = `${this.baseUrl}/label/${label.toUpperCase()}`;

		return this.http.get<Bookmarks[]>(url, httpOptions);
	}

	public save(bookmarks: Bookmarks): Observable<Bookmarks> {
		return this.http.post<Bookmarks>(`${this.baseUrl}`, bookmarks);
	}

	public update(bookmarks: Bookmarks): Observable<Bookmarks> {
		const url = `${this.baseUrl}/${bookmarks.id}`;

		return this.http.put<Bookmarks>(url, bookmarks);
	}

	public updateSources(bookmarksId: number, sourceIds: number[]): Observable<void> {
		const url = `${this.baseUrl}/${bookmarksId}/sources`;

		return this.http.put<void>(url, { sourceIds }, httpOptions);
	}

	public updateSourceBits(bookmarksId: number, infoIds: number[]): Observable<void> {
		const url = `${this.baseUrl}/${bookmarksId}/info`;

		return this.http.put<void>(url, { infoIds }, httpOptions);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.delete(url);
	}

}
