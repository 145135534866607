import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hostname'
})
export class HostnamePipe implements PipeTransform {

	transform(value: unknown, ...args: unknown[]): unknown {
		if (value) {
			const url = new URL(value as string);

			return url.hostname;
		}
	}

}
