import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Tag } from 'src/app/model/tag';

@Injectable({
	providedIn: 'root'
})
export class TagService {
	private baseUrl: string = environment.serverUrl + 'tags';

	constructor(private http: HttpClient) { }

	public search(query: string): Observable<Tag[]> {
		const url = `${this.baseUrl}/search/all`;
		const params = new HttpParams()
			.set('query', query.toUpperCase());

		return this.http.get<Tag[]>(url, { params });
	}

	public searchAllTags(query: string): Observable<Tag[]> {
		const url = `${this.baseUrl}/search/all`;
		const params = new HttpParams()
			.set('query', query.toUpperCase());

		return this.http.get<Tag[]>(url, { params });
	}

	public searchBookmarksTags(query: string): Observable<Tag[]> {
		const url = `${this.baseUrl}/search/bookmarks`;
		const params = new HttpParams()
			.set('query', query.toUpperCase());

		return this.http.get<Tag[]>(url, { params });
	}

	public searchCategoryTags(query: string): Observable<Tag[]> {
		const url = `${this.baseUrl}/search/category`;
		const params = new HttpParams()
			.set('query', query.toUpperCase());

		return this.http.get<Tag[]>(url, { params });
	}

	public searchInfoTags(query: string): Observable<Tag[]> {
		const url = `${this.baseUrl}/search/info`;
		const params = new HttpParams()
			.set('query', query.toUpperCase());

		return this.http.get<Tag[]>(url, { params });
	}

	public searchSourceTags(query: string): Observable<Tag[]> {
		const url = `${this.baseUrl}/search/source`;
		const params = new HttpParams()
			.set('query', query.toUpperCase());

		return this.http.get<Tag[]>(url, { params });
	}

	public searchReferenceTags(query: string): Observable<Tag[]> {
		const url = `${this.baseUrl}/search/reference`;
		const params = new HttpParams()
			.set('query', query.toUpperCase());

		return this.http.get<Tag[]>(url, { params });
	}

}
