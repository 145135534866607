import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { LogoutComponent } from './component/login/logout.component';
import { RegisterComponent } from './component/register/register.component';
import { AboutComponent } from './component/about/about.component';
import { HomeComponent } from './component/home/home.component';
import { SurveyComponent } from './component/survey/survey.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'home',
		redirectTo: '',
		pathMatch: 'full'
	},
	{
		path: 'about',
		component: AboutComponent
	},
	{
		path: 'author',
		loadChildren: () => import('./component/author/author.module').then(m => m.AuthorModule)
	},
	{
		path: 'bookmarks',
		loadChildren: () => import('./component/bookmarks/bookmarks.module').then(m => m.BookmarksModule)
	},
	{
		path: 'category',
		loadChildren: () => import('./component/category/category.module').then(m => m.CategoryModule)
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'logout',
		component: LogoutComponent
	},
	{
		path: 'organization',
		loadChildren: () => import('./component/organization/organization.module').then(m => m.OrganizationModule)
	},
	{
		path: 'reference',
		loadChildren: () => import('./component/reference/reference.module').then(m => m.ReferenceModule)
	},
	{
		path: 'source',
		loadChildren: () => import('./component/source/source.module').then(m => m.SourceModule)
	},
	{
		path: 'story',
		loadChildren: () => import('./component/story/story.module').then(m => m.StoryModule)
	},
	{
		path: 'register',
		component: RegisterComponent
	},
	{
		path: 'survey',
		component: SurveyComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
