import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrganizationComponent } from './organization.component';
import { OrganizationListComponent } from './organization-list.component';
import { OrganizationEditComponent } from './organization-edit.component';

const routes: Routes = [
	{ path: 'organization/list', component: OrganizationListComponent },
	{ path: 'organization/add', component: OrganizationEditComponent },
	{ path: 'organization/:id', component: OrganizationComponent },
	{ path: 'organization/edit/:id', component: OrganizationEditComponent }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})
export class OrganizationRoutingModule { }