import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from 'src/app/model/category';
import { FormControl } from '@angular/forms';
import { CategoryService } from 'src/app/service/category.service';
import { startWith, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

@Component({
	selector: 'app-category-search',
	templateUrl: './category-search.component.html',
	styleUrls: ['./category-search.component.scss']
})
export class CategorySearchComponent implements OnInit {
	categories: Observable<Category[]>;
	searchField: FormControl = new FormControl();
	isInputVisible: boolean = false;

	@Output()
	select: EventEmitter<Category> = new EventEmitter<Category>();

	constructor(private categoryService: CategoryService) { }

	public ngOnInit(): void {
		this.categories = this.searchField.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(name => name && name.length > 0),
			switchMap(name => this.categoryService.searchByName(name.toUpperCase()))
		);
	}
	
	public onSelect(category: Category): void {
		this.searchField.setValue(category.name);
		this.select.emit(category);
	}

	public toggleInputVisibility() {
		this.isInputVisible = !this.isInputVisible;
	}
}
