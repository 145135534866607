<div class="report-card" fxLayout="column" fxLayoutAlign="space-between start">
    <table>
        <thead>
            <tr class="header-row">
                <th class="category-name">Category</th>
                <th class="rank" *ngFor="let i of [1, 2, 3, 4, 5]; let idx = index">
                    <fa-icon class="icon-outline" [icon]="[getIcon(idx+1)[0], getIcon(idx+1)[1]]" [style.color]="getIcon(idx+1)[2]" size="xl"></fa-icon>
                </th>
                <th *ngIf="editable">&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let reputation of getDisplayedReputations(); index as idx">
                <td [ngClass]="['category-name', getCategoryClass(reputation.category)]" [ngStyle]="{'padding-left': getCategoryLevel(reputation.category) + 'px'}">
                    {{ reputation.category?.name || '&nbsp;' }}
                </td>
                <td *ngFor="let rank of [RankEnum.AWFUL, RankEnum.BAD, RankEnum.NEUTRAL, RankEnum.GOOD, RankEnum.GREAT]; let rIdx = index">
                    <mat-icon>{{ getRankIcon(reputation.rank, rank) }}</mat-icon>
                </td>
                <td *ngIf="editable" (click)="onDelete(idx)">
                    <mat-icon>delete</mat-icon>
                </td>
            </tr>
        </tbody>
    </table>
    <mat-paginator [length]="totalSize" [pageSize]="pageSize" [hidePageSize]="true" (page)="handlePageEvent($event)"></mat-paginator>
</div>
