<mat-accordion cdkDropList (cdkDropListDropped)="onDrop($event)">
	<mat-expansion-panel *ngFor="let info of infoList" cdkDrag>
		<mat-expansion-panel-header>
			<mat-panel-title>
				{{info.summary}}
			</mat-panel-title>
			<mat-panel-description>
				<mat-chip-listbox>
					<ng-container *ngFor="let tag of info.tags">
						<mat-chip-option color="primary">{{tag}}</mat-chip-option>
					</ng-container>
				</mat-chip-listbox>	
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div class="info-detail">
			<markdown [data]="info.detail"></markdown>
			<p>
				<a [routerLink]="['/source', info.source.id]">{{info.source.reference}}</a>
			</p>
		</div>

		<mat-action-row *ngIf="editable">
			<button mat-button color="primary" (click)="editInfo(info)">Edit</button>
			<button mat-button color="primary" (click)="onDelete(info)">Delete</button>
		</mat-action-row>
	</mat-expansion-panel>
</mat-accordion>
