import { Info } from './info';
import { Source } from './source';
import { Taggable } from './tag';
import { User } from './user';

export class Bookmarks extends Taggable {
    id: number;
	user: User;
	label: string;
	
	sources: Source[] = [];
	sourceBits: Info[] = [];
}