import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Subscription, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';

import { InfoserviceService } from 'src/app/service/infoservice.service';
import { ReferenceService } from 'src/app/service/reference.service';
import { SourceService } from 'src/app/service/source.service';
import { BookmarksService } from 'src/app/service/bookmarks.service';

import { Info } from 'src/app/model/info';
import { Source, SourceDetails, SourceStatus } from 'src/app/model/source';
import { Organization } from 'src/app/model/organization';

import { InfoDialogComponent } from 'src/app/component/info-dialog/info-dialog.component';
import { HtmlInputDialogComponent } from './html-input-dialog.component';
import { BookmarksButtonEvent } from 'src/app/component/bookmarks/bookmarks-button.component';
import { Tag, TagType } from 'src/app/model/tag';

import { ErrorHelper } from 'src/app/helpers/error-helper';
import { Author } from 'src/app/model/author';

@Component({
	selector: 'app-source',
	templateUrl: './source-edit.component.html',
	providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class SourceEditComponent implements OnInit, OnDestroy {
	url: string;

	sourceId: string;
	source: Source;
	details: SourceDetails;

	entityFrequencies: number[] = [];
	entityMinCount: number = 0;
	keywordFrequencies: number[] = [];
	keywordMinCount: number = 0;

	private subscriptions: Subscription[] = [];

	ranks: number[] = [1, 2, 3, 4, 5];
	
	statusEnum = SourceStatus;
	status = [];
	tagType: TagType = TagType.All;
	
	referenceId: number;

	inProgress: boolean = false;

	constructor(private sourceService: SourceService,
		private infoService: InfoserviceService,
		private referenceService: ReferenceService,
		private bookmarksService: BookmarksService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location) {
		//
		// create source status enums
		const statusKeys = Object.keys(this.statusEnum);
		for (const k of statusKeys) {
			this.status.push({ key: k, value: this.statusEnum[k] });
		}		

		this.source = new Source();
		this.source.authors = [];	
		this.source = new Source();
		this.source.authors = [];		
	}

	ngOnInit(): void {
		const id = this.activatedRoute.snapshot.params['id'];
		const referenceId = this.activatedRoute.snapshot.queryParams['referenceId'];

		if (id) {
			this.sourceService.getById(id).subscribe({
				next: (response) => {
					this.source = response ? plainToClass(Source, response) : new Source();
				},
				error: (error) => {
					ErrorHelper.handleError(error, this.snackBar);
				}
			});
		} 
		else if (referenceId) {
			this.loadReference(referenceId);
		}
		else {
			this.source = new Source();
			this.source.authors = [];
		}
	}

	private loadReference(id: number): void {
		this.referenceService.getById(id).subscribe({
			next: (response) => {
				const reference = response;
				const source: Source = new Source();
	
				source.reference = reference.reference;
				source.tags = reference.tags;
				source.title = reference.title;
				source.rank = reference.rank;
				source.authors = [];
				
				this.source = source;
				this.referenceId = reference.id;

				//
				// set rank based on HI, LOOK, GOOD				
				if (!this.source.rank) {
	
					if (this.source.tags.some(tag => tag.name === 'HI')) {
						this.source.rank = 5;
					}
					else if (this.source.tags.some(tag => tag.name === 'LOOK')) {
						this.source.rank = 4;
					}
					else {
						this.source.rank = 3;
					}
				}
				
				//
				// filter out "rank" tags	
				const TAGS = ['HI', 'LOOK', 'GOOD', 'READ'];
				this.source.tags = this.source.tags.filter(tag => !TAGS.includes(tag.name));
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	onSave(): void {
		this.sourceService.save(this.source).pipe(
			// Use switchMap to chain the deletion operation after save, but only if referenceId is defined
			switchMap(response => {
				this.source = plainToClass(Source, response);
				this.snackBar.open('Source saved', 'Dismiss', { duration: 1000 });

				//
				// delete reference if it was passed in
				if (this.referenceId) {
					return this.referenceService.delete(this.referenceId);
				}
				else {
					return of(null);
				}
			})
		).subscribe({
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	onUpdate(): void {
		this.sourceService.update(this.source).subscribe({
			next: (response) => {
				this.source = plainToClass(Source, response);
				this.snackBar.open('Source updated', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onDelete(): void {
		this.sourceService.delete(this.source.id).subscribe({
			next: () => {
				this.router.navigate(['source/list']);
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onCancel(): void {
		this.location.back();
	}

	public onRemoveOrganization(): void {
		this.source.organization = null;
	}

	public onNewOrganization(organization: Organization): void {
		this.source.organization = organization;
	}

	public onAddTag(tag: Tag): void {
		this.source.addTag(tag);
	}

	public onRemoveTag(tag: Tag): void {
		this.source.removeTag(tag);	
	}

	public addInfo(): void {
		const dialogRef = this.dialog.open(InfoDialogComponent, {
			width: '500px',
			data: { sourceId: this.source.id }
		});

		dialogRef.afterClosed().subscribe(info => {
			if (info) {
				this.source.infoList ??= [];
				this.source.infoList.push(info);
				this.source.infoList = [].concat(this.source.infoList);
			}
		});
	}

	public editInfo(info: Info): void {
		const dialogRef = this.dialog.open(InfoDialogComponent, {
			width: '500px',
			data: { source: this.source, info: info }
		});

		dialogRef.afterClosed().subscribe(result => {
			const index = this.source.infoList.findIndex(info => info.id == result._id);
			this.source.infoList[index] = result;
		});		
	}

	public deleteInfo(info: Info): void {
		this.infoService.delete(info.id).subscribe({
			next: () => {
				const index = this.source.infoList.indexOf(info);

				if (index >= 0) {
					this.source.infoList.splice(index, 1);
					this.source.infoList = [].concat(this.source.infoList);
				}
				
				this.snackBar.open('Info deleted', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		})
	}

	public onBookmark(event: BookmarksButtonEvent): void {
		this.bookmarksService.updateSources(event.bookmark.id, [this.source.id]).subscribe({
			next: (response) => { 
				this.snackBar.open('Bookmarks updated', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}			
		});
	}

	public onGetDetails(): void {
		this.inProgress = true;

		//
		// check to see if the source already exists
		this.sourceService.getByReference(this.source.reference).pipe(
			switchMap(response => {
				this.source = plainToClass(Source, response);
				return of(null);
			}),
			catchError(error => {
				if (error.status === 404) {
					// If the source is not found, get details
					return this.getDetailsByUrl();
				}
				else {
					ErrorHelper.handleError(error, this.snackBar);
					return of(null);
				}
			}),
			finalize(() => this.inProgress = false)
		).subscribe();
	}

	private getDetailsByUrl() {
		return this.sourceService.getDetailsByUrl(this.source.reference).pipe(
			switchMap((details: SourceDetails) => this.populateSourceDetails(details)),
			catchError(error => this.handleErrorAndPromptHtmlInput(error))
		);
	}

	private getDetailsByHtml(html: string) {
		return this.sourceService.getDetailsByHtml(html).pipe(
			switchMap((details: SourceDetails) => this.populateSourceDetails(details))
		);
	}

	private handleErrorAndPromptHtmlInput(error: any) {
		ErrorHelper.handleError(error, this.snackBar);

		const dialogRef = this.dialog.open(HtmlInputDialogComponent, {
			width: '400px'
		});

		return dialogRef.afterClosed().pipe(
			switchMap((html: string) => html ? this.getDetailsByHtml(html) : of(null))
		);
	}

	private populateSourceDetails(details: SourceDetails) {
		this.source.title = details.title;
		this.source.description = details.description;
		this.source.date = details.date;
		this.source.image = details.image;
		this.source.organization = details.organization && Object.keys(details.organization).length > 0
			? details.organization
			: this.source.organization;

		this.details = details;
		this.calculateFrequencies();
		return of(null);
	}

	public onAddEntity(entity: string): void {
		this.source.addTag({ name: entity.toUpperCase() });
		this.onRemoveEntity(entity);
	}

	public onRemoveEntity(entity: string) {
		const index = this.details.entities.findIndex(item => item.label === entity);
		if (index >= 0) {
			this.details.entities.splice(index, 1);
		}
	}

	public onAddAllExistingEntities(): void {
		//
		// add all existing entities with a count >= to the minimum
	    this.details.entities.forEach(entity => {
	        if (entity.existing && entity.count >= this.entityMinCount) {
	            this.source.addTag({ name: entity.label.toUpperCase() });
	        }
	    });

		//
		// remove all entities that were added
	    this.details.entities = this.details.entities.filter(
			entity => !(entity.existing && entity.count >= this.entityMinCount)
		);
	}

	public onAddKeyword(keyword: string): void {
		this.source.addTag({ name: keyword.toUpperCase() });
		this.onRemoveKeyword(keyword);
	}

	public onRemoveKeyword(keyword: string) {
		const index = this.details.keywords.findIndex(item => item.keyword === keyword);
		if (index >= 0) {
			this.details.keywords.splice(index, 1);
		}
	}

	public onAddAllExistingKeywords(): void {
		//
		// add all existing keywords with a count >= to the minimum
	    this.details.keywords.forEach(keyword => {
	        if (keyword.existing && keyword.count >= this.keywordMinCount) {
	            this.source.addTag({ name: keyword.keyword.toUpperCase() });
	        }
	    });

		//
		// remove all keywords that were added
	    this.details.keywords = this.details.keywords.filter(
			keyword => !(keyword.existing && keyword.count >= this.keywordMinCount)
		);
	}


	public onOrganization(organization: Organization): void {
		this.source.organization = organization;
	}

	public onAddAuthor(name: string): void {
		const author = new Author(name);
		this.source.authors.push(author);
		
		this.details.authors = this.details.authors.filter(
			author => author != name
		);
	}
	
	private calculateFrequencies(): void {
        const entityCount = this.details.entities.map(entity => entity.count);
        const entityBucket = Array.from(new Set(entityCount)).sort((a, b) => a - b).reverse();
        this.entityFrequencies = entityBucket;
 
		const keywordCount = this.details.keywords.map(keyword => keyword.count);
        const keywordBucket = Array.from(new Set(keywordCount)).sort((a, b) => a - b).reverse();
        this.keywordFrequencies = keywordBucket;
	}
}
