import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthorService } from '../../service/author.service';
import { StorageService, StorageProperty } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { LoginService } from 'src/app/service/login.service';

import { Author } from 'src/app/model/author';

import { PaginatorComponent } from '../paging/paginator.component';

@Component({
	selector: 'app-author-list',
	templateUrl: './author-list.component.html',
	styleUrls: ['./author-list.component.scss']
})
export class AuthorListComponent extends PaginatorComponent<Author> implements OnInit {
	authorList: Author[] = [];
	authorColumns: string[] = ['firstName', 'lastName'];

	constructor(private service: AuthorService,
				private router: Router,
				private storageService: StorageService,
				private navigationService: NavigationService,
				private snackBar: MatSnackBar,
				private loginService: LoginService) {
		super();
	}

	ngOnInit() {
		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			//
			// page
			const pageIndex = this.storageService.getItem<number>(StorageProperty.AUTHOR_PAGE_INDEX);
			if (pageIndex) {
				this.pageIndex = pageIndex;
			}		
		}
		else {
			this.storageService.clearItem(StorageProperty.AUTHOR_PAGE_INDEX);
		}

		super.ngOnInit();
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	public onView(id: number): void {
		this.router.navigate(['/author', id]);
	}

	public onAuthorSelected(author: Author): void {
		this.router.navigate(['/author', author.id]);
	}

	protected getData(): void {
		this.isLoading = true;
		this.service.getList(this.pageIndex + 1, this.pageSize).subscribe({
			next: (response) => {
				this.loadData(response);			
			},
			error: (error) => {
				console.error('Error:', error.message);
				this.snackBar.open(error.message, 'Dismiss', {
					duration: 2000
				});
			},
			complete: () => {
				this.isLoading = false;
			}
		});
	}

    protected onPageChange(pageIndex: number): void {
		this.storageService.setItem(StorageProperty.AUTHOR_PAGE_INDEX, pageIndex);
	}
}