import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Organization } from 'src/app/model/organization';
import { OrganizationService } from 'src/app/service/organization.service';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-organization-edit',
	templateUrl: './organization-edit.component.html',
	styleUrls: ['./organization-edit.component.scss'],
	providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class OrganizationEditComponent implements OnInit {
	organization: Organization;

	constructor(private organizationService: OrganizationService,
		private snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location) { }

	ngOnInit() {
		this.organization = new Organization();
		const id = this.activatedRoute.snapshot.params['id'];

		if (id) {
			this.organizationService.getById(id).subscribe({
				next: (response) => {
					this.organization = response;
				},
				error: (error) => {
					ErrorHelper.handleError(error, this.snackBar);
				}
			});
		}
	}

	public onSave(): void {
		//
		// make sure reputation list is valid
		if (!this.validate()) {
			this.snackBar.open('Report card is missing values', 'Dismiss', {
				duration: 3000
			});
			
			return;
		}

		this.organizationService.save(this.organization).subscribe({
			next: (response) => {
				this.organization = response;
				this.snackBar.open('Organization saved', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onUpdate(): void {
		//
		// make sure reputation list is valid
		if (!this.validate()) {
			this.snackBar.open('Report card is missing values', 'Dismiss', {
				duration: 3000
			});
			
			return;
		}

		this.organizationService.update(this.organization).subscribe({
			next: (response) => {
				this.organization = response;
				this.snackBar.open('Organization updated', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onDelete(): void {
		this.organizationService.delete(this.organization.id).subscribe({
			next: () => {
				this.router.navigate(['organization/list']);
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}
	
	public onCancel(): void {
		this.location.back();
	}

	private validate(): boolean {
		//
		// make sure repution list is correct
		for (let reputation of this.organization.reputationList) {
			if (!reputation.category) {
				return false;
			}
		}
		
		return true;
	}
}
