import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReferenceComponent } from './reference.component';
import { ReferenceListComponent } from './reference-list.component';

const routes: Routes = [
	{ path: 'reference/list', component: ReferenceListComponent },
	{ path: 'reference/add', component: ReferenceComponent },
	{ path: 'reference/:id', component: ReferenceComponent }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})
export class ReferenceRoutingModule { }