import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Author } from 'src/app/model/author';
import { AuthorService } from 'src/app/service/author.service';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-author-edit',
	templateUrl: './author-edit.component.html',
	styleUrls: ['./author-edit.component.scss'],
	providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class AuthorEditComponent implements OnInit {
	author: Author;

	constructor(private authorService: AuthorService,
		private snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location) { }

	ngOnInit() {
		this.author = new Author('');
		const authorId = this.activatedRoute.snapshot.params['id'];

		if (authorId) {
			this.authorService.getById(authorId).subscribe({
				next: (response) => {
					this.author = response;
					if (!this.author.reputationList) {
						this.author.reputationList = [];
					}
				},
				error: (error) => {
					ErrorHelper.handleError(error, this.snackBar);
				}
			});
		}
	}

	public onSave(): void {
		//
		// make sure reputation list is valid
		if (!this.validate()) {
			this.snackBar.open('Report card is missing values', 'Dismiss', {
				duration: 3000
			});
			
			return;
		}
		
		this.authorService.save(this.author).subscribe({
			next: (response) => {
				this.author = response;
				this.snackBar.open('Author saved', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onUpdate(): void {
		//
		// make sure reputation list is valid
		if (!this.validate()) {
			this.snackBar.open('Report card is missing values', 'Dismiss', {
				duration: 3000
			});
			
			return;
		}

		this.authorService.update(this.author).subscribe({
			next: (response) => {
				this.author = response;
				this.snackBar.open('Author updated', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onDelete(): void {
		this.authorService.delete(this.author.id).subscribe({
			next: () => {
				this.router.navigate(['author/list']);
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onCancel(): void {
		this.location.back();
	}

	private validate(): boolean {
		//
		// make sure repution list is correct
		for (let reputation of this.author.reputationList) {
			if (!reputation.category) {
				return false;
			}
		}
		
		return true;
	}
}
