import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { animate, state, style, transition, trigger } from '@angular/animations';

import {MatTable} from '@angular/material/table';

import { FixedTableComponent } from './fixed-table.component';
import { Identifiable } from 'src/app/model/identifiable';

@Component({
	selector: 'app-fixed-expandable-table',
	templateUrl: './fixed-expandable-table.component.html',
	styleUrls: ['./fixed-expandable-table.component.scss'],
	animations: [
		trigger('detailExpand', [
		state('collapsed', style({ height: '0px', minHeight: '0' })),
		state('expanded', style({ height: '*' })),
		transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class FixedExpandableTableComponent<T extends Identifiable> extends FixedTableComponent<T> implements OnInit {
	@Input()
	editable: boolean = false;

	@Output()
	edit: EventEmitter<T> = new EventEmitter<T>();
	
	@Output()
	delete: EventEmitter<T> = new EventEmitter<T>();

	@ViewChild('table')
	table: MatTable<T>;

	expanded: Set<T> = new Set();

	constructor() { 
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	public isExpanded(document: T): boolean {
		return this.expanded.has(document);
	}

	public toggle(document: T): void {
		if (this.expanded.has(document)) {
			this.expanded.delete(document);
		}
		else {
			this.expanded.add(document);
		}
	}

	public onEdit(document: T): void {
		this.edit.emit(document);
	}

	public onDeleteClick(document: T): void {
		this.delete.emit(document);
	}

	public onDrop(event: CdkDragDrop<T>): void {
		moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
		this.table.renderRows();
	}
	
	public onDelete(document: T): void {
		const index = this.dataSource.findIndex(doc => doc.id == document.id);
		this.dataSource.splice(index, 1);
	}

}
