<mat-form-field class="source-field">
	<mat-label>{{label}}</mat-label>
	<mat-chip-grid #orgList>
		<mat-chip-row *ngIf="organization != null"
			[selectable]="!readOnly"
			[removable]="!readOnly"
			(removed)="remove(organization)"
			(click)="onClick(organization)">
			{{organization?.name}}
			<mat-icon *ngIf="!readOnly" matChipRemove>cancel</mat-icon>
		</mat-chip-row>
		<input matInput #organizationInput placeholder="Search organizations..."
			[formControl]="organizationControl"
			[matAutocomplete]="organizationAutocomplete"
			[matChipInputFor]="orgList"
			[matChipInputSeparatorKeyCodes]="separatorKeyCodes"
			[matChipInputAddOnBlur]="true"
			(matChipInputTokenEnd)="add($event)" />
	</mat-chip-grid>

	<mat-autocomplete autoActiveFirstOption 
		#organizationAutocomplete="matAutocomplete" 
		[displayWith]="displayFn"
		(optionSelected)="onOrganization($event)">
		<mat-option *ngFor="let organization of organizations | async" [value]="organization">
			{{organization.name}}
		</mat-option>
       </mat-autocomplete>
</mat-form-field>
