import { Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Category, CategoryTreeNode } from 'src/app/model/category';
import { SourceFilters } from '../../model/source';

import { CategoryService } from 'src/app/service/category.service';
import { LoadingComponent } from '../loading-component';

import { StorageProperty, StorageService } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Directive()
export abstract class CategoryCommonComponent extends LoadingComponent {
	category: Category;
	rootTreeNode: CategoryTreeNode;
	
	treeControl = new NestedTreeControl<CategoryTreeNode>(node => node.children);
	dataSource = new MatTreeNestedDataSource<CategoryTreeNode>();
	
	filters: SourceFilters = { tags: []};
	filtersProperty: StorageProperty = StorageProperty.CATEGORY_FILTERS;
	
	
	constructor(protected categoryService: CategoryService,
				protected storageService: StorageService,
				protected snackBar: MatSnackBar,
				protected navigationService: NavigationService,
				protected activatedRoute: ActivatedRoute,
				protected router: Router) {
		super();
		this.category = new Category();				
	}

	public onCategoryClick(categoryId: string): void {
		this.router.routeReuseStrategy.shouldReuseRoute = function() {
			return false;
		};
		this.router.navigate(['/category', categoryId]);
	}
	
	hasChild = (_: number, node: CategoryTreeNode) => !!node.children && node.children.length > 0;

	public expandToNode(categoryId: number): void {
		let categoryPath: CategoryTreeNode[] = [];

		const allNodes = [this.rootTreeNode, ...this.rootTreeNode.children];
		for (let node of allNodes) {
			const result = this.traverseNodePath(categoryId, node, categoryPath);
			if (result) {
				categoryPath.forEach(node => this.treeControl.expand(node));

				return;
			}
		}
	}
	
	protected processTreeNodeResponse(node: CategoryTreeNode) {
		this.rootTreeNode = new CategoryTreeNode(node);
		this.dataSource.data = [this.rootTreeNode];
		this.expandToNode(this.category.id);
	}

	protected setCategoryFilters(tags: any[]): void {
	  this.storageService.setItem(StorageProperty.CATEGORY_FILTERS, { tags: [...tags] });
	}

	protected clearCategoryFilters(): void {
		this.storageService.clearItem(StorageProperty.CATEGORY_FILTERS);
	}

	protected handleError(error) {
		ErrorHelper.handleError(error, this.snackBar);
	}
	
	private traverseNodePath(categoryId: number, node: CategoryTreeNode, categoryPath: CategoryTreeNode[]): boolean {
		categoryPath.push(node);
		if (node.categoryId === categoryId) {
			return true;
		}
		else if (node.children) {
			for (let childNode of node.children) {
				const result = this.traverseNodePath(categoryId, childNode, categoryPath);
				if (result) {
					return true;
				}
			}
		}
		// Pop the node if the node's children don't contain the desired node
		categoryPath.pop();

		return false;
	}

}