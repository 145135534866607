import { Component,Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Story, StoryFilters } from 'src/app/model/story';
import { Category } from 'src/app/model/category';

import { StoryService } from 'src/app/service/story.service';
import { StorageService, StorageProperty } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';

import { PaginatorComponent } from '../paging/paginator.component';

import { SelectionList } from 'src/app/model/util/selection-list';
import { LoginService } from 'src/app/service/login.service';

@Component({
	selector: 'app-story-table',
	templateUrl: './story-table.component.html',
	styleUrls: ['./story-table.component.scss']
})
export class StoryTableComponent extends PaginatorComponent<Story> implements OnInit {
	storyColumns: string[] = ['select', 'title', 'category', 'actions'];

	selected: SelectionList<Story>;

	filters: StoryFilters = {};

	@Input()
	selectable: boolean;
	
	@Input()
	addable: boolean;

	@Input()
	lazy: boolean = false;

	categoryFilter: Category;

//	@Output()
//	select: EventEmitter<Story> = new EventEmitter<Story>();

	constructor(private storyService: StoryService,
				private router: Router,
				private storageService: StorageService,
				private snackBar: MatSnackBar,
				private navigationService: NavigationService,
				private loginService: LoginService) {
		super();
	}

	ngOnInit(): void {
		if (this.selectable) {
			this.selected = new SelectionList();
		}
		else {
			this.storyColumns.shift();
		}

		if (!this.isLoggedIn()) {
			this.storyColumns.pop();
		}

		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			//
			// page
			const pageIndex = this.storageService.getItem<number>(StorageProperty.STORY_PAGE_INDEX);
			if (pageIndex) {
				this.pageIndex = pageIndex;
			}			
		}
		else {
			this.storageService.clearItem(StorageProperty.STORY_PAGE_INDEX);
		}

		if (!this.lazy) {
			super.ngOnInit();
		}
	}

	public fetch(): void {
		//
		// category
		if (this.categoryFilter) {
			this.storageService.setItem(StorageProperty.STORY_CATEGORY_FILTER, this.categoryFilter);
		}

		//
		// reset list
		this.reset();
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	onAdd(): void {
		this.router.navigate(['/story/edit', '']);
	}

	public onCategory(category: Category): void {
		this.filters.categoryId = category.id;
		this.categoryFilter = category;
	}

	public onSelect(story: Story): void {
		this.selected.toggle(story);
	}
	
	public isSelected(story: Story): boolean {
		return this.selected.isSelected(story);
	}
	
	public isAllSelected(): boolean {
		return this.selected.allSelected(this.dataSource.data);
	}
	
	public onSelectedToggle(): void {
		//
		// if all items of current page are selected clear, otherwise add
		if (this.selected.allSelected(this.dataSource.data)) {
			this.selected.clear();
		}
		else {
			this.selected.addList(this.dataSource.data);
		}
	}

	protected getData(): void {
		this.isLoading = true;
		this.storyService.getList(this.pageIndex + 1, this.pageSize, this.filters).subscribe({
			next: (response) => {
				this.loadData(response);			
			},
			error: (error) => {
				console.error('Error:', error.message);
				this.snackBar.open(error.message, 'Dismiss', {
					duration: 2000
				});
			},
			complete: () => {
				this.isLoading = false;
			}
		});	
	}

    protected onPageChange(pageIndex: number): void {
		this.storageService.setItem(StorageProperty.STORY_PAGE_INDEX, pageIndex);
	}

}
