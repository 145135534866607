import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CategoryComponent } from './category.component';
import { CategoryListComponent } from './category-list.component';
import { CategoryEditComponent } from './category-edit.component';

const routes: Routes = [
	{ path: 'category/list', component: CategoryListComponent },
	{ path: 'category/add', component: CategoryEditComponent },
	{ path: 'category/:id', component: CategoryComponent },
	{ path: 'category/edit/:id', component: CategoryEditComponent }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})
export class CategoryRoutingModule { }