import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Author } from '../model/author';
import { PagingResponse } from 'src/app/model/paging-response';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AuthorService {
	private baseUrl: string = environment.serverUrl + 'authors';

	constructor(protected http: HttpClient) {}

	public getById(id: number): Observable<Author> {
		return this.http.get<Author>(`${this.baseUrl}` + '/' + id);
	}

	public getList(page: number = 1, limit: number = 10): Observable<PagingResponse<Author>> {
		const params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		return this.http.get<PagingResponse<Author>>(`${this.baseUrl}`, { params });
	}

	public searchByName(name: string): Observable<Author[]> {
		const url = `${this.baseUrl}` + '/name/' + name;
		
		return this.http.get<Author[]>(url);
	}

	public save(author: Author): Observable<Author> {
		return this.http.post<Author>(`${this.baseUrl}`, author);
	}

	public update(author: Author): Observable<Author> {
		const url = `${this.baseUrl}/${author.id}`;

		return this.http.put<Author>(url, author);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.delete(url);
	}

}
