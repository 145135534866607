import { Author } from './author';
import { Category } from './category';
import { Organization } from './organization';

export class Reputation {
	category: Category;
	rank: number;
	weight: number;
	
	static getIcon(rank: number): [string, string, string] {
		if (rank < 1.5) {
			return ['fas', 'face-dizzy', '#B9706F'];
		}
		else if (rank >= 1.5 && rank < 2.5) {
			return ['fas', 'face-frown', '#E39E6D'];
		}
		else if (rank >= 2.5 && rank < 3.5) {
			return ['fas', 'face-meh-blank', '#F0D130'];
		}
		else if (rank >= 3.5 && rank < 4.5) {
			return ['fas', 'face-smile', '#D2D46A'];
		}
		else if (rank >= 4.5) {
			return ['fas', 'face-grin-stars', '#B0B75E'];
		}
		else {
			return ['', '', ''];
		}
    }
}

export class AuthorReputation extends Reputation {
	author: Author;
}

export class OrganizationReputation extends Reputation {
	organization: Organization;
}

export enum Rank {
	AWFUL,
	BAD,
	NEUTRAL,
	GOOD,
	GREAT
}