export class User {
	id: number;
	login: string;
	name?: string;
	email?: string;
	
	constructor(login?: string, name?: string, email?: string) {
		this.login = login;
		this.name = name;
		this.email = email;
	}
}

export class UserRegistration extends User {
	password: string;
	token: string;
	
	constructor(login?: string, name?: string, email?: string) {
		super(login, name, email);
	}
}
