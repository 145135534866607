<h3>Login</h3>
<div fxLayout="column" fxLayoutAlign="space-evenly start">
	<form class="form-group" [formGroup]="form" (ngSubmit)="login()">
		<mat-form-field class="source-field">
			<input matInput placeholder="Login" formControlName="login">
		</mat-form-field>
	
		<mat-form-field class="source-field">
			<input matInput placeholder="Password" type="password" formControlName="password">
		</mat-form-field>

		<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" fxFill>
			<button mat-stroked-button color="accent" type="button" [routerLink]="['/register']">Register</button>
			
			<button mat-stroked-button color="primary" type="submit">Login</button>
		</div>
	</form>
</div>