import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SourceComponent } from './source.component';
import { SourceListComponent } from './source-list.component';
import { SourceEditComponent } from './source-edit.component';

const routes: Routes = [
	{ path: 'source/list', component: SourceListComponent },
	{ path: 'source/add', component: SourceEditComponent },
	{ path: 'source/reference/:reference', component: SourceEditComponent },
	{ path: 'source/:id', component: SourceComponent },
	{ path: 'source/edit/:id', component: SourceEditComponent }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})
export class SourceRoutingModule { }