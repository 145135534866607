import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { startWith, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import { TagService } from 'src/app/service/tag.service';
import { Tag, TagType, TagUtility } from 'src/app/model/tag';

@Component({
	selector: 'app-taglookup',
	templateUrl: './taglookup.component.html',
	styleUrls: ['./taglookup.component.scss']
})
export class TaglookupComponent implements OnInit {
	@Input()
	tags: Tag[];
	
	@Input()
	tagType: TagType = TagType.All;
	
	@Input()
	label: string = 'Tags';
	
	@Input()
	clickable: boolean = true;
	
	@Input()
	database: string = 'info';
	
	@Input()
	readOnly: boolean = true;

	@Output()
	add: EventEmitter<Tag> = new EventEmitter();

	@Output()
	remove: EventEmitter<Tag> = new EventEmitter();

	@ViewChild('tagsInput')
	tagsInput: ElementRef<HTMLInputElement>;
	
	@ViewChild('tagsComponentAutocomplete')
	tagsAutoComplete: MatAutocomplete;
	
	@ViewChild(MatAutocompleteTrigger, { static: true })
	autocompleteTrigger: MatAutocompleteTrigger;
	
//	tag: string;
	tagsObs: Observable<Tag[]>;
	tagsControl: FormControl = new FormControl();

	separatorKeyCodes: number[] = [ENTER, COMMA];

	constructor(private tagService: TagService,
				protected router: Router) { }

	ngOnInit() {
		this.tagsObs = this.tagsControl.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(name => name != null && name.length > 0),
			switchMap(name => {
				switch (this.tagType) {
					case TagType.Bookmarks:
						return this.tagService.searchBookmarksTags(name.toUpperCase());
					case TagType.Category:
						return this.tagService.searchCategoryTags(name.toUpperCase());
					case TagType.Info:
						return this.tagService.searchInfoTags(name.toUpperCase());
					case TagType.Source:
						return this.tagService.searchSourceTags(name.toUpperCase());
					case TagType.Reference:
						return this.tagService.searchReferenceTags(name.toUpperCase());
					default:
						return this.tagService.search(name.toUpperCase());;
				}
			})
		);
	}

	public onTagSelected(event: MatAutocompleteSelectedEvent): void {
		this.add.emit(event.option.value as Tag);
		this.tagsInput.nativeElement.value = '';
		this.tagsControl.setValue(null);
	}

	public addTag(event: MatChipInputEvent): void {
		if (!this.tagsAutoComplete.isOpen) {
			const input = event.input;
			const value = event.value;

			if ((value || '').trim()) {
				const tag = TagUtility.createTag(value);
				this.add.emit(tag);
			}
	
			if (input) {
				input.value = '';
			}
		}
	}

	public removeTag(tag: Tag): void {
		this.remove.emit(tag);
	}

	public onEnter(): void {
		const inputValue = this.tagsControl.value?.trim();
		if (inputValue) {
			//
			// Check if the autocomplete panel is open and search for tag
			if (this.tagsAutoComplete.isOpen) {
				let matchedTag = null;
				this.tagsAutoComplete.options.forEach(option => {
					if (option.value.name.toLowerCase() === inputValue.toLowerCase()) {
						matchedTag = option.value;
					}
				});

				//
				// if tag exists, emit, otherwise create and emit
				if (matchedTag) {
					this.add.emit(matchedTag);
					this.autocompleteTrigger.closePanel();
				}
				else {
					const newTag = TagUtility.createTag(inputValue);
					this.add.emit(newTag);
				}
				
				this.resetInputField();
			}
			else {
				const newTag = TagUtility.createTag(inputValue);
				this.add.emit(newTag);
				this.resetInputField();
			}
		}
	}
	
	public onClick(tag: Tag): void {
		if (this.clickable) {
			this.router.navigate(['source/list'],
				{ queryParams: { tag: tag.name }}
			);
		}
	}
	
	private resetInputField(): void {
		this.tagsInput.nativeElement.value = ''; // Clear the input field
		this.tagsControl.setValue(null); // Reset the form control
	}
}
