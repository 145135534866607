<div class="page-header" fxLayout="row" fxLayoutAlign="space-between start">
	<h3>Authors</h3>
	<!-- search -->
	<app-author-search (authorSelected)="onAuthorSelected($event)"></app-author-search>
</div>
<div class="author-list" fxLayout="column" fxLayoutAlign="space-evenly start" fxLayoutGap="5px">
	<!-- authors -->
	<table mat-table [dataSource]="dataSource" class="data-table mat-elevation-z1" fxFlexFill.xs>
	    <ng-container matColumnDef="firstName">
	        <th mat-header-cell *matHeaderCellDef> First Name </th>
	        <td mat-cell *matCellDef="let author"> {{author.firstName}} </td>
	    </ng-container>
	
	    <ng-container matColumnDef="lastName">
	        <th mat-header-cell *matHeaderCellDef> Last Name </th>
	        <td mat-cell *matCellDef="let author"> {{author.lastName}} </td>
	    </ng-container>
	
	    <tr mat-header-row *matHeaderRowDef="authorColumns"></tr>
	    <tr mat-row (click)="onView(row.id)" *matRowDef="let row; columns: authorColumns;" style="cursor: pointer"></tr> 
	</table>

	<div class="list-pager" fxLayoutAlign="space-between center" fxFlexOffset="1">
		<mat-paginator
			[length]="resultsLength"
			[pageSizeOptions]="[10, 20, 50]"
			[pageIndex]="pageIndex"
			(page)="onPage($event)">
		</mat-paginator>
	
		<button *ngIf="isLoggedIn()" mat-stroked-button [routerLink]="['/author/add']">
		    <mat-icon>add</mat-icon> Add
		</button>
	</div>
</div>