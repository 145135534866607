import { Reputation } from './reputation';

export class Organization {
    id: number;
    name: string;
    sites: string[];
    description: string;

	reputationList: Reputation[] = [];
}
