<div class="search-box">
	<div class="search-container">
		<button mat-icon-button (click)="toggleInputVisibility()">
			<mat-icon>search</mat-icon>
		</button>
		<mat-form-field class="search-input" [ngClass]="{'visible': isInputVisible}">
			<input type="text" placeholder="{{label}}" matInput [formControl]="searchField" [matAutocomplete]="auto" />
			<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
				(optionSelected)="onSelect($event.option.value)">
				<mat-option *ngFor="let organization of organizations | async" [value]="organization">
					{{organization.name}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</div>