import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PagingResponse } from 'src/app/model/paging-response';

@Component({
  template: ''  // Empty template
})
export abstract class PaginatorComponent<T> implements OnInit {
	resultsLimit: number = 10;
	resultsLength: number = Math.pow(2, 32);
	pageIndex: number = 0;
	pageSize: number = 10;
	isLoading: boolean = true;
	
	dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	ngOnInit(): void {
		this.getData();
	}

	protected loadData(response: PagingResponse<T>) : void {
		this.dataSource.data = response.data;
		this.pageIndex = response.page - 1;

		if (response.total !== undefined) {
			this.resultsLength = response.total;
			return;
		}

		if (this.pageIndex === 0 || response.data.length < this.resultsLimit) {
			this.resultsLength = this.pageIndex * this.resultsLimit + response.data.length;
		}
		else if (this.resultsLength === undefined || this.resultsLength === Math.pow(2, 32)) {
			this.resultsLength = Math.pow(2, 32); // Placeholder for "unknown" total length
		}

		this.isLoading = false;
	}

	protected reset(): void {
		this.pageIndex = 0;
		this.resultsLength = Math.pow(2, 32);
		
		//
		// firstPage() triggers onPage() if not on the first page
		if (!this.paginator.hasPreviousPage()) {
			this.getData();
		}
		else {
			this.paginator.firstPage();
		}
	}
	
	public onPage(event: PageEvent): void {
		//
		// change of page size
		if (event.pageSize != this.pageSize) {
			this.pageSize = event.pageSize;
			this.pageIndex = 0;
		}
		else {
			this.pageIndex = event.pageIndex;
			this.onPageChange(event.pageIndex);
		}	
	
		this.getData();
	}

	protected abstract getData(): void;
	protected abstract onPageChange(pageIndex: number): void; 
}
