<mat-toolbar fxLayout="row">
	<img src="https://cdn.respedia.org/images/respedia_banner.png" [routerLink]="['/home']" class="site-logo">
	<span fxFlex [routerLink]="['/home']" class="site-title">Respedia</span>
	<!-- items -->
	<ng-container *ngFor="let item of items">
		<button mat-button [fxShow.xs]="item.showOnMobile" [routerLink]="[item.route]" routerLinkActive="router-link-active">
			<mat-icon>{{item.icon}}</mat-icon> {{item.label}}
		</button>
	</ng-container>

	<!-- login/logout -->
	<button *ngIf="!isLoggedIn()" mat-button fxHide.xs [routerLink]="['/login']" routerLinkActive="router-link-active">
		<mat-icon>login</mat-icon> Login
	</button>
	<button *ngIf="isLoggedIn()" mat-button fxHide.xs [routerLink]="['/logout']" routerLinkActive="router-link-active">
		<mat-icon>logout</mat-icon> Logout
	</button>

	<!-- more items -->
	<button mat-button [matMenuTriggerFor]="moreMenu" fxHide.xs>
		<mat-icon>more_vert</mat-icon> More
	</button>
	<mat-menu #moreMenu="matMenu" class="toolbar-menu">
		<ng-container *ngFor="let item of moreItems">
			<button mat-menu-item [routerLink]="[item.route]">
				<mat-icon>{{item.icon}}</mat-icon>
				<span>{{item.label}}</span>
			</button>		
		</ng-container>
	</mat-menu>

	<button mat-icon-button [matMenuTriggerFor]="dropMenu" fxHide fxShow.xs>
		<mat-icon>more_vert</mat-icon>
	</button>

	<mat-menu #dropMenu="matMenu" class="toolbar-menu">
		<ng-container *ngFor="let item of items">
			<button *ngIf="!item.showOnMobile" mat-menu-item [routerLink]="[item.route]">
				<mat-icon>{{item.icon}}</mat-icon>
				<span>{{item.label}}</span>
			</button>
		</ng-container>

		<button *ngIf="!isLoggedIn()" mat-menu-item [routerLink]="['/login']" routerLinkActive="router-link-active">
			<mat-icon>login</mat-icon> Login
		</button>	
		<button *ngIf="isLoggedIn()" mat-menu-item [routerLink]="['/logout']" routerLinkActive="router-link-active">
			<mat-icon>logout</mat-icon> Logout
		</button>

		<mat-divider></mat-divider>

		<ng-container *ngFor="let item of moreItems">
			<button mat-menu-item [routerLink]="[item.route]">
				<mat-icon>{{item.icon}}</mat-icon>{{item.label}}
			</button>
		</ng-container>
	</mat-menu>
</mat-toolbar>