import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Author } from '../model/author';
import { PagingResponse } from 'src/app/model/paging-response';

import { environment } from 'src/environments/environment';
import { Survey, SurveyResponse, SurveySubmission } from '../model/survey';

@Injectable({
	providedIn: 'root'
})
export class SurveyService {
	private baseUrl: string = environment.serverUrl + 'surveys';

	constructor(protected http: HttpClient) {}

	public getById(id: number): Observable<Survey> {
		return this.http.get<Survey>(`${this.baseUrl}/${id}`);
	}

	public getByName(name: string): Observable<Survey> {
		return this.http.get<Survey>(`${this.baseUrl}/name/${name}`);
	}

	public getList(page: number = 1, limit: number = 10): Observable<PagingResponse<Author>> {
		const params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		return this.http.get<PagingResponse<Author>>(`${this.baseUrl}`, { params });
	}

	public searchByName(name: string): Observable<Author[]> {
		const url = `${this.baseUrl}` + '/name/' + name;
		
		return this.http.get<Author[]>(url);
	}

	public submitSurvey(survey: Survey, submission: SurveySubmission): Observable<SurveySubmission> {
		return this.http.post<SurveySubmission>(`${this.baseUrl}/${survey.id}/submit`, submission);
	}

	public update(author: Author): Observable<Author> {
		const url = `${this.baseUrl}/${author.id}`;

		return this.http.put<Author>(url, author);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.delete(url);
	}

}
