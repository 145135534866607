import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, switchMap, debounceTime, distinctUntilChanged, filter, catchError } from 'rxjs/operators';


import { Bookmarks } from 'src/app/model/bookmarks';
import { Identifiable } from 'src/app/model/identifiable';
import { BookmarksService } from 'src/app/service/bookmarks.service';

@Component({
	selector: 'app-bookmarks-button',
	templateUrl: './bookmarks-button.component.html',
	styleUrls: ['./bookmarks-button.component.scss']
})
export class BookmarksButtonComponent implements OnInit {

	isOpen: boolean = false;
	label: string;
	
	bookmarks: Observable<Bookmarks[]>;
	
	searchField: FormControl = new FormControl();
	
	@Input()
	document: Identifiable;
	
	@Output()
	selected: EventEmitter<BookmarksButtonEvent> = new EventEmitter<BookmarksButtonEvent>();
	
	constructor(private bookmarksService: BookmarksService) { }

	ngOnInit(): void {
		this.bookmarks = this.searchField.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(name => name.length > 0),
			switchMap(name => this.bookmarksService.searchByLabel(name)
				.pipe(
					catchError(error => {
						if (error.status === 404) {
							return of([]);
						} else {
							// Handle other errors
						}
					})
				)			
			)
		);
	}

	public displayFn(bookmark: Bookmarks): string {
		return bookmark != null ? bookmark.label : '';
	}

	public select(event: Bookmarks): void {
		this.isOpen = !this.isOpen;
		this.searchField.reset();
		this.selected.emit(new BookmarksButtonEvent(event, this.document));
	}
}

export class BookmarksButtonEvent {
	bookmark: Bookmarks;
	document: Identifiable;

	
	constructor(bookmark: Bookmarks, document: Identifiable) {
		this.bookmark = bookmark;
		this.document = document;
	} 
}
