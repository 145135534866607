<div class="page-header" fxLayout="row" fxLayoutAlign="space-between start">
	<h3>Categories</h3>
	<!-- search -->
	<app-category-search (select)="onCategorySelected($event)"></app-category-search>
</div>
<div class="category-list" fxLayout="column" fxLayoutAlign="space-evenly start" style="min-width: 100%">
	<table mat-table [dataSource]="dataSource" class="data-table mat-elevation-z1">
	    <ng-container matColumnDef="name">
	        <th mat-header-cell *matHeaderCellDef> Name </th>
	        <td mat-cell *matCellDef="let category">
	        	{{category.name}} 
	        	<span *ngIf="category.path != category.name">&#10149;</span> 
	        	<span style="font-weight: lighter" [innerHTML]="category | categoryPath"></span>
	        </td>
	    </ng-container>
	
	    <ng-container matColumnDef="description">
	        <th mat-header-cell *matHeaderCellDef fxHide.xs> Description </th>
	        <td mat-cell *matCellDef="let category" fxHide.xs> {{category.description}} </td>
	    </ng-container>
	
	    <tr mat-header-row *matHeaderRowDef="categoryColumns"></tr>
	    <tr mat-row (click)="onView(row.id)" *matRowDef="let row; columns: categoryColumns;"></tr> 
	</table>
	
	<div class="list-pager" fxLayoutAlign="space-between center" fxFlexOffset="1">
		<mat-paginator
			[length]="resultsLength"
			[pageSizeOptions]="[10, 20, 50]"
			[pageIndex]="pageIndex"
			(page)="onPage($event)">
		</mat-paginator>
		
		<button *ngIf="isLoggedIn()" mat-stroked-button [routerLink]="['/category/add']">
		    <mat-icon>add</mat-icon> Add
		</button>
	</div>
</div>