<div class="page-header">
	<h3>Bookmark</h3>
</div>
<div fxLayout="column" fxLayoutAlign="start none">
	<form class="form-group source-form" fxFlex="80" fxFlex.xs="100">
		<div fxLayout="column" fxLayoutAlign="space-evenly start">
			<mat-form-field class="source-field">
				<input matInput placeholder="Name" [(ngModel)]="bookmarks.label" [ngModelOptions]="{standalone: true}" >
			</mat-form-field>
		</div>

		<!-- tags -->
		<app-taglookup
			matInput 
			[tagType]="tagType"
			[readOnly]="false"
			[tags]="bookmarks.tags"
			(add)="onAddTag($event)"
			(remove)="onRemoveTag($event)">
		</app-taglookup>
		
		<!-- buttons -->
		<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" fxFill>
			<button *ngIf="bookmarks.id == null" mat-stroked-button color="primary" type="button" (click)="onSave()">
			  <em class="fa fa-plus"></em> Save
			</button>
			
			<button *ngIf="bookmarks.id != null" mat-stroked-button color="primary" type="button" (click)="onUpdate()">
				<em class="fa fa-plus"></em> Update
			</button>
			
			<button mat-stroked-button color="accent" type="button" [disabled]="bookmarks.id == null" confirm-dialog (confirmed)="onDelete()">
			  <em class="fa fa-plus"></em> Delete
			</button>
		</div>
	</form>
	
	<mat-tab-group fxFill (selectedTabChange)="onTabChanged($event)">
		<!-- sources -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>flare</mat-icon><mat-icon>article</mat-icon>&nbsp; Sources
			</ng-template>
			<ng-template matTabContent>
				<button mat-stroked-button color="primary" (click)="onSourceDialog()">
					<mat-icon>add</mat-icon> Add Source
				</button>
				<app-fixed-source-table [dataSource]="bookmarks.sources" (selected)="onSourceSelected($event)"></app-fixed-source-table>
			</ng-template>		
		</mat-tab>

		<!-- bits -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>flare</mat-icon><mat-icon>article</mat-icon>&nbsp; Excerpts
			</ng-template>
			<ng-template matTabContent>
				<button mat-stroked-button color="primary" (click)="onInfoDialog()">
					<mat-icon>add</mat-icon> Add Source Bit
				</button>
				<app-fixed-info-table [dataSource]="bookmarks.sourceBits"></app-fixed-info-table>
			</ng-template>		
		</mat-tab>
	</mat-tab-group>	
</div>
