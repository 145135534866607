import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	private JWT_TOKEN: string = 'token';

	constructor() { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const jwtToken = localStorage.getItem(this.JWT_TOKEN);

		if (jwtToken) {
			const requestClone = request.clone({
				headers: request.headers.set("Authorization", "Bearer " + jwtToken)
			});
			
			return next.handle(requestClone);
		}
		else {
			return next.handle(request);
		}
	}
}
