export interface Tag {
	id?: number;
	name: string;
}

export enum TagType {
	All,
	Category,
	Bookmarks,
	Info,
	Reference,
	Source
}

export class Taggable {
	tags: Tag[] = [];

	public addTag(tag: Tag): void {
		this.tags = this.tags ?? [];
		if (!this.tags.some(existingTag => existingTag.name === tag.name)) {
			this.tags.push(tag);
		}
	}

	public removeTag(tag: Tag): void {
		if (this.tags) {
			const index = this.tags.findIndex(existingTag => existingTag.name === tag.name);
			if (index >= 0) {
				this.tags.splice(index, 1);
			}
		}
	}
}

export class TagUtility {
	static createTag(name: string): Tag {
		return {
			name: name.toUpperCase()
		};
	}

	static addTag(tag: Tag, tags: Tag[] = []): Tag[] {
		if (!tags.some(existingTag => existingTag.name === tag.name)) {
			tags.push(tag);
		}
		return tags;
	}

	static removeTag(tag: Tag, tags: Tag[]): Tag[] {
		const index = tags.findIndex(existingTag => existingTag.name === tag.name);
		if (index >= 0) {
			tags.splice(index, 1);
		}
		return tags;
	}

	static sortAndUpperCase(tags: Tag[] | null): Tag[] {
		if (!tags || tags.length === 0) {
			return [];
		}

		return tags
			.sort((a, b) => a.name.localeCompare(b.name))
			.map(tag => {
				tag.name = tag.name.toUpperCase();
				return tag;
			});
	}
}