import { Identifiable } from '../identifiable';

export class SelectionList<T extends Identifiable> {
	selected: T[] = [];
	
	public toggle(item: T): void {
		//
		// if item is selected, remove, otherwise add
		const index = this.selected.findIndex(src => {
			if (src.id == item.id) {
				return true;
			}
		});

		if (index > -1) {
			this.selected.splice(index, 1);
		}
		else {
			this.selected.push(item);
		}		
	}

	public toggleList(items: T[]): void {
		items.forEach(item => {
			this.toggle(item);
		});
	}

	public add(item: T): void {
		//
		// add item if doesn't exist
		if (!this.isSelected(item)) {
			this.selected.push(item);
		}
	}

	public addList(items: T[]): void {
		//
		// add items that don't exist
		items.forEach(item => {
			this.add(item);
		});
	}

	public clear(): void {
		this.selected = [];
	}

	public isEmpty(): boolean {
		return this.selected.length == 0;
	}

	public isSelected(item: T): boolean {
		const index = this.selected.findIndex(src => {
			if (src.id == item.id) {
				return true;
			}
		});

		return index > -1;
	}
	
	public allSelected(items: T[]): boolean {
		if (this.selected.length == 0 || this.selected.length < items.length) {
			return false;
		}

		for (let item of items) {
			if (!this.isSelected(item)) {
				return false;
			}
		}

		return true;
	}

}