import { Meta, Title } from '@angular/platform-browser';

export function setMetaTags(
	title: Title,
	titleProperty: string,
	meta?: Meta,
	descriptionProperty?: string | null | undefined,
	imageProperty?: string | null | undefined
) {

	title.setTitle(titleProperty);

	const metaTags = [
		{ name: 'og:title', content: titleProperty }
	];

	if (descriptionProperty) {
		metaTags.push({ name: 'og:description', content: descriptionProperty });
	}

	if (imageProperty) {
		metaTags.push({ name: 'og:image', content: imageProperty });
	}

	meta.addTags(metaTags);
}