<table #table fxFlex="auto" mat-table [dataSource]="dataSource" class="mat-elevation-z1" multiTemplateDataRows
	cdkDropList [cdkDropListData]="dataSource" (cdkDropListDropped)="onDrop($event)">
	<ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.columnDef">
		<th mat-header-cell *matHeaderCellDef>
			{{column.header}}
		</th>
		<td [ngSwitch]="column.type" mat-cell *matCellDef="let row">
			<mat-icon *ngIf="i == 0 && editable">open_with</mat-icon>
			<span *ngSwitchCase="ColumnType.DATE">
				{{column.cell(row) | date:'MM/dd/yyyy'}}
			</span>
			<span *ngSwitchCase="ColumnType.STRING">
				{{column.cell(row)}}
			</span>
			<mat-chip-listbox *ngSwitchCase="ColumnType.TAGS">
				<ng-container *ngFor="let tag of column.cell(row)">
					<mat-chip-option color="accent">{{tag.name}}</mat-chip-option>
				</ng-container>				
			</mat-chip-listbox>
			<span *ngSwitchDefault>
				{{column.cell(row)}}{{column.type}}
			</span>
		</td>
	</ng-container>

	<ng-container matColumnDef="expandedItem">
		<td mat-cell *matCellDef="let info" [attr.colspan]="displayColumns.length">
			<div fxLayout="column" fxLayoutAlign="space-evenly start" class="item-detail" [@detailExpand]="isExpanded(info) ? 'expanded' : 'collapsed'">
				<div class="item-detail-content">
					<markdown [data]="info.detail"></markdown>
					<p>
						<a [routerLink]="['/source', info.source?.id]">{{info.source?.reference}}</a>
					</p>
				</div>
				
				<div *ngIf="isLoggedIn() && !editable" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" style="width: 100%">
					<app-bookmarks-button [document]="info" (selected)="onBookmark($event)"></app-bookmarks-button>
					<app-story-add-button [info]="info" (selected)="onStory($event)"></app-story-add-button>
				</div>

				<div *ngIf="editable" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" style="width: 100%">
					<app-bookmarks-button [document]="info" (selected)="onBookmark($event)"></app-bookmarks-button>
					<app-story-add-button [info]="info" (selected)="onStory($event)"></app-story-add-button>


					<button  mat-stroked-button color="primary" type="button" (click)="onEdit(info)">
					  <em class="fa fa-plus"></em> Edit
					</button>
					
					<button mat-stroked-button color="accent" type="button" confirm-dialog name="info" (confirmed)="onDeleteClick(info)">
					  <em class="fa fa-plus"></em> Delete
					</button>			
				</div>

			</div>
		</td>
	</ng-container>

	<!-- row defintions -->
	<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"
    	class="item-row"
		[class.item-expanded-row]="isExpanded(row)"
		(click)="toggle(row)"
		cdkDrag [cdkDragData]="row"
		[cdkDragDisabled]="!editable">
	</tr>
	<tr mat-row *matRowDef="let row; columns: ['expandedItem']" class="item-detail-row"></tr>	
	

	<!-- no results -->
    <tr class="mat-row" *matNoDataRow>
		<td class="mat-cell" [colSpan]="displayColumns.length">
  			No results found
		</td>
	</tr>
</table>
