<div class="search-box">
	<div class="search-container">
		<button mat-icon-button (click)="toggleInputVisibility()">
			<mat-icon>search</mat-icon>
		</button>
		<mat-form-field class="search-input" [ngClass]="{'visible': isInputVisible}">
			<input type="text" placeholder="Search categories ..." matInput [formControl]="searchField"
				[matAutocomplete]="auto">

			<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [panelWidth]="'auto'"
				(optionSelected)='onSelect($event.option.value)'>
				<mat-option *ngFor="let category of categories | async" [value]="category">
					{{category.name}}
					<span *ngIf="category.path != category.name">&#10149;</span>
					<span style="font-weight: lighter" [innerHTML]="category | categoryPath"></span>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</div>