import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { UserRegistration } from 'src/app/model/user';
import { LoginService } from 'src/app/service/login.service';

import { Errors } from 'src/app/model/errors';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	form: FormGroup;
	loading: Boolean = false;
	submitted: Boolean = false;
	
	constructor(
		private formBuilder: FormBuilder,
		private loginService: LoginService,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			login: ['', Validators.required],
			password: ['', [Validators.required, Validators.minLength(8)]],
			email: ['', [Validators.required, Validators.email]],
			token: ['', Validators.required],
			name: ['']
		});
	}

	public get fields() {
		return this.form.controls;
	}

	public checkFieldError(name: string, error: string): boolean {
		return this.form.controls[name].hasError(error);
	}

	public onSubmit(): void {
		this.submitted = true;
		
		if (this.form.invalid) {
			return;
		}
		
		const newUser = new UserRegistration(
			this.form.controls.login.value,
			this.form.controls.name.value,
			this.form.controls.email.value
		);
		
		newUser.password = this.form.controls.password.value;
		newUser.token = this.form.controls.token.value;

		this.loading = true;
		this.loginService.register(newUser).subscribe(
			response => {
				this.snackBar.open('Registration complete', 'Dismiss', {
					duration: 1000
				});
			},
			error => {
				if (error.error.code == Errors.DOCUMENT_EXISTS) {
					this.form.controls['login'].setErrors({'exists': true});
				}
				else if (error.error.code == Errors.JWT_TOKEN_ERROR) {
					this.form.controls['token'].setErrors({'invalid': true});
				}
				else if (error.error.code == Errors.JWT_TOKEN_EXPIRED) {
					this.form.controls['token'].setErrors({'expired': true});
				}

				this.loading = false;
			}
		);
		
	}
}
