import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class PaginatorLabels extends MatPaginatorIntl {
	public getRangeLabel = (page: number, pageSize: number, length: number): string => {
		if (length === 0 || pageSize === 0) {
			return `0 / ${length}`;
	  	}

		length = Math.max(length, 0);
		const startIndex = ((page * pageSize) > length) ?
		  (Math.ceil(length / pageSize) - 1) * pageSize: page * pageSize;
		const endIndex = Math.min(startIndex + pageSize, length);

		if (length == Math.pow(2, 32)) {
			return startIndex + 1 + ' - ' + endIndex;
		}
		else {
			return startIndex + 1 + ' - ' + endIndex + ' of ' + length;
		}
	}

}