import { Component, OnInit } from '@angular/core';
import { Source } from 'src/app/model/source';
import { ColumnType, FixedTableComponent } from './fixed-table.component';

@Component({
	selector: 'app-fixed-source-table',
	templateUrl: './fixed-table.component.html',
	styleUrls: ['./fixed-table.component.scss']
})
export class FixedSourceTableComponent extends FixedTableComponent<Source> implements OnInit {

	constructor() {
		super();
		
		this.columns = [
			{
				columnDef: 'title',
				header: 'Title',
				type: ColumnType.STRING,
				cell: (source: Source) => `${source.title}`
			},
			{
				columnDef: 'organization',
				header: 'Organization',
				type: ColumnType.STRING,
				cell: (source: Source) => `${source.organization?.name}`
			},
			{
				columnDef: 'date',
				header: 'Date',
				type: ColumnType.DATE,
				cell: (source: Source) => `${source.date}`
			}
		];
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}
