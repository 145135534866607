import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Organization } from 'src/app/model/organization';
import { PagingResponse } from 'src/app/model/paging-response';

import { environment } from 'src/environments/environment';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Authorization': 'YWRtaW46KkNvam9uZXNz'
	})
};

@Injectable({
	providedIn: 'root'
})
export class OrganizationService {
	private baseUrl: string = environment.serverUrl + 'organizations';

	constructor(protected http: HttpClient) {}

	public getById(id: number): Observable<Organization> {
		return this.http.get<Organization>(`${this.baseUrl}` + '/' + id, httpOptions);
	}

	public getList(page: number = 1, limit: number = 10): Observable<PagingResponse<Organization>> {
		const params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		return this.http.get<PagingResponse<Organization>>(`${this.baseUrl}`, { params });
	}

	public save(organization: Organization): Observable<Organization> {
		return this.http.post<Organization>(`${this.baseUrl}`, organization);
	}

	public update(organization: Organization): Observable<Organization> {
		const url = `${this.baseUrl}/${organization.id}`;

		return this.http.put<Organization>(url, organization);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.delete(url);
	}

	public searchByName(name: string): Observable<Organization[]> {
		const url = `${this.baseUrl}` + '/name/' + name;

		return this.http.get<Organization[]>(url);
	}

	public searchBySite(site: string): Observable<Organization> {
		const url = `${this.baseUrl}` + '/site/' + site;
		
		return this.http.get<Organization>(url, httpOptions);
	}
}

