<div class="page-header" fxLayout="row" fxLayoutAlign="space-between start">
	<h3>Bookmarks</h3>
</div>
<div class="bookmarks-list" fxLayout="column" fxLayoutAlign="space-evenly start">
	<table mat-table [dataSource]="dataSource" class="data-table mat-elevation-z1">
	    <ng-container matColumnDef="label">
	        <th mat-header-cell *matHeaderCellDef> Label </th>
	        <td mat-cell *matCellDef="let bookmarks">
	        	{{bookmarks.label}} 
	        </td>
	    </ng-container>
	
	    <tr mat-header-row *matHeaderRowDef="bookmarksColumns"></tr>
	    <tr mat-row (click)="onView(row.id)" *matRowDef="let row; columns: bookmarksColumns"></tr> 
	</table>
	
	<div class="list-pager" fxLayoutAlign="space-between center" fxFlexOffset="1">
		<mat-paginator
			[length]="resultsLength"
			[pageSizeOptions]="[10, 20, 50]"
			[pageIndex]="pageIndex"
			(page)="onPage($event)">
		</mat-paginator>
		
		<button *ngIf="isLoggedIn()" mat-stroked-button type="button" [routerLink]="['/bookmarks/add']">
		    <mat-icon>add</mat-icon> Add
		</button>
	</div>
</div>