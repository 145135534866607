import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';

import { AuthorReportCard, DisplayType, OrganizationReportCard, ReportCardType } from 'src/app/model/report-card';
import { Reputation } from 'src/app/model/reputation';
import { CategoryService } from 'src/app/service/category.service';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-category-report-card',
	templateUrl: './category-report-card.component.html',
	styleUrls: ['./category-report-card.component.scss']
})
export class CategoryReportCardComponent implements OnInit {

	public getIcon = Reputation.getIcon;
	public ReportCardType = ReportCardType;
	
	@Input()
	reportCard: AuthorReportCard | OrganizationReportCard;

	@Input()
	displayType: DisplayType;

	limit: number = 10;
	page: number = 0;
	total: number = Math.pow(2, 32);

	constructor(private categoryService: CategoryService,
				private snackBar: MatSnackBar,
				private breakpointObserver: BreakpointObserver,
				private router: Router) {}

	public ngOnInit(): void {
		this.updateTotalItems();
		this.updateLimitBasedOnScreenSize();
	}

	private updateTotalItems(): void {
		if (this.displayType === DisplayType.Top && this.reportCard?.topGrades.length < this.limit) {
			this.total = this.reportCard.topGrades.length;
		}
		else if (this.displayType === DisplayType.Bottom && this.reportCard?.bottomGrades.length < this.limit) {
			this.total = this.reportCard.bottomGrades.length;
		}
	}

	private updateLimitBasedOnScreenSize(): void {
		this.breakpointObserver.observe([
			Breakpoints.XSmall,
			Breakpoints.Small
		]).subscribe(result => {
			if (result.matches) {
				//
				// resize grade array to 5 items on small screens
				this.limit = 5;
				if (this.displayType === DisplayType.Top && this.reportCard.topGrades.length > 5) {
					this.reportCard.topGrades = this.reportCard.topGrades.slice(0, 5);
				}
				else if (this.displayType === DisplayType.Bottom && this.reportCard.bottomGrades.length > 5) {
					this.reportCard.bottomGrades = this.reportCard.bottomGrades.slice(0, 5);
				}
			}
			else {
				this.limit = 10;
			}
		});
	}

	public isAuthorReportCard(): boolean {
		return this.reportCard && this.reportCard.type === 'author';
	}
	
	public onRowClick(id: number) {
		if (this.isAuthorReportCard()) {
  			this.router.navigate(['/author', id]);
		}
		else {
			this.router.navigate(['/organization', id]);
		}
	}

	public onPageChange(event: PageEvent): void {
		this.page = event.pageIndex;
		const fetchReputations = this.isAuthorReportCard() ?
			this.categoryService.getAuthorReputations.bind(this.categoryService) :
			this.categoryService.getOrganizationReputations.bind(this.categoryService);

		fetchReputations(this.reportCard.category.id, this.displayType, this.page, this.limit).subscribe({
			next: (response: any[]) => {
				if (response.length < this.limit) {
					this.total = this.page * this.limit + response.length;
				}

				if (this.displayType === DisplayType.Top) {
					this.reportCard.topGrades = response;
				} else if (this.displayType === DisplayType.Bottom) {
					this.reportCard.bottomGrades = response;
				}
			},
			error: (error: any) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

}
