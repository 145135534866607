<div fxLayout="column" fxLayoutAlign="space-evenly start">
	<form class="form-group source-form" fxFill>
		<div fxLayout="row" fxLayoutAlign="space-around end" fxLayoutGap="5px" style="height: 7em">

		<!-- info tags -->
  		<app-taglookup
			matInput 
		    [label]="tagsLabel" 
		    [readOnly]="false"
		    [tagType]="tagInfoType"
		    [tags]="filters.tags" 
			(add)="onAddTag($event)"
			(remove)="onRemoveTag($event)">
		</app-taglookup>
		
		<!-- source tags -->
		<app-taglookup 
		    matInput 
		    [label]="sourceTagsLabel" 
		    [readOnly]="false"
		    [tagType]="tagSourceType"
		    [tags]="filters.sourceTags" 
			(add)="onAddSourceTag($event)"
			(remove)="onRemoveSourceTag($event)">
		</app-taglookup>

		<button mat-button matSuffix mat-stroked-button (click)="fetch()">
			<mat-icon>search</mat-icon>
		</button>
		</div>
	</form>

	<table fxFlex="auto" mat-table [dataSource]="dataSource" class="mat-elevation-z1" multiTemplateDataRows>
		<!-- Checkbox Column -->
		<ng-container matColumnDef="select" *ngIf="selectable">
			<th mat-header-cell *matHeaderCellDef >
				<mat-checkbox (change)="$event ? onSelectedToggle() : null"
					[checked]="isAllSelected()"
					[indeterminate]="!isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row" >
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="onSelect(row)"
					[checked]="isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

	    <ng-container matColumnDef="summary">
	        <th mat-header-cell *matHeaderCellDef> Summary </th>
	        <td mat-cell *matCellDef="let info"> {{info.summary}} </td>
	    </ng-container>
	
	    <ng-container matColumnDef="tags">
			<th mat-header-cell *matHeaderCellDef> Tags </th>
			<td mat-cell *matCellDef="let info">
				<mat-chip-listbox>
					<ng-container *ngFor="let tag of info.tags">
						<mat-chip-option color="accent">{{tag.name}}</mat-chip-option>
					</ng-container>
				</mat-chip-listbox>
			</td>
	    </ng-container>

<!-- 		<ng-container matColumnDef="actions"> -->
<!-- 		<th mat-header-cell *matHeaderCellDef> Actions </th> -->
<!-- 		<td mat-cell *matCellDef="let info"> -->
<!-- 		<button mat-button matSuffix mat-stroked-button (click)="onSelect($event, info)">Select</button> -->
<!-- 		</td> -->
		
<!-- 		</ng-container> -->

		<ng-container matColumnDef="expandedInfo">
			<td mat-cell *matCellDef="let info" [attr.colspan]="infoColumns.length">
				<div fxLayout="column" fxLayoutAlign="space-evenly start" class="info-detail" [@detailExpand]="info.selected ? 'expanded' : 'collapsed'">
					<span>
					<markdown [data]="info.detail"></markdown>
					<p>
						<a [routerLink]="['/source', info.source.id]">{{info.source.reference}}</a>
					</p>
					</span>
				</div>
			</td>
		</ng-container>

	    <tr mat-header-row *matHeaderRowDef="infoColumns"></tr>
	    <tr mat-row *matRowDef="let row; columns: infoColumns;"
	    	class="info-row"
			[class.info-expanded-row]="row.selected"
			(click)="row.selected = !row.selected ">
		</tr>
	    <tr mat-row *matRowDef="let row; columns: ['expandedInfo']" class="info-detail-row"></tr>
	    <tr class="mat-row" *matNoDataRow>
 			<td class="mat-cell" [colSpan]="infoColumns.length">
   				No results found
			</td>
		</tr>
	</table>
	
	<mat-paginator fxFlexOffset="1"
		[length]="resultsLength"
		[pageSizeOptions]="[10, 20, 50]"
		[pageIndex]="pageIndex"
		(page)="onPage($event)">
	</mat-paginator>
</div>