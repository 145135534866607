import { Pipe, PipeTransform } from '@angular/core';
import { Author } from '../model/author';

@Pipe({
	name: 'author'
})
export class AuthorPipe implements PipeTransform {

	transform(author: Author): string {
		let fullName = author.firstName;

		if (author.middleName) {
			fullName += ` ${author.middleName}`;
		}

		if (author.lastName) {
			fullName += ` ${author.lastName}`;
		}

		return fullName;
	}

}
