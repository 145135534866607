// src/app/models/survey.model.ts

export enum QuestionType {
	RATING = 'rating',
	OPEN = 'open',
	MULTIPLE_CHOICE = 'multiple_choice'
}

export interface Survey {
	id?: number;
	name: string;
	description?: string;
	questions: SurveyQuestion[];
	submissions?: SurveySubmission[];
	createdDate?: Date;
	updatedDate?: Date;
}

export interface SurveyQuestion {
	id?: number;
	index: number,
	text: string;
	type: QuestionType;
	options?: string[];
	survey?: Pick<Survey, 'id'>;
	responses?: SurveyResponse[];
}

export interface SurveySubmission {
	id?: number;
	survey: Pick<Survey, 'id'>;
	responses: SurveyResponse[];
	submittedAt?: Date;
}

export interface SurveyResponse {
	id?: number;
	submission?: Pick<SurveySubmission, 'id'>;
	question:  Pick<SurveyQuestion, 'id'>;
	value: string;
}