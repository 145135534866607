import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { plainToClass } from 'class-transformer';

import { StoryService } from 'src/app/service/story.service';

import { Story, StoryBit, StoryBitInfoType, StoryBitStoryType, StoryBitType } from 'src/app/model/story';
import { Info } from 'src/app/model/info';
import { SwiperOptions } from 'swiper';
import { Category } from 'src/app/model/category';

import { InfoTableDialogComponent } from '../info/info-table-dialog.component';
import { StoryTableDialogComponent } from './story-table-dialog.component';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-story-edit',
	templateUrl: './story-edit.component.html',
	styleUrls: ['./story-edit.component.scss']
})
export class StoryEditComponent implements OnInit {
	story: Story = new Story();
	selectedBit: StoryBit = null;

	StoryBitType = StoryBitType;

	config: SwiperOptions = {
		pagination: { el: '.swiper-pagination', clickable: true },
		effect: 'coverflow',
		coverflowEffect: {
			slideShadows: true,
			rotate: 15,
			stretch: 15,
			depth: 5,
			modifier: 5
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},
		spaceBetween: 30
	};

	constructor(private storyService: StoryService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute,
		private router: Router) {
		this.story = new Story();
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe(
			params => {
				const storyId = params['id'];
				if (storyId != null) {
					this.storyService.getById(storyId).subscribe({
						next: (response) => {
							this.story = plainToClass(Story, response);

							if (this.story.bitList) {
								this.story.bitList = this.story.bitList.map(bit => {
									// Check the type and convert to the appropriate class.
									switch (bit.type) {
										case 'StoryBitInfoType':
											return plainToClass(StoryBitInfoType, bit);
										case 'StoryBitStoryType':
											return plainToClass(StoryBitStoryType, bit);
										default:
											// Handle the case where the type is not recognized or not provided.
											throw new Error("Unrecognized StoryBit type");
									}
								});
							}
						},
						error: (error) => {
							ErrorHelper.handleError(error, this.snackBar);
						}
					});
				}
				else {
					//					this.story = new Story();
				}
			},
			error => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		);
	}

	onSave(): void {
		this.storyService.save(this.story).subscribe({
			next: (response) => {
				this.story = plainToClass(Story, response);
				this.snackBar.open('Story saved', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	onUpdate(): void {
		this.storyService.update(this.story).subscribe({
			next: (response) => {
				this.story = plainToClass(Story, response);
				this.snackBar.open('Story updated', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				console.error(error);
				this.snackBar.open(error.error.message, 'Dismiss', {
					duration: 1000
				});
			}
		});
	}

	onDelete(): void {
		this.storyService.delete(this.story.id).subscribe({
			next: () => {
				this.router.navigate(['story/list']);
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	onDeleteInfo(storyBit: StoryBit): void {
		const index = this.story.bitList.findIndex(bit => bit.equals(storyBit));
		if (index !== -1) {
			this.story.bitList.splice(index, 1);
		}
		
		this.story.bitList.forEach((bit, i) => {
			bit.order = i + 1;
		});
	}

	public onInfoAdd(): void {
		const dialogRef = this.dialog.open(InfoTableDialogComponent);
		dialogRef.afterClosed().subscribe((result: Info[]) => {
			if (result) {
				//
				// make sure no duplicates are added
				result.forEach(info => {
					const index = this.story.bitList.findIndex(bit =>
						bit.type == StoryBitType.SOURCE_BIT && (bit as StoryBitInfoType).infoBit.id == info.id
					);

					if (index < 0) {
						this.story.addInfo(info);
					}
					else {
						this.snackBar.open('One or more items already in story', 'OK', {
							duration: 1000
						});				
					}
				})
			}
		});
	}

	public onStoryAdd(): void {
		const dialogRef = this.dialog.open(StoryTableDialogComponent);
		dialogRef.afterClosed().subscribe((result: Story[]) => {
			if (result) {
				//
				// make sure no duplicates are added
				result.forEach(story => {
					const index = this.story.bitList.findIndex(bit =>
						bit.type == StoryBitType.STORY && (bit as StoryBitStoryType).storyBit.id === story.id
					);

					if (index < 0) {
						this.story.addStory(story);
					}
					else {
						this.snackBar.open('One or more items already in story', 'OK', {
							duration: 1000
						});				
					}
				})
			}
		});
	}

	onInfoSelect(info: Info): void {
		//
		// check to see if it's already been added
		const index = this.story.bitList.findIndex(bit =>
			bit.type == StoryBitType.SOURCE_BIT && (bit as StoryBitInfoType).infoBit.id == info.id
		);
		if (index > -1) {
			this.snackBar.open('Info already in Story', 'OK', {
				duration: 1000
			});

			return;
		}
		else {
			this.story.addInfo(info);
		}

//		if (this.story.bitList == null) {
//			this.story.bitList = [];
//		}
//
//		const storyBit: StoryBit = new StoryBit();
//		storyBit.info = <Info>info;
//		storyBit.order = this.story.bitList.length + 1;
//
//		this.story.bitList.push(storyBit);
	}

	public onCategorySelect(category: Category): void {
		console.log('Category: ' + category?.name);
		this.story.category = category;
	}

	public onCategoryRemove(category: Category): void {
		this.story.category = null;
	}

	drop(event: CdkDragDrop<StoryBit>): void {
		moveItemInArray(this.story.bitList, event.previousIndex, event.currentIndex);
		
		this.story.bitList.forEach((bit, i) => {
			bit.order = i + 1;
		});
	}

	onClick(index: number): void {
		this.selectedBit = this.story.bitList[index];
	}

//	private getStory(id: string): Observable<Story> {
//		return this.storyService.getById(id);
//	}

}
