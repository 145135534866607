<h3>Category Edit</h3>
<div fxLayout="column" fxLayoutAlign="space-evenly start">
	<div fxLayout="row" fxLayoutAlign="space-evenly start" fxLayoutGap="10px" fxFill>
		<!-- top form -->
		<form class="form-group source-form" fxFlex="80">
			<div fxLayout="column" fxLayoutAlign="space-evenly start">
				<!--  name -->
				<mat-form-field class="source-field">
					<mat-label>Name</mat-label>
					<input matInput placeholder="Name" [(ngModel)]="category.name" [ngModelOptions]="{standalone: true}">
				</mat-form-field>
			 
			 	<!--  description -->
				<mat-form-field class="source-field">
					<mat-label>Description</mat-label>
					<textarea	matInput placeholder="Description" [(ngModel)]="category.description"
						[ngModelOptions]="{standalone: true}">
					</textarea>
				</mat-form-field>

				<!-- image -->
				<mat-form-field class="source-field">
					<mat-label>Image</mat-label>
					<input matInput placeholder="Image" [(ngModel)]="category.image"
						[ngModelOptions]="{standalone: true}"> 
				</mat-form-field>
				
				<!-- tags -->
				<app-taglookup matInput
					[readOnly]="false"
					[tagType]="tagType"
					[tags]="category.tags"
					(add)="onAddTag($event)"
					(remove)="onRemoveTag($event)">
				</app-taglookup>
			</div>
			
			<!-- buttons -->
			<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" fxFill>
				<button *ngIf="category.id == null" mat-stroked-button color="primary" type="button" (click)="onSave()">
				  <em class="fa fa-plus"></em> Save
				</button>
				
				<button *ngIf="category.id != null" mat-stroked-button color="primary" type="button" (click)="onUpdate()">
					<em class="fa fa-plus"></em> Update
				</button>
				
				<button mat-stroked-button confirm-dialog ="accent" type="button" [disabled]="category.id == null" (confirmed)="onDelete()">
				  <em class="fa fa-plus"></em> Delete
				</button>
				
				<button mat-stroked-button color="accent" type="button" [disabled]="category.id == null" (click)="onAddCategory()">
				  <em class="fa fa-plus"></em> Add Category
				</button>
			</div>
		</form>
	
		<!-- category tree -->
		<div class="category-tree-container" fxFlex="20"fxHide.xs>
			<span class="section-title">Category Tree</span>
			<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="category-tree">
				<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
					<span [class.category-tree-current-node]="node.categoryId == category.id">
						{{node.name}}
					</span>
				</mat-tree-node>
			
				<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
				    <div class="mat-tree-node">
				      <button mat-icon-button matTreeNodeToggle
				              [attr.aria-label]="'Toggle ' + node.name">
				        <mat-icon class="mat-icon-rtl-mirror">
				          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
				        </mat-icon>
				      </button>
				      <span [class.category-tree-current-node]="node.categoryId == category.id">
				      	{{node.name}}
				      </span>
				    </div>
					<div [class.category-tree-invisible]="!treeControl.isExpanded(node)" role="group">
						<ng-container matTreeNodeOutlet></ng-container>
					</div>
				</mat-nested-tree-node>
			</mat-tree>	
		</div>
	</div>

	<mat-tab-group fxFill>
		<!-- sources -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>article</mat-icon>&nbsp; Sources
			</ng-template>
			<ng-template matTabContent>
				<app-source-table *ngIf="category?.id != null" [tagsFilter]="category.tags" [lazy]="true"></app-source-table>
			</ng-template>		
		</mat-tab>

		<!-- top sources -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>flare</mat-icon><mat-icon>article</mat-icon>&nbsp; Top Sources
			</ng-template>
			<ng-template matTabContent>
				<button mat-stroked-button color="primary" (click)="onSourceDialog()">
					<mat-icon>add</mat-icon> Add Source
				</button>
				<app-document-list [type]="'SOURCE'" [data]="category.sourceList"></app-document-list>
			</ng-template>		
		</mat-tab>

		<!-- bits -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>description</mat-icon>&nbsp; Excerpts
			</ng-template>
			<ng-template matTabContent>
				<app-info-table *ngIf="(isLoading | async) == false" [sourceTags]="category.tags" [editable]="true"></app-info-table>
			</ng-template>		
		</mat-tab>

		<!-- top bits -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>flare</mat-icon><mat-icon>article</mat-icon>&nbsp; Top Excerpts
			</ng-template>
			<ng-template matTabContent>
				<button mat-stroked-button color="primary" (click)="onInfoDialog()">
					<mat-icon>add</mat-icon> Add Source Bit
				</button>
				<app-document-list [type]="'INFO'" [data]="category.infoList"></app-document-list>
			</ng-template>		
		</mat-tab>
		
		<!-- story -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>auto_stories</mat-icon>&nbsp; Stories
			</ng-template>
			<ng-template matTabContent>
				<app-story-table [fixedCategoryFilter]="category" [lazy]="true"></app-story-table>
			</ng-template>		
		</mat-tab>
	</mat-tab-group>
</div>
