import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { Source, SourceDetails, SourceFilters } from 'src/app/model/source';
import { Info } from 'src/app/model/info';
import { TagUtility } from 'src/app/model/tag';
import { PagingResponse } from 'src/app/model/paging-response';

import { StorageService } from './storage.service';

import { environment } from 'src/environments/environment';


const { NLP_URL } = environment;

@Injectable({
	providedIn: 'root'
})
export class SourceService {
	private baseUrl: string = environment.serverUrl + 'sources';

	constructor(private storageService: StorageService,
		protected http: HttpClient) {}

	public getById(id: number): Observable<Source> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.get<Source>(url).pipe(
			map((source: Source) => {
				if (source.infoList) {
					const partialSource = new Source();
					partialSource.id = source.id
					partialSource.reference = source.reference;
					partialSource.title = source.title;
					source.infoList = source.infoList.map((info: Info) => {
						info.source = partialSource;
						return info;
					});
				}

				return source;
			})
		);
	}

	public getByReference(reference: string): Observable<Source> {
		const params = new HttpParams()
			.set('uri', reference);
		const url = `${this.baseUrl}/reference`;

		return this.http.get<Source>(url, { params });
	}	

	public getList(
		page: number = 1,
		limit: number = 10,
		filters?: SourceFilters
	): Observable<PagingResponse<Source>> {
		let params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		if (filters) {
			if (filters.author) {
				params = params.set('authorId', filters.author.id);
			}

			if (filters.organization) {
				params = params.set('organizationId', filters.organization.id);
			}

			if (filters.rank !== undefined) {
				params = params.set('rank', filters.rank.toString());
			}

			if (filters.tags) {
				filters.tags.forEach(tag => {
					params = params.append('tags', tag.name);
				});
			}
		}

		return this.http.get<PagingResponse<Source>>(`${this.baseUrl}`, { params });
	}

	public save(source: Source): Observable<Source> {
		source.createdBy = this.storageService.getItem('USER');
		
		//
		// sort and uppercase all tags
		source.tags = TagUtility.sortAndUpperCase(source.tags);
		
		return this.http.post<Source>(`${this.baseUrl}`, source);
	}

	public update(source: Source): Observable<Source> {
		//
		// sort and uppercase all tags
		source.tags = TagUtility.sortAndUpperCase(source.tags);
		
		return this.http.put<Source>(`${this.baseUrl}/${source.id}`, source);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.delete(url);
	}

	public urlToSource(url: string): Observable<any> {
		return this.http.get<any>(url, { responseType: 'text' as 'json' });
	}

	public fetchUrl(url: string): Observable<any> {
		const corsUrl = NLP_URL + '/' + url;
		return this.http.get<any>(corsUrl, { responseType: 'text' as 'json' });
	}

	public getDetailsByUrl(url: string): Observable<SourceDetails> {
		const body = { url: url };

		return this.http.post<SourceDetails>(`${NLP_URL}/api/process-url`, body);
	}
	
	public getDetailsByHtml(html: string): Observable<SourceDetails> {
		const headers = new HttpHeaders({
			'Content-Type': 'text/html'
		});

		return this.http.post<SourceDetails>(`${NLP_URL}/api/process-html`, html, { headers });
	}
}

