<div fxLayout="column" fxLayoutAlign="space-around center">
	<span class="story-title">{{story.title}}</span>
	<swiper [virtual]="true"
		[slidesPerView]="1"
		[spaceBetween]="30"
		[navigation]="true"
		[pagination]="{
			type: 'fraction',
			renderFraction: renderFraction
		}"
		(slideChangeTransitionStart)="onSlideChange($event)">

		<ng-template swiperSlide>
			<div class="story-bit-summary">
				<div class="story-cover">
					<p>{{story.description}}</p>
				</div>
			</div>
			<div class="story">
				<img [src]="story.bitList[0]?.getImage()" />
				<mat-accordion>
					<mat-expansion-panel class="references-panel">
						<mat-expansion-panel-header>
							<mat-panel-title class="references-title">
								References
							</mat-panel-title>
						</mat-expansion-panel-header>

						<div class="references-container">
							<a *ngFor="let organization of story.getOrganizations()" class="reference-tag" [routerLink]="['/organization', organization.id]">
								{{ organization.name }}
							</a>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</ng-template>

		<ng-template swiperSlide *ngFor="let storyBit of story.bitList">
			<div *ngIf="!storyBit.type || storyBit.type == StoryBitType.SOURCE_BIT; else story_panel">
				<div class="story-bit-summary">
					<ng-container *ngIf="storyBit.description; else info_summary">
						<span>{{storyBit.description}}</span>
					</ng-container>
					<ng-template #info_summary>
						<span>{{storyBit.infoBit.summary}}</span>
					</ng-template>
				</div>
				<div class="story">
					<img [src]="storyBit.image ? storyBit.image : storyBit.infoBit.source.image"/>
					<span class="story-bit-title">{{storyBit.infoBit.source.title}}</span>
				  	<markdown class="story-bit-detail" [data]="storyBit.infoBit.detail"></markdown>
					<span class="story-bit-reference" fxLayout="row" fxLayoutAlign="space-between center">
						<a confirm-navigate href="#" [source]="storyBit.infoBit.source">
							<mat-icon aria-hidden="false" aria-label="Edit" size="20">link</mat-icon>{{storyBit.infoBit.source.reference | hostname}}
						</a>
						<a [routerLink]="['/source', storyBit.infoBit.source.id]">
							<mat-icon aria-hidden="false" aria-label="Edit" size="20">info</mat-icon>
						</a>
					</span>
				</div>
			</div>
			
			<!-- Nested story swiper -->
			<ng-template #story_panel>
				<swiper #swiper [virtual]="true" id="{{storyBit.storyBit.id}}"
					[slidesPerView]="1"
					[spaceBetween]="30"
					[direction]="'vertical'" >

					<ng-template swiperSlide>
						<div class="story-bit-summary">
							<div class="story-cover">
								<p>{{storyBit.storyBit?.description}}</p>
							</div>
						</div>
						<div class="story story-vertical">
							<div class="story-vertical-container">
								<img [src]="story.bitList[0]?.getImage()"/>

								<!-- prev button -->		
								<div class="story-vertical-prev" [class.story-vertical-disabled]="!hasPrevious(storyBit.storyBit.id)">
									<svg (click)="onPrev(storyBit.storyBit.id)" viewBox="0 0 24 24" width="90%">
			  							<path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
									</svg>
								</div>

								<!-- next button -->
								<div class="story-vertical-next" [class.story-vertical-disabled]="!hasNext(storyBit.storyBit.id)">
									<svg id="chevron" (click)="onNext(storyBit.storyBit.id)" viewBox="0 0 24 24" width="90%" >
			  							<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
									</svg>
								</div>
							</div>
						</div>
					</ng-template>

					<ng-container *ngFor="let nestedStoryBit of storyBit.storyBit?.bitList">
						<ng-template swiperSlide>
							<ng-container *ngIf="nestedStoryBit.description; else nested_info_summary">
								<span class="story-bit-summary">{{nestedStoryBit.description}}</span>
							</ng-container>
							<ng-template #nested_info_summary>
								<span class="story-bit-summary">{{nestedStoryBit.infoBit.summary}}</span>
							</ng-template>
							<div class="story-vertical">
								<div class="story-vertical-container">
									<img [src]="nestedStoryBit.image ? nestedStoryBit.image : nestedStoryBit.infoBit.source.image"/>

									<!-- prev button -->
									<div class="story-vertical-prev" [class.story-vertical-disabled]="!hasPrevious(storyBit.storyBit.id)">
										<svg (click)="onPrev(storyBit.storyBit.id)" viewBox="0 0 24 24" width="90%">
				  							<path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
										</svg>
									</div>

									<!-- next button -->
									<div class="story-vertical-next" [class.story-vertical-disabled]="!hasNext(storyBit.storyBit.id)">
										<svg id="chevron" (click)="onNext(storyBit.storyBit.id)" viewBox="0 0 24 24" width="90%" >
				  							<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
										</svg>
									</div>
								</div>
								<span class="story-bit-title">{{nestedStoryBit.infoBit.source.title}}</span>
							  	<markdown class="story-bit-detail" [data]="nestedStoryBit.infoBit.detail"></markdown>
								<span class="story-bit-reference" fxLayout="row" fxLayoutAlign="space-between center">
									<a confirmNavigate [source]="nestedStoryBit.infoBit.source">
										<mat-icon aria-hidden="false" aria-label="Edit" size="20">info</mat-icon>{{nestedStoryBit.infoBit.source.reference | hostname}}
									</a>
									<a [routerLink]="['/source', nestedStoryBit.infoBit.source.id]">
										<mat-icon aria-hidden="false" aria-label="Edit" size="20">info</mat-icon>
									</a>
								</span>					
							</div>
						</ng-template>
					</ng-container>
				</swiper>
			</ng-template>
		</ng-template>
	</swiper>
</div>

