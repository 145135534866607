import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from 'src/app/service/login.service';

import { Organization } from 'src/app/model/organization';
import { Author } from 'src/app/model/author';

import { LoadingComponent } from '../loading-component';

import { SourceFilters } from '../../model/source';
import { Tag, TagUtility } from 'src/app/model/tag';

@Component({
	selector: 'app-source-list',
	templateUrl: './source-list.component.html',
	styleUrls: ['./source-list.component.scss']
})
export class SourceListComponent extends LoadingComponent implements OnInit {
	organizationFilter: Organization;
	authorFilter: Author;
	status: string;

	tags: Tag[] = [];

	constructor(private loginService: LoginService,
		private activatedRoute: ActivatedRoute,
		private router: Router) {
		super();
	}

	public ngOnInit(): void {
		const params = this.activatedRoute.snapshot.queryParams;
		const tags = params['tag'];

		if (tags) {
			if (Array.isArray(tags)) {
				tags.forEach(tag => {
					const decodedTag = decodeURIComponent(tag);
					const tagObject = TagUtility.createTag(decodedTag);
					this.tags.push(tagObject);
				});
			} else {
				const decodedTag = decodeURIComponent(tags);
				const tagObject = TagUtility.createTag(decodedTag);
				this.tags.push(tagObject);
			}
		}

		this.done();
	}

	public onFilterChange(filters: SourceFilters): void {
		const queryParams = filters.tags.length > 0
			? { tag: filters.tags.map(tag => encodeURIComponent(tag.name)) }
			: { tag: null };

		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams,
			queryParamsHandling: 'merge' // merge with existing query params
		});
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

}
