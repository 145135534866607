import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Category, CategoryFilters, CategoryTreeNode } from 'src/app/model/category';
import { PagingResponse } from 'src/app/model/paging-response';

import { environment } from 'src/environments/environment';
import { AuthorGrade, AuthorReportCard, DisplayType, OrganizationGrade, OrganizationReportCard } from '../model/report-card';
import { AuthorReputation } from '../model/reputation';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
	private baseUrl: string = environment.serverUrl + 'categories';

	constructor(protected http: HttpClient) {}

	public getById(id: number): Observable<Category> {
		return this.http.get<Category>(`${this.baseUrl}` + '/' + id, httpOptions);
	}
	
	public getCateogryList(
		page: number = 1,
		limit: number = 10,
		filters?: CategoryFilters
	): Observable<PagingResponse<Category>> {
		let params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		if (filters) {
			params = params.set('story', filters.story);
		}

		return this.http.get<PagingResponse<Category>>(`${this.baseUrl}`, { params });
	}
	
	public getAuthorsReportCard(categoryId: number): Observable<AuthorReportCard> {
		return this.http.get<AuthorReportCard>(`${this.baseUrl}/${categoryId}/author-report-card`);
	}

	public getOrganizationsReportCard(categoryId: number): Observable<OrganizationReportCard> {
		return this.http.get<OrganizationReportCard>(`${this.baseUrl}/${categoryId}/organization-report-card`);
	}

	public getAuthorReputations(categoryId: number, type: DisplayType, page: number = 1, limit: number = 10): Observable<AuthorGrade[]> {
		let params = new HttpParams()
			.set('type', type.toString())
			.set('page', page.toString())
			.set('limit', limit.toString());

		return this.http.get<AuthorGrade[]>(`${this.baseUrl}/${categoryId}/author-reputations`, { params });
	}

	public getOrganizationReputations(categoryId: number, type: DisplayType, page: number = 1, limit: number = 10): Observable<OrganizationGrade[]> {
		let params = new HttpParams()
			.set('type', type.toString())
			.set('page', page.toString())
			.set('limit', limit.toString());

		return this.http.get<OrganizationGrade[]>(`${this.baseUrl}/${categoryId}/organization-reputations`, { params });
	}

	public searchByName(name: string): Observable<Category[]> {
		const url = `${this.baseUrl}` + '/search';
		const params = new HttpParams()
			.set('q', name);

		return this.http.get<Category[]>(url, { params });
	}
	
	public save(category: Category): Observable<Category> {
		return this.http.post<Category>(`${this.baseUrl}`, category);
	}

	public saveChild(category: Category, parentId: number): Observable<Category> {
		const url = `${this.baseUrl}/${parentId}`;
	
		return this.http.post<Category>(url, category);
	}

	public update(category: Category): Observable<Category> {
		const url = `${this.baseUrl}/${category.id}`;

		return this.http.put<Category>(url, category);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.delete(url);
	}
	
	public getTreeNodesById(id: number): Observable<CategoryTreeNode> {
		const url = `${this.baseUrl}/trees/${id}`;

		return this.http.get<CategoryTreeNode>(url);
	}

}
