import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { User, UserRegistration } from '../model/user';
import { StorageService } from './storage.service';

const JWT_TOKEN = 'token';
const JWT_TOKEN_EXP = 'token_exp';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	private baseUrl: string = environment.serverUrl + 'login';

	constructor(private http: HttpClient,
		private storageService: StorageService) { }

	public login(login: string, password: string) {
		const userLogin: UserLogin = new UserLogin();
		userLogin.login = login;
		userLogin.password = password;

		return this.http.post<any>(`${this.baseUrl}`, userLogin)
			.pipe(map(response => {
				const loginResponse = JSON.parse(atob(response.split('.')[1]));
				
				//
				// store user and jwt login
				let user = new User();
				user.id = loginResponse.userId;

				this.storageService.setItem('USER', user);
				localStorage.setItem(JWT_TOKEN, response);
				localStorage.setItem(JWT_TOKEN_EXP, loginResponse.exp);

				return response;
			}));
	}

	public logout(): void {
		localStorage.removeItem(JWT_TOKEN);
		localStorage.removeItem(JWT_TOKEN_EXP);
	}

	public isLoggedIn(): boolean {
		const jwtExp = localStorage.getItem(JWT_TOKEN_EXP);

		if (!jwtExp) {
			return false;
		}
		else {
			//
			// exp is seconds, getTime() in milliseconds
			const date = new Date();
			
			return Number(jwtExp) * 1000 > date.getTime();
		}
	}

	public register(user: UserRegistration) {
//		user.id = User.getIdentifier(user);
		return this.http.post(`${environment.serverUrl}/register`, user);
	}
}

class UserLogin {
	login: string;
	password: string;
}
