import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion'; 

import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MarkdownModule } from 'ngx-markdown';
import { SwiperModule } from 'swiper/angular';

import { StoryComponent } from './story.component';
import { StoryListComponent } from './story-list.component';
import { StoryEditComponent } from './story-edit.component';
import { StoryRoutingModule } from './story-routing.module';

import { SharedModule } from 'src/app/component/shared/shared.module';
import { StorySearchComponent } from './story-search.component';

@NgModule({
	declarations: [
		StoryComponent,
		StoryListComponent,
		StoryEditComponent,
  		StorySearchComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		FontAwesomeModule,
		MarkdownModule,
		SwiperModule,
		StoryRoutingModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		DragDropModule,
		MatIconModule,
		MatInputModule,
		MatExpansionModule,
		SharedModule
	],
	exports: [
		StoryComponent,
		StoryListComponent,
		StoryEditComponent
	]
})
export class StoryModule { }