<div class="report-card-container">
    <div class="header">
        <div class="header-item">
            <span *ngIf="isAuthorReportCard(); else orgHeader">Author</span>
            <ng-template #orgHeader><span>Organization</span></ng-template>
        </div>
        <div class="header-item rank-column">Rank</div>
    </div>
    <div class="body">
        <ng-container *ngIf="isAuthorReportCard(); else orgRows">
            <div class="row" *ngFor="let grade of (displayType === 'top' ? reportCard.topGrades : reportCard.bottomGrades)"
                (click)="onRowClick(grade.author.id)">
                <div class="row-item">{{ grade.author | author }}</div>
                <div class="row-item rank-column">
                    <fa-icon [icon]="[getIcon(grade.rank)[0], getIcon(grade.rank)[1]]"
                             [style.color]="getIcon(grade.rank)[2]" size="xl">
                    </fa-icon>
                </div>
            </div>
        </ng-container>
        <ng-template #orgRows>
            <div class="row" *ngFor="let grade of (displayType === 'top' ? reportCard.topGrades : reportCard.bottomGrades)"
                (click)="onRowClick(grade.organization.id)">
                <div class="row-item">{{ grade.organization.name }}</div>
                <div class="row-item rank-column">
                    <fa-icon [icon]="[getIcon(grade.rank)[0], getIcon(grade.rank)[1]]"
                             [style.color]="getIcon(grade.rank)[2]" size="xl">
                    </fa-icon>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<mat-paginator [length]="total" [pageSize]="limit" [hidePageSize]="true" (page)="onPageChange($event)">
</mat-paginator>
