import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap } from 'rxjs/operators';
import { Author } from 'src/app/model/author';
import { AuthorService } from 'src/app/service/author.service';

@Component({
	selector: 'app-author-search',
	templateUrl: './author-search.component.html',
	styleUrls: ['./author-search.component.scss']
})
export class AuthorSearchComponent implements OnInit {
	authors: Observable<Author[]>;
	searchField: FormControl = new FormControl();
	isInputVisible: boolean = false;

	@Output() authorSelected: EventEmitter<Author> = new EventEmitter<Author>();

	constructor(private authorService: AuthorService) { }

	ngOnInit() {
		this.authors = this.searchField.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(name => name.length > 0),
			switchMap(name => this.authorService.searchByName(name))
		);
	}

	public toggleInputVisibility() {
		this.isInputVisible = !this.isInputVisible;
	}

	displayFn(author: Author): string {
		return author ? `${author.firstName} ${author.lastName}` : '';
	}

	onSelect(author: Author): void {
		this.authorSelected.emit(author);
	}
}
