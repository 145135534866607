<!--The content below is only a placeholder and can be replaced.-->


<div fxLayout="column" style="padding: 5px">
	<app-toolbar></app-toolbar>

    <div fxFlex style="padding: 10px">
		<div fxFill fxLayout="row">
			
			<!-- left panel -->
			<div fxFlex fxHide.xs>
			</div>
		
			<!--  content -->
			<div fxFlex="100">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>

<!-- <div class="content"> -->
<!-- 	<router-outlet></router-outlet> -->
<!-- </div> -->
