import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { Category } from 'src/app/model/category';
import { Rank, Reputation } from 'src/app/model/reputation';

@Component({
	selector: 'app-report-card',
	templateUrl: './report-card.component.html',
	styleUrls: ['./report-card.component.scss']
})
export class ReportCardComponent implements OnInit {
	
	getIcon = Reputation.getIcon;
	
	private _reputationList: Reputation[] = [];
	
	@Input()
	set reputationList(value: Reputation[]) {
        this._reputationList = value.sort((a, b) => {
            // Combine path and name for sorting if they are not the same,
            // otherwise treat them as a single entity "path" only
            let aCombined = a.category.path + (a.category.path !== a.category.name ? ':' + a.category.name : '');
            let bCombined = b.category.path + (b.category.path !== b.category.name ? ':' + b.category.name : '');

            // Compare the combined path and name
            return aCombined.localeCompare(bCombined);
        });

		this.totalSize = value ? this._reputationList.length : 0;
	}

	get reputationList(): Reputation[] {
		return this._reputationList;
	}

	@Input()
	editable: boolean = false;
	
	pageSize = 5; // Default page size
	currentPage = 0;
	totalSize = this._reputationList.length;
	
	RankEnum = Rank;
	
	constructor() { }

	public ngOnInit(): void {

	}

	public onCategory(category: Category, reputation: Reputation): void {
		reputation.category = category;
	}

	public onRank(reputation: Reputation, rank: Rank): void {
		reputation.rank = rank;
	}

	public getCategoryLevel(category: Category): number {
		const basePadding = 5;

		//
		// make sure category is valid
		if (!category || !category.name || !category.path) {
			return 0; // Return 0 or some default padding when category data is incomplete
		}		

		//
		// If the category is a root element, return just the base padding
		if (category.name === category.path) {
			return basePadding;
		}

		//
		// Count the number of colons plus one for indentation levels
		const numberOfColons = (category.path.match(/:/g) || []).length;

		//
		// Calculate total padding: base padding + 20 pixels per level of hierarchy
		return basePadding + (numberOfColons + 1) * 20;
	}

	public getCategoryClass(category: Category): string {
		if (!category || !category.name || !category.path) {
			return 'category-level-0';
		}
		
		if (category.name === category.path) {
			return 'category-level-0';
		}
		else if (!category.path.includes(':')) {
			return 'category-level-1';
		}
		else {
			return 'category-level-2';
		}
		
//		const level = this.getCategoryLevel(category);
//		if (level === 0) {
//			return 'category-level-0';
//		}
//		else if (level === 1) {
//			return 'category-level-1';
//		}
//		else if (level === 2) {
//			return 'category-level-2';
//		}
//		else {
//			return 'category-level-3';
//		}
	}

	public getDisplayedReputations(): Reputation[] {
		const pageReputations = this.getReputationPage();
		if (this._reputationList.length < this.pageSize) {
			return this._reputationList;
		}
	
		const placeholderCount = this.pageSize - pageReputations.length;
		for (let i = 0; i < placeholderCount; i++) {
			pageReputations.push(new Reputation());
		}

		return pageReputations;
	}

	public getRankIcon(currentRank: number, checkRank: Rank): string {
		//
		// Check if the current rank matches any of the rank thresholds
		if (checkRank === Rank.AWFUL && currentRank < 1.5) {
			return 'check';
		}
		else if (checkRank === Rank.BAD && currentRank >= 1.5 && currentRank < 2.5) {
			return 'check';
		}
		else if (checkRank === Rank.NEUTRAL && currentRank >= 2.5 && currentRank < 3.5) {
			return 'check';
		} 
		else if (checkRank === Rank.GOOD && currentRank >= 3.5 && currentRank < 4.5) {
			return 'check';
		}
		else if (checkRank === Rank.GREAT && currentRank >= 4.5) {
			return 'check';
		}
		else {
			return '';
		}
	}

	public isAwful(rank: number): boolean {
		return rank < 1.5;
	}

	public isBad(rank: number): boolean {
		return rank >= 1.5 && rank < 2.5;
	}

	public isNeutral(rank: number): boolean {
		return rank >= 2.5 && rank < 3.5;
	}

	public isGood(rank: number): boolean {
		return rank >= 3.5 && rank < 4.5;
	}

	public isGreat(rank: number): boolean {
		return rank >= 4.5;
	}

	public onAdd(): void {
		if (!this.reputationList) {
			this.reputationList = [];
		}

		const reputation = new Reputation();
		reputation.rank = Rank.AWFUL;
		this.reputationList.push(reputation);
	}
	
	public onDelete(index: number): void {
		this.reputationList.splice(index, 1);
	}

	public getReputationPage(): Reputation[] {
		const startIndex = this.currentPage * this.pageSize;
		return this.reputationList.slice(startIndex, startIndex + this.pageSize);
	}

	public handlePageEvent(event: PageEvent) {
		this.pageSize = event.pageSize;
		this.currentPage = event.pageIndex;
	}

}
