<h3>Organization</h3>
<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
	<div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="5px">	
		<form fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="5px">
			<div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px">
				<!-- Name -->
				<mat-form-field class="source-field">
					<input matInput placeholder="Name" [(ngModel)]="organization.name" [ngModelOptions]="{standalone: true}">
				</mat-form-field>
				
				<!-- Site -->	
				<div *ngFor="let site of organization.sites; let i = index">
				    <mat-form-field class="source-field">
				        <input matInput placeholder="Site" [(ngModel)]="organization.sites[i]" [ngModelOptions]="{standalone: true}">
				    </mat-form-field>
				</div>

				<mat-form-field>
					<mat-label>Description</mat-label>
					<textarea matInput placeholder="Description" [(ngModel)]="organization.description"
						[ngModelOptions]="{standalone: true}">
					</textarea>
				</mat-form-field>
			</div>

			<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px">
				<button *ngIf="organization.id == null" mat-stroked-button type="button" (click)="onSave()">
					<mat-icon>save</mat-icon> Save
				</button>
				
				<button *ngIf="organization.id != null" mat-stroked-button type="button" (click)="onUpdate()">
					<mat-icon>save</mat-icon> Update
				</button>
				
				<button *ngIf="organization.id != null" confirm-dialog mat-stroked-button type="button" (confirmed)="onDelete()">
					<mat-icon>delete</mat-icon> Delete
				</button>
				
				<button mat-stroked-button type="button" (click)="onCancel()">
					<mat-icon>cancel</mat-icon> Cancel
				</button>
			</div>
		</form>

		<app-report-card [reputationList]="organization.reputationList" [editable]="true"></app-report-card>
	</div>
</div>

