import { Component, OnInit, ViewChild } from '@angular/core';
import { StoryTableComponent } from './story-table.component';

@Component({
	selector: 'app-story-table-dialog',
	templateUrl: './story-table-dialog.component.html',
	styleUrls: ['./story-table-dialog.component.scss']
})
export class StoryTableDialogComponent implements OnInit {

	@ViewChild('storyTable')
	storyTable: StoryTableComponent;

	constructor() { }

	ngOnInit(): void {
	}

}
