import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-html-input-dialog',
	templateUrl: './html-input-dialog.component.html',
	styleUrls: ['./html-input-dialog.component.scss']
})
export class HtmlInputDialogComponent {
	html: string = '';

	constructor(
		public dialogRef: MatDialogRef<HtmlInputDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	public onCancel(): void {
		this.dialogRef.close();
	}

	public onSubmit(): void {
		this.dialogRef.close(this.html);
	}
}
