<mat-form-field class="source-field" [floatLabel]="false">
	<mat-label>{{label}}</mat-label>
	<mat-chip-grid #authorList>
		<mat-chip-row *ngFor="let author of authors"
			[selectable]="!readOnly"
			[removable]="!readOnly"
			(removed)="remove(author)"
			(click)="onClick(author)">
			{{author | author}}
			<mat-icon *ngIf="!readOnly" matChipRemove>cancel</mat-icon>
		</mat-chip-row>
		<input #authorInput placeholder="Search authors..."
			[formControl]="authorControl"
			[readonly]="readOnly"
			[matAutocomplete]="authorComponentAutocomplete"
			[matChipInputFor]="authorList"
			[matChipInputSeparatorKeyCodes]="separatorKeyCodes"
			[matChipInputAddOnBlur]="true"
			(matChipInputTokenEnd)="add($event)" />
	</mat-chip-grid>

	<mat-autocomplete #authorComponentAutocomplete="matAutocomplete" [displayWith]="displayAuthor" (optionSelected)="onAuthorSelected($event)">
		<mat-option *ngFor="let author of authorsObs | async" [value]="author">
			 {{author | author}}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
