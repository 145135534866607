import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Author } from 'src/app/model/author';
import { Category } from 'src/app/model/category';
import { Organization } from 'src/app/model/organization';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	constructor(private router: Router) { }

	ngOnInit(): void {
	}

	public onAuthor(author: Author): void {
		this.router.navigate(['/author', author.id]);
	}

	public onCategory(category: Category): void {
		this.router.navigate(['/category', category.id]);
	}
	
	public onOrganization(organization: Organization): void {
		this.router.navigate(['/organization', organization.id]);
	}

}
