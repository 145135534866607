<mat-dialog-content>
	<p>
		<span>{{data.message}}</span>
	</p>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button [mat-dialog-close]="false">No</button>
	<button mat-stroked-button [mat-dialog-close]="true" color="warn"
		style="margin-right: 1em">Yes</button>
</mat-dialog-actions>