import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, switchMap, tap, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { OrganizationService } from '../../service/organization.service';

import { Organization } from '../../model/organization';

@Component({
	selector: 'app-organization-search',
	templateUrl: './organization-search.component.html',
	styleUrls: ['./organization-search.component.scss']
})
export class OrganizationSearchComponent implements OnInit {
	@Input()
	label: string = 'Search organizations...';

	@Input()
	organization: Organization;

	organizations: Observable<Organization[]>;

	searchField: FormControl = new FormControl();
	
	isInputVisible: boolean = false;

	@Output()
	organizationSelected: EventEmitter<Organization> = new EventEmitter<Organization>();

	@Output()
	organizationName: EventEmitter<string> = new EventEmitter<string>();

	constructor(private organizationService: OrganizationService) { }

	ngOnInit() {
		this.organizations = this.searchField.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(name => name.length > 0),
			tap(name => this.organizationName.emit(name)),
			switchMap(name => this.organizationService.searchByName(name))
		);
		this.searchField.setValue(this.organization);
	}

	public displayFn(organization: Organization): string {
		return organization ? organization.name : '';
	}

	public onSelect(organization: Organization): void {
		this.organizationSelected.emit(organization);
	}

	public toggleInputVisibility() {
		this.isInputVisible = !this.isInputVisible;
	}
}
