import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Author } from 'src/app/model/author';
import { AuthorService } from 'src/app/service/author.service';
import { LoginService } from 'src/app/service/login.service';
import { StorageProperty } from 'src/app/service/storage.service';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-author',
	templateUrl: './author.component.html',
	styleUrls: ['./author.component.scss']
})
export class AuthorComponent implements OnInit {
	author: Author;

	filtersProperty: StorageProperty = StorageProperty.AUTHOR_FILTERS;

	constructor(private authorService: AuthorService,
		private loginService: LoginService,
		private snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute) { }

	ngOnInit() {
		this.author = new Author('');
		const authorId = this.activatedRoute.snapshot.params['id'];

		if (authorId) {
			this.authorService.getById(authorId).subscribe({
				next: (response) => {
					this.author = response;
					if (!this.author.reputationList) {
						this.author.reputationList = [];
					}
				},
				error: (error) => {
					ErrorHelper.handleError(error, this.snackBar);
				}
			});
		}
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

}
