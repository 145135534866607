import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SourceService } from 'src/app/service/source.service';
import { Source, SourceFilters, SourceStatus } from '../../model/source';
import { Tag, TagType, TagUtility } from 'src/app/model/tag';

import { PaginatorComponent } from '../paging/paginator.component';
import { Organization } from 'src/app/model/organization';
import { Author } from 'src/app/model/author';
import { StorageService, StorageProperty } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { Reputation } from 'src/app/model/reputation';

@Component({
	selector: 'app-source-table',
	templateUrl: './source-table.component.html',
	styleUrls: ['./source-table.component.scss']
})
export class SourceTableComponent extends PaginatorComponent<Source> implements OnInit {
	sourceList: Source[] = [];
	sourceColumns: string[] = ['select','reference', 'organization', 'date', 'rank'];

	statusEnum = SourceStatus;
	statusList = [];

	getIcon = Reputation.getIcon;

	ranks: number[] = [1, 2, 3, 4, 5];
	
	selected: Source[] = [];
	
	filters: SourceFilters = { tags: []};
	tagType = TagType.Source;
	
	@Input()
	lazy: boolean = false;
	
	@Input()
	addable: boolean = false;
	
	@Input()
	selectable: boolean = false;	

	@Input()
	filtersProperty: StorageProperty = StorageProperty.SOURCE_FILTERS;

	@Input()
	set tags(tags: Tag[]) {
		this.filters.tags = tags;
	}

	@Input()
	set organization(organization: Organization) {
		this.filters.organization = organization;
		this.fixedOrganization = true;
	}
	
	fixedOrganization: boolean = false;
	
	@Input()
	set author(author: Author) {
		this.filters.author = author;
		this.fixedAuthor = true;
	}

	get authors(): Author[] {
		return this.filters.author ? [this.filters.author] : [];
	}

	fixedAuthor: boolean = false;

	@Output()
	filtersChange = new EventEmitter<SourceFilters>();

	constructor(private sourceService: SourceService,
				private storageService: StorageService,
				private snackBar: MatSnackBar,
				private navigationService: NavigationService) {
		super();

		//
		// create source status enums
		const statusKeys = Object.keys(this.statusEnum);
		for (const k of statusKeys) {
			this.statusList.push({ key: k, value: this.statusEnum[k] });
		}
	}
	 
	public ngOnInit(): void {
		if (!this.selectable) {
			this.sourceColumns.shift();
		}

		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			//
			// filters
			this.filters = this.storageService.getItem<SourceFilters>(this.filtersProperty);
			if (!this.filters) {
				this.filters = { tags: []}
			}
			else {
				//
				// page
				this.pageIndex = this.filters.pageIndex;
			}
		}
		else {
			this.storageService.setItem(this.filtersProperty, this.filters);
		}

		super.ngOnInit();
	}

	public fetch(): void {
		this.storageService.setItem(this.filtersProperty, this.filters);

		//
		// reset list
		this.reset();
	}

	public onOrganization(organization: Organization): void {
		this.filters.organization = organization;
	}

	public onOrganizationRemoved(): void {
		delete this.filters.organization;
	}

	public onAuthor(author: Author): void {
		this.filters.author = author;
	}

	public onAuthorRemoved(author: Author): void {
		delete this.filters.author;
	}

	public onRankChange(rank: number): void {
		if (rank) {
			this.filters.rank = rank;
		}
		else {
			delete this.filters.rank;
		}
	}

	public onAddTag(tag: Tag): void {
		this.filters.tags = TagUtility.addTag(tag, this.filters.tags);
	}

	public onRemoveTag(tag: Tag): void {
		this.filters.tags = TagUtility.removeTag(tag, this.filters.tags);
	}

	public onSelect(source: Source): void {
		const index = this.selected.findIndex(src => {
			if (src.id == source.id) {
				return true;
			}
		});

		if (index > -1) {
			this.selected.splice(index, 1);
		}
		else {
			this.selected.push(source);
		}
	}
	
	public isSelected(source: Source): boolean {
		const index = this.selected.findIndex(src => {
			if (src.id == source.id) {
				return true;
			}
		});
		
		return index > -1;
	}
	
	public isAllSelected(): boolean {
		if (this.selected.length == 0) {
			return false;
		}
		
		this.dataSource.data.forEach(source => {
			if (this.selected.find(src => src.id == source.id)) {
				return false;
			}
		});
		
		return true;
	}
	
	public onSelectedToggle(): void {
		//
		// if any are selected clear all, otherwise add current view
		if (this.selected.length > 0) {
			this.selected = [];
		}
		else {
			this.dataSource.data.forEach(source => {
				if (!this.selected.find(src => src.id == source.id)) {
					this.selected.push(source);
				}
			});
		}
	}

	protected getData(): void {
		this.isLoading = true;
		this.sourceService.getList(this.pageIndex + 1, this.pageSize, this.filters).subscribe({
			next: (response) => {
				this.loadData(response);
				this.filtersChange.emit(this.filters);
			},
			error: (error) => {
				console.error('Error:', error.message);
				this.snackBar.open(error.message, 'Dismiss', {
					duration: 2000
				});
			},
			complete: () => {
				this.isLoading = false;
			}
		});	
	}

    protected onPageChange(pageIndex: number): void {
		this.filters.pageIndex = pageIndex;
		this.storageService.setItem(this.filtersProperty, this.filters);
	}

}
