import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PaginatorComponent } from '../paging/paginator.component';

import { Info, InfoFilters } from 'src/app/model/info';
import { InfoserviceService } from 'src/app/service/infoservice.service';
import { StorageService, StorageProperty } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { SelectionList } from 'src/app/model/util/selection-list';
import { Tag, TagType, TagUtility } from 'src/app/model/tag';

@Component({
	selector: 'app-info-table',
	templateUrl: './info-table.component.html',
	styleUrls: ['./info-table.component.scss'],
	animations: [
		trigger('detailExpand', [
		state('collapsed', style({ height: '0px', minHeight: '0' })),
		state('expanded', style({ height: '*' })),
		transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class InfoTableComponent extends PaginatorComponent<InfoUI> implements OnInit {
	infoColumns: string[] = ['select', 'summary', 'tags'];

	selected: SelectionList<Info>;

	filters: InfoFilters = {};
	tagInfoType = TagType.Info;
	tagSourceType = TagType.Source;

	@Input()
	sourceTags: string[] = [];
	
	tags: string[] = [];
	
	tagsLabel: string = "Filter by source part tags... ";
	sourceTagsLabel: string = "Filter by source tags...";

	@Input()
	selectable: boolean;
	
	@Output()
	select: EventEmitter<Info> = new EventEmitter<Info>();

	constructor(private infoService: InfoserviceService,
				private storageService: StorageService,
				private snackBar: MatSnackBar,
				private navigationService: NavigationService) {
		super();
	}
	
	ngOnInit(): void {
		if (this.selectable) {
			this.selected = new SelectionList();
		}
		else {
			this.infoColumns.shift();
		}

		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			//
			// filters
			this.filters = this.storageService.getItem<InfoFilters>(StorageProperty.INFO_FILTERS);
			if (!this.filters) {
				this.filters = { tags: [] }
			}
			else {
				//
				// page
				this.pageIndex = this.filters.pageIndex;
			}
		}
		else {
			this.storageService.clearItem(StorageProperty.INFO_FILTERS);
		}
			
		super.ngOnInit();
	}

	public fetch(): void {
		this.storageService.setItem(StorageProperty.INFO_FILTERS, this.filters);

		//
		// reset list
		this.reset();
	}

	public onAddTag(tag: Tag): void {
		this.filters.tags = TagUtility.addTag(tag, this.filters.tags);
	}

	public onRemoveTag(tag: Tag): void {
		this.filters.tags = TagUtility.removeTag(tag, this.filters.tags);
	}

	public onAddSourceTag(tag: Tag): void {
		this.filters.sourceTags = TagUtility.addTag(tag, this.filters.sourceTags);
	}

	public onRemoveSourceTag(tag: Tag): void {
		this.filters.sourceTags = TagUtility.removeTag(tag, this.filters.sourceTags);
	}

	public onSelect(info: Info): void {
		this.selected.toggle(info);
	}
	
	public isSelected(info: Info): boolean {
		return this.selected.isSelected(info);
	}
	
	public isAllSelected(): boolean {
		return this.selected.allSelected(this.dataSource.data);
	}
	
	public onSelectedToggle(): void {
		//
		// if all items of current page are selected clear, otherwise add
		if (this.selected.allSelected(this.dataSource.data)) {
			this.selected.clear();
		}
		else {
			this.selected.addList(this.dataSource.data);
		}
	}

	protected getData(): void {
		this.isLoading = true;
		this.infoService.getList(this.pageIndex + 1, this.pageSize, this.filters).subscribe({
			next: (response) => {
				this.loadData(response);			
			},
			error: (error) => {
				console.error('Error:', error.message);
				this.snackBar.open(error.message, 'Dismiss', {
					duration: 2000
				});
			},
			complete: () => {
				this.isLoading = false;
			}
		});	
	}

    protected onPageChange(pageIndex: number): void {
		this.filters.pageIndex = pageIndex;
		this.storageService.setItem(StorageProperty.INFO_FILTERS, this.filters);
	}
}

export class InfoUI extends Info {
	selected?: boolean = true;
}
