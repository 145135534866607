<div fxLayout="column" fxLayoutAlign="center center">
	<div class="slides-container">
		<div class="slide-header" fxLayout="row" fxLayoutAlign="space-between stretch" fxFill>
			<button mat-icon-button (click)="prevSlide()" [@fadeAnimation]="getButtonAnimationState()">
				<mat-icon>arrow_back</mat-icon>
			</button>
			<div class="slider-header-placeholder"></div>
			<span [@fadeAnimation]="getHeaderAnimationState()">{{ getSlideTitle() }}</span>
		</div>
		<div class="slide-wrapper" [style.transform]="getTransform()">
			<div class="slide" *ngFor="let slide of slides; let i = index">
				<div *ngIf="currentSlideIndex === i">
					<!-- Categories -->
					<mat-grid-list *ngIf="currentSlideIndex === 0" [cols]="gridColumns" rowHeight="152px"
						gutterSize="10px">
						<mat-grid-tile *ngFor="let category of categories" class="grid-category">
							<mat-grid-tile-header>{{ getRootCategory(category.path) }}</mat-grid-tile-header>
							<div class="grid-tile-wrapper">
								<img src="{{ category.image }}" (click)="onCategoryClick(category)">
								<span>{{ category.name }}</span>
							</div>
						</mat-grid-tile>
					</mat-grid-list>

					<!-- Stories -->
					<mat-grid-list *ngIf="currentSlideIndex === 1" [cols]="gridColumns - 1" rowHeight="225px" gutterSize="10px">
						<mat-grid-tile *ngFor="let story of stories" class="grid-story" (click)="onStoryClick(story)">
							<div class="grid-story-container">
								<img [src]="story.image" alt="{{ story.title }}" class="grid-story-image">
								<span class="grid-story-title">{{ story.title }}</span>
							</div>
						</mat-grid-tile>
					</mat-grid-list>
					
					<!-- Story -->
					<div *ngIf="currentSlideIndex === 2">
						<app-story [id]="story.id"></app-story>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>