import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatSnackBar } from '@angular/material/snack-bar';

import { CategoryService } from 'src/app/service/category.service';
import { Category, CategoryFilters } from 'src/app/model/category';


import { ErrorHelper } from 'src/app/helpers/error-helper';
import { Story, StoryFilters } from 'src/app/model/story';
import { StoryService } from 'src/app/service/story.service';

@Component({
	selector: 'app-category-story',
	templateUrl: './category-story.component.html',
	styleUrls: ['./category-story.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1, width: '*', height: '*' })),
      state('out', style({ opacity: 0, width: '0px', height: '0px' })),
      transition('in => out', animate('200ms ease-out')),
      transition('out => in', animate('200ms ease-in'))
    ])
  ]
})
export class CategoryStoryComponent implements OnInit {

	categories: Category[] = [];
	stories: Story[] = [];

	category: Category;
	story: Story;

	catgroyFilters: CategoryFilters = { story: true};
	storyFilters: StoryFilters = {};
	
	pageIndex: number = 1;
	pageSize: number = 10;
	
	@Input()
	title: string;
	slides = [1, 2, 3];

	gridColumns: number = 3;
	currentSlideIndex = 0;
	animationState = 'in';

	constructor(protected categoryService: CategoryService,
		private storyService: StoryService,
		private snackBar: MatSnackBar,
		private breakpointObserver: BreakpointObserver) {
		this.breakpointObserver.observe([
			Breakpoints.XSmall,
			Breakpoints.Small,
			Breakpoints.Medium,
			Breakpoints.Large,
			Breakpoints.XLarge
		]).subscribe(result => {
			if (result.breakpoints) {
				if (result.breakpoints[Breakpoints.XSmall]) {
					this.gridColumns = 3;
				} 
				else if (result.breakpoints[Breakpoints.Small]) {
					this.gridColumns = 4;
				}
				else if (result.breakpoints[Breakpoints.Medium]) {
					this.gridColumns = 5;
				}
				else if (result.breakpoints[Breakpoints.Large]) {
					this.gridColumns = 6;
				}
				else {
					this.gridColumns = 7; // Adjust as needed
				}
			}
		});			
	}

	public ngOnInit(): void {
		this.categoryService.getCateogryList(this.pageIndex, this.pageSize, this.catgroyFilters).subscribe({
			next: (response) => {
				this.categories = response.data;
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public getRootCategory(path: string): string {
		const tokens = path.split(":");
		
		return tokens[tokens.length - 1];
	}

	public getSlideTitle(): string {
		if (this.currentSlideIndex == 0) {
			return this.title;
		}
		else if (this.currentSlideIndex == 1 && this.category != null) {
			return this.category.name;
		}
		else if (this.currentSlideIndex == 2 && this.story != null) {
			return ''; // this.story.title;
		}
		else {
			return "N/A";
		}
	}

	public getTransform(): string {
		return `translateX(-${this.currentSlideIndex * 100}%)`;
	}

	public getButtonAnimationState(): string {
		return this.currentSlideIndex > 0 ? 'in' : 'out';
	}

	public getHeaderAnimationState(): string {
		return this.animationState;
	}

	public onCategoryClick(category: Category): void {
		this.category = category;
		this.storyFilters.categoryId = category.id;

		this.storyService.getList(1, 10, this.storyFilters).subscribe({
			next: (response) => {
				this.stories = response.data;
				this.nextSlide();
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onStoryClick(story: Story): void {
		this.storyService.getById(story.id).subscribe({
			next: (response) => {
				this.story = response;
				this.nextSlide();
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	private nextSlide(): void {
		console.log(`nextSlide: ${this.currentSlideIndex}`);
		this.animationState = 'out';
		setTimeout(() => {
			this.currentSlideIndex = this.currentSlideIndex + 1;
			this.animationState = 'in';
		}, 200); // Delay should match your animation duration
	}

	public prevSlide(): void {
		console.log(`prevSlide: ${this.currentSlideIndex}`);
		this.animationState = 'out';
		setTimeout(() => {
			this.currentSlideIndex = this.currentSlideIndex - 1;
			if (this.currentSlideIndex === 0) {
				this.category = null;
			}
			this.animationState = 'in';
		}, 200); // Delay should match your animation duration
	}

}
