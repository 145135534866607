import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BookmarksComponent } from './bookmarks.component';
import { BookmarksListComponent } from './bookmarks-list.component';

const routes: Routes = [
	{ path: 'bookmarks/list', component: BookmarksListComponent },
	{ path: 'bookmarks/add', component: BookmarksComponent },
	{ path: 'bookmarks/:id', component: BookmarksComponent }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})
export class BookmarksRoutingModule { }