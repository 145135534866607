import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, switchMap, tap, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { Story } from 'src/app/model/story';
import { StoryService } from 'src/app/service/story.service';

@Component({
	selector: 'app-story-search',
	templateUrl: './story-search.component.html',
	styleUrls: ['./story-search.component.scss']
})
export class StorySearchComponent implements OnInit {
	@Input()
	label: string = 'Search stories...';

	@Input()
	story: Story;

	stories: Observable<Story[]>;

	searchField: FormControl = new FormControl();
	
	isInputVisible: boolean = false;

	@Output()
	storySelected: EventEmitter<Story> = new EventEmitter<Story>();

	@Output()
	storyName: EventEmitter<string> = new EventEmitter<string>();

	constructor(private storyService: StoryService) { }

	ngOnInit() {
		this.stories = this.searchField.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(name => name.length > 0),
			tap(name => this.storyName.emit(name)),
			switchMap(name => this.storyService.searchByTitle(name))
		);
		this.searchField.setValue(this.story);
	}

	public displayFn(story: Story): string {
		return story ? story.title : '';
	}

	public onSelect(story: Story): void {
		this.storySelected.emit(story);
	}

	public toggleInputVisibility() {
		this.isInputVisible = !this.isInputVisible;
	}
}
