import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OverlayModule } from "@angular/cdk/overlay";

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion'; 
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MarkdownModule } from 'ngx-markdown';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFaceDizzy, faFaceFrown, faFaceMehBlank, faFaceSmile, faFaceGrinStars, faSearch, faCopy, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InfoserviceService } from './service/infoservice.service';
import { SourceService } from './service/source.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InfoDialogComponent } from './component/info-dialog/info-dialog.component';
import { LoginComponent } from './component/login/login.component';
import { LogoutComponent } from './component/login/logout.component';

import { PaginatorLabels } from './component/paging/paginator';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { RegisterComponent } from './component/register/register.component';
import { InfoListComponent } from './component/info/info-list.component';
import { InfoAccordianComponent } from './component/info/info-accordian.component';
import { HomeComponent } from './component/home/home.component';
import { ToolbarComponent } from './component/toolbar/toolbar.component';
import { CategoryStoryComponent } from './component/category-story/category-story.component';

import { AuthorRoutingModule } from './component/author/author-routing.module';
import { BookmarksRoutingModule } from './component/bookmarks/bookmarks-routing.module';
import { CategoryRoutingModule } from './component/category/category-routing.module';
import { OrganizationRoutingModule } from './component/organization/organization-routing.module';
import { ReferenceRoutingModule } from './component/reference/reference-routing.module';
import { SharedModule } from './component/shared/shared.module';
import { SourceRoutingModule } from './component/source/source-routing.module';
import { StoryModule } from './component/story/story.module';
import { StoryRoutingModule } from './component/story/story-routing.module';
import { AboutComponent } from './component/about/about.component';
import { SurveyComponent } from './component/survey/survey.component';


@NgModule({
    declarations: [
        AppComponent,
        InfoDialogComponent,
        LoginComponent,
        LogoutComponent,
        RegisterComponent,
        InfoListComponent,
        InfoAccordianComponent,
        HomeComponent,
        ToolbarComponent,
        CategoryStoryComponent,
        AboutComponent,
        SurveyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        DragDropModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        OverlayModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatChipsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatAutocompleteModule,
        MatTableModule,
        MatSelectModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
		MatTabsModule,
		MatTooltipModule,
		MatCheckboxModule,
		MatToolbarModule,
		MatMenuModule,
		MatDividerModule,
		MatRadioModule,
        FlexLayoutModule,
        MarkdownModule.forRoot(),
        QuillModule.forRoot(),
		AuthorRoutingModule,
		CategoryRoutingModule,
		OrganizationRoutingModule,
		BookmarksRoutingModule,
		ReferenceRoutingModule,
		SourceRoutingModule,
		StoryRoutingModule,
		StoryModule,
		RouterModule,
		SharedModule
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: PaginatorLabels
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}
		},
        InfoserviceService,
        SourceService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) { 
		library.addIcons(faFaceDizzy, faFaceFrown, faFaceMehBlank, faFaceSmile, faFaceGrinStars, faSearch, faCopy, faArrowUpRightFromSquare);
	}
}
