import { BehaviorSubject, Observable } from "rxjs";

export abstract class LoadingComponent {
	protected loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public isLoading: Observable<boolean> = this.loading.asObservable();
	
	protected start(): void {
		this.loading.next(true);
	}
	
	protected done(): void {
		this.loading.next(false);
	}
}