<mat-form-field class="source-field">
	<mat-label>{{label}}</mat-label>
	<mat-chip-grid #categoryList>
		<mat-chip-row *ngIf="category != null"
			[selectable]="!readOnly"
			[removable]="!readOnly"
			(removed)="remove(category)"
			(click)="onClick(category)">
			{{category?.name}}
			<mat-icon *ngIf="!readOnly" matChipRemove>cancel</mat-icon>
		</mat-chip-row>
		<input matInput #categoryInput placeholder="Search categories..."
			[formControl]="categoryControl"
			[matAutocomplete]="categoryAutocomplete"
			[matChipInputFor]="categoryList"
			[matChipInputSeparatorKeyCodes]="separatorKeyCodes"
			[matChipInputAddOnBlur]="true"
			(matChipInputTokenEnd)="add($event)" />
	</mat-chip-grid>

	<mat-autocomplete autoActiveFirstOption 
		#categoryAutocomplete="matAutocomplete" 
		[displayWith]="displayFn"
		(optionSelected)="onCategory($event)">
		<mat-option *ngFor="let category of categories | async" [value]="category">
			{{category.name}}
		</mat-option>
       </mat-autocomplete>
</mat-form-field>
