import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from './confirm-dialog.component';

@Directive({
	selector: '[confirm-dialog]'
})
export class ConfirmDialogDirective {
	@Input() message: string;

	@Output() confirmed = new EventEmitter<boolean>();

	constructor(private dialog: MatDialog) { }

	@HostListener('click', ['$event']) onClick(event) {
		this.dialog.open<ConfirmDialogComponent, any, boolean>(ConfirmDialogComponent,
			{ 
				data: {message: this.message }
			})
			.afterClosed().subscribe(result => {
				console.error("ON CLOSE", result)
				if (result) {
					this.confirmed.emit(true);
				}
			});
	}
}
