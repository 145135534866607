import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Story, StoryBit, StoryFilters } from '../model/story';
import { PagingResponse } from 'src/app/model/paging-response';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class StoryService {
	private baseUrl: string = environment.serverUrl + 'stories';
	
	constructor(protected http: HttpClient) {}

	public getById(id: number): Observable<Story> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.get<Story>(url);
	}

	public getList(
		page: number = 1,
		limit: number = 10,
		filters?: StoryFilters
	): Observable<PagingResponse<Story>> {
		let params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		if (filters) {
			if (filters.categoryId) {
				params = params.set('categoryId', filters.categoryId);
			}
		}

		return this.http.get<PagingResponse<Story>>(`${this.baseUrl}`, { params });
	}

	public searchByTitle(title: string): Observable<Story[]> {
		const url = `${this.baseUrl}/search`;
		const params = new HttpParams()
			.set('query', title.toUpperCase());
	
		return this.http.get<Story[]>(url, { params });		
	}
	
	public save(story: Story): Observable<Story> {
		return this.http.post<Story>(`${this.baseUrl}`, story);
	}
	
	public update(story: Story): Observable<Story> {
		const url = `${this.baseUrl}/${story.id}`;

		return this.http.put<Story>(url, story);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.delete(url);
	}

	public addBit(id: number, bitId: number): Observable<void> {
		const url = `${this.baseUrl}/${id}/bits/${bitId}`;
		
		return this.http.put<void>(url, '');
	}	

}
