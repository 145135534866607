<h1 mat-dialog-title>Info</h1>
<div mat-dialog-content fxLayout="column" fxLayoutAlign="space-evenly stretch">
	<form>
		<mat-form-field class="source-field"> 
			<input matInput placeholder="Summary" [(ngModel)]="info.summary"
				[ngModelOptions]="{standalone: true}" />
		</mat-form-field>

		<quill-editor [modules]="editorOptions" [(ngModel)]="detail" [ngModelOptions]="{standalone: true}" style="width: 100%; height: 200px;"></quill-editor>

		<!-- tags -->
		<app-taglookup
			matInput
			[tags]="info.tags"
			[tagType]="tagInfoType"
			[readOnly]="false"
			(add)="onAddTag($event)"
			(remove)="onRemoveTag($event)">
		</app-taglookup>

	</form>
</div>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button *ngIf="info.id == null" mat-button (click)="onSave()" cdkFocusInitial>Save</button>
    <button *ngIf="info.id != null" mat-button (click)="onUpdate()" cdkFocusInitial>Update</button>
</mat-dialog-actions>