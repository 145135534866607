import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { SurveyService } from 'src/app/service/survey.service';
import { Survey, SurveySubmission, SurveyResponse, QuestionType } from 'src/app/model/survey';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-feedback-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
	survey: Survey | undefined;
	surveySubmission: SurveySubmission | undefined;

	surveyForm: FormGroup;
	isSubmitting: boolean = false;

	ratingOptions = [1, 2, 3, 4, 5];

	constructor(private service: SurveyService,
		private snackBar: MatSnackBar,
		private fb: FormBuilder) {
		this.surveyForm = this.fb.group({});
	}

	ngOnInit() {
		this.service.getByName('Feedback').subscribe({
			next: (response) => {
				this.survey = response;
				this.createForm();
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	private createForm(): void {
		if (this.survey) {
			const group: any = {};
			this.survey.questions.forEach(question => {
				group[question.id.toString()] = [''];
			});
			this.surveyForm = this.fb.group(group, { validators: this.onlyOneFieldFilledValidator() });
		}
	}

	private onlyOneFieldFilledValidator(): ValidatorFn {
		return (formGroup: AbstractControl): { [key: string]: any } | null => {
			const controls = formGroup.value;
			const filledFields = Object.keys(controls).filter(key => controls[key] !== '');

			if (filledFields.length === 0) {
				return { onlyOneFieldFilled: true };
			}

			return null;
		};
	}

	public onSubmit(): void {
		if (this.surveyForm.valid && this.survey) {
			const formValue = this.surveyForm.value;

			const responses = this.survey.questions
				.map(question => {
					const value = formValue[question.id!.toString()];
					if (value) {
						return {
							question: { id: question.id! },
							value: this.formatResponseValue(value, question.type)
						} as SurveyResponse;
					}
					else {
						return null;
					}
				})
				.filter(response => response !== null) as SurveyResponse[];

			if (responses.length > 0) {
				const submission: SurveySubmission = {
					survey: { id: this.survey.id! },
					responses: responses
				};

				this.isSubmitting = true;
				this.service.submitSurvey(this.survey, submission).subscribe({
					next: (response) => {
						this.surveySubmission = response;
						this.snackBar.open('Thank you for your feedback', 'Dismiss', {
							duration: 3000
						});
					},
					error: (error) => {
						ErrorHelper.handleError(error, this.snackBar);
					},
					complete: () => {
						this.isSubmitting = false;
					}
				});
			}
			else {
				this.snackBar.open('Please provide at least one response.', 'Dismiss', {
					duration: 3000
				});
			}
		}
		else {
			this.snackBar.open('Form is invalid. Please check your input.', 'Dismiss', {
				duration: 3000
			});
		}
	}

	private formatResponseValue(value: any, questionType: QuestionType): string {
		switch (questionType) {
			case QuestionType.RATING:
				return value.toString();
			case QuestionType.MULTIPLE_CHOICE:
			case QuestionType.OPEN:
				return value;
			default:
				throw new Error(`Unknown question type: ${questionType}`);
		}
	}
}