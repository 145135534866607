<div class="page-header">
	<h3>Feedback</h3>
</div>
<form [formGroup]="surveyForm" (ngSubmit)="onSubmit()">
	<div class="survey-container">
		<div class="survey-content" *ngIf="survey?.questions?.length > 0">
			<ng-container *ngFor="let question of survey.questions">
				<div [ngSwitch]="question.type" class="question-container">
					<div *ngSwitchCase="'rating'" class="rating-question">
						<label [for]="'question_' + question.id">{{ question.text }}</label>
						<mat-radio-group [id]="'question_' + question.id" [formControlName]="question.id.toString()">
							<mat-radio-button color="primary" *ngFor="let rating of ratingOptions" [value]="rating">
								{{ rating }}
							</mat-radio-button>
						</mat-radio-group>
					</div>

					<div *ngSwitchCase="'open'" class="open-question">
						<label [for]="'question_' + question.id">{{ question.text }}</label>
						<mat-form-field appearance="outline">
							<textarea matInput [id]="'question_' + question.id"
								[formControlName]="question.id.toString()" rows="4"></textarea>
						</mat-form-field>
					</div>

					<div *ngSwitchCase="'multiple_choice'" class="multiple-choice-question">
						<label [for]="'question_' + question.id">{{ question.text }}</label>
						<mat-radio-group [id]="'question_' + question.id" [formControlName]="question.id.toString()">
							<mat-radio-button color="primary" *ngFor="let option of question.options" [value]="option">
								{{ option }}
							</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</ng-container>
		</div>
		<div *ngIf="!surveySubmission" class="survey-actions">
			<button mat-raised-button color="primary" type="submit" [disabled]="surveyForm.invalid || isSubmitting">Submit</button>
		</div>
	</div>
</form>