import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Reference, ReferenceFilters } from '../model/reference';
import { PagingResponse } from 'src/app/model/paging-response';

import { environment } from 'src/environments/environment';
import { TagUtility } from '../model/tag';

@Injectable({
	providedIn: 'root'
})
export class ReferenceService {
	private baseUrl: string = environment.serverUrl + 'references';

	constructor(protected http: HttpClient) {}

	public getById(id: number): Observable<Reference> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.get<Reference>(url);
	}

	public getByReference(reference: string): Observable<Reference> {
		const url = `${this.baseUrl}/reference`;
		const params = new HttpParams()
			.set('url', reference);

		return this.http.get<Reference>(url, { params });
	}

	public getList(
		page: number = 1,
		limit: number = 10,
		filters?: ReferenceFilters
	): Observable<PagingResponse<Reference>> {
		let params = new HttpParams()
			.set('page', page.toString())
			.set('limit', limit.toString());

		if (filters) {
			if (filters.status) {
				params = params.set('status', filters.status);
			}

			if (filters.rank !== undefined) {
				params = params.set('rank', filters.rank.toString());
			}

			if (filters.tags) {
				filters.tags.forEach(tag => {
					params = params.append('tags', tag.name);
				});
			}
		}

		return this.http.get<PagingResponse<Reference>>(`${this.baseUrl}`, { params });
	}

	public save(reference: Reference): Observable<Reference> {
		//
		// sort and uppercase all tags
		reference.tags = TagUtility.sortAndUpperCase(reference.tags);

		return this.http.post<Reference>(`${this.baseUrl}`, reference);
	}

	public update(reference: Reference): Observable<Reference> {
		const url = `${this.baseUrl}/${reference.id}`;

		//
		// sort and uppercase all tags
		reference.tags = TagUtility.sortAndUpperCase(reference.tags);
		console.log('updat:' + JSON.stringify(reference.tags));

		return this.http.put<Reference>(url, reference);
	}

	public delete(id: number): Observable<any> {
		const url = `${this.baseUrl}/${id}`;

		return this.http.delete(url);
	}

}