 <h3>Signup</h3>
 <div fxLayout="column" fxLayoutAlign="space-evenly start">
 		<form class="form-group" [formGroup]="form" (ngSubmit)="onSubmit()">
			<!-- login -->
			<mat-form-field class="source-field">
				<input matInput placeholder="Login" formControlName="login" class="form-control" [ngClass]="{ 'is-invalid': submitted && fields.login.errors }" />
                <mat-error *ngIf="checkFieldError('login', 'required')">Login is required</mat-error>
                <mat-error *ngIf="checkFieldError('login', 'exists')">Login exists</mat-error>
			</mat-form-field>

			<!-- password -->
			<mat-form-field class="source-field">
				<input matInput placeholder="Password" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && fields.password.errors }" />
				<mat-error *ngIf="checkFieldError('password', 'required')">Password is required</mat-error>
				<mat-error *ngIf="checkFieldError('password', 'minlength')">Password must be at least 8 characters</mat-error>
			</mat-form-field>

			<!-- name -->
			<mat-form-field class="source-field">
				<input matInput placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && fields.name.errors }" />
			</mat-form-field>
			
			<!-- email -->
			<mat-form-field class="source-field">
				<input matInput placeholder="Email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && fields.email.errors }" />
				<mat-error *ngIf="checkFieldError('email', 'email')">Invalid email</mat-error>
				<mat-error *ngIf="checkFieldError('email', 'required')">Email is required</mat-error>
			</mat-form-field>

			<!-- registration token -->
			<mat-form-field class="source-field">
				<input matInput placeholder="Token" formControlName="token" [ngClass]="{ 'is-invalid': submitted && fields.token.errors }" />
				<mat-error *ngIf="checkFieldError('token', 'invalid')">Token is not valid</mat-error>
				<mat-error *ngIf="checkFieldError('token', 'expired')">Token is expired</mat-error>
				<mat-error *ngIf="checkFieldError('token', 'required')">Token is required</mat-error>
			</mat-form-field>
			
			<!-- buttons -->
			<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" fxFill>
				<button mat-stroked-button color="primary">Register</button>
				<button mat-stroked-button color="accent" [routerLink]="['/login']">Cancel</button>
			</div>
 		</form>
 </div>
