import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Info } from 'src/app/model/info';
import { BookmarksService } from 'src/app/service/bookmarks.service';
import { LoginService } from 'src/app/service/login.service';
import { StoryService } from 'src/app/service/story.service';
import { BookmarksButtonEvent } from '../bookmarks/bookmarks-button.component';
import { StoryButtonEvent } from '../story/story-add-button.component';
import { FixedExpandableTableComponent } from './fixed-expandable-table.component';
import { ColumnType } from './fixed-table.component';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-fixed-info-table',
	templateUrl: './fixed-expandable-table.component.html',
	styleUrls: ['./fixed-expandable-table.component.scss']
})
export class FixedInfoTableComponent extends FixedExpandableTableComponent<Info> implements OnInit {

	constructor(private bookmarksService: BookmarksService,
				private storyService: StoryService,
				private loginService: LoginService,
				private snackBar: MatSnackBar) {
		super();

		this.columns = [
			{
				columnDef: 'summary',
				header: 'Summary',
				type: ColumnType.STRING,
				cell: (info: Info) => `${info.summary}`
			},
			{
				columnDef: 'tags',
				header: 'Tags',
				type: ColumnType.TAGS,
				cell: (info: Info) => info.tags
			}
		];
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	public onBookmark(event: BookmarksButtonEvent): void {
		this.bookmarksService.updateSourceBits(event.bookmark.id, [event.document.id]).subscribe(
			response => { 
				this.snackBar.open('Bookmarks updated', 'Dismiss', {
					duration: 1000
				});
			},
			error => {
				console.error(error);
				this.snackBar.open('Error updating', 'Dismiss', {
					duration: 1000
				});
			}			
		);
	}

	public onStory(event: StoryButtonEvent): void {
		this.storyService.addBit(event.story.id, event.info.id).subscribe({
			next: () =>  { 
				this.snackBar.open('Story updated', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}			
		});
	}
}
