import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Meta, Title } from '@angular/platform-browser';

import { setMetaTags } from 'src/app/component/shared/meta-tags.util';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';

import { SourceService } from '../../service/source.service';
import { BookmarksService } from 'src/app/service/bookmarks.service';
import { LoginService } from 'src/app/service/login.service';

import { Source } from '../../model/source';
import { BookmarksButtonEvent } from 'src/app/component/bookmarks/bookmarks-button.component';
import { Reputation } from 'src/app/model/reputation';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-source',
	templateUrl: './source.component.html',
	styleUrls: ['./source.component.scss']
})
export class SourceComponent implements OnInit {
	url: string;

	sourceId: string;
	source: Source;

	getIcon = Reputation.getIcon;

	private subscriptions: Subscription[] = [];

	showUrl: boolean = false;
	
	constructor(private sourceService: SourceService,
		private bookmarksService: BookmarksService,
		private loginService: LoginService,
		private clipboard: Clipboard,
		private snackBar: MatSnackBar,
		private meta: Meta,
		private title: Title,
		private activatedRoute: ActivatedRoute) {
		
		//
		// check for passed in ref
		this.source = new Source();
		this.source.authors = [];			
	}

	ngOnInit(): void {
		const paramsSubscription = this.activatedRoute.params
			.pipe(
				switchMap(params => {
					const refId = params['id'];
					return refId ? this.sourceService.getById(refId) : of(null);
				})
			)
			.subscribe({
				next: (response) => {
					this.source = response ? plainToClass(Source, response) : new Source();
					setMetaTags(this.title, response.title, this.meta, response.description, response.image);
				},
				error: (error) => {
					ErrorHelper.handleError(error, this.snackBar);
				}
			});

		this.subscriptions.push(paramsSubscription);
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	public copyUrl(): void {
		this.clipboard.copy(this.source.reference);
	}

	public onBookmark(event: BookmarksButtonEvent): void {
		this.bookmarksService.updateSources(event.bookmark.id, [this.source.id]).subscribe({
			next: () => { 
				this.snackBar.open('Bookmarks updated', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}			
		});
	}
}
