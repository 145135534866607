import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';

import { finalize } from 'rxjs';
import TurndownService from 'turndown';
import { marked } from 'marked';

import { Info } from 'src/app/model/info';
import { Tag, TagType, TagUtility } from 'src/app/model/tag';

import { InfoserviceService } from '../../service/infoservice.service';
import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-info-dialog',
	templateUrl: './info-dialog.component.html',
	styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
	info: Info;
	tagInfoType = TagType.All;
	
	detail: string;

	editorOptions = {
		toolbar: [
			['bold', 'italic'],
			['link']
		]
	}

	constructor(public dialogRef: MatDialogRef<InfoDialogComponent>,
		private service: InfoserviceService,
		private snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) public data: any) {

	}

	ngOnInit() {
		if (this.data.info == null) {
			this.info = new Info();
		}
		else {
			this.info = this.data.info;
			this.detail = marked(this.info.detail);	
		}
	}

	public onAddTag(tag: Tag): void {
		this.info.tags = TagUtility.addTag(tag, this.info.tags);
	}

	public onRemoveTag(tag: Tag): void {
		this.info.tags = TagUtility.removeTag(tag, this.info.tags);
	}

	onSave(): void {
		const turndownService = new TurndownService();
		this.info.detail = turndownService.turndown(this.detail);

		this.service.save(this.data.sourceId, this.info).subscribe({
			next: (response) => {
				this.info = response;
				this.snackBar.open('Info saved', 'Dismiss', { duration: 1000 });
				this.dialogRef.close(this.info);
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
				this.dialogRef.close(null);
			}
		});
	}

	onUpdate(): void {
		console.log('onUpdate____________');
		console.log(JSON.stringify(this.info))
		const turndownService = new TurndownService();
		this.info.detail = turndownService.turndown(this.detail);

		this.service.update(this.info).subscribe({
			next: (response) => {
				this.snackBar.open('Info saved', 'Dismiss', {
					duration: 1000
				});
				;
				this.dialogRef.close(response);
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
				this.dialogRef.close(null);
			}
		});
	}
}
