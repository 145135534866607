import { Component, OnInit, ViewChild } from '@angular/core';

import { InfoTableComponent } from './info-table.component';

@Component({
	selector: 'app-info-table-dialog',
	templateUrl: './info-table-dialog.component.html',
	styleUrls: ['./info-table-dialog.component.scss']
})
export class InfoTableDialogComponent implements OnInit {

	@ViewChild('infoTable')
	infoTable: InfoTableComponent;
	
	constructor() { }

	ngOnInit(): void {
	}

}
