import { Author } from './author';
import { Info } from './info';
import { Organization } from './organization';
import { User } from './user';
import { Tag, Taggable } from './tag';

export class Source extends Taggable {
	id: number;
	reference: string;
	altReference: string;
	title: string;
	description: string;
	date: Date;
	image: string;
	rank: number;
	
	authors: Author[] = [];
	organization: Organization;
	infoList: Info[];
	
	createdBy: User;
	createdDate: Date;

	get author(): string {
		if (this.authors != null) {
			return this.authors[0].lastName + ',' + this.authors[0].firstName;
		}
		else {
			return '';
		}
	}
}

export interface SourceFilters {
	author?: Author;
	organization?: Organization;
	tags?: Tag[];
	rank?: number;
	pageIndex?: number;
}

export class SourceDetails {
	title: string;
	description: string;
	image: string;
	date: Date;
	organization: Organization;
	authors: string[];

	entities: { count: number; label: string; existing: boolean }[];
	keywords: { count: number; keyword: string; existing: boolean }[];
}

export enum SourceStatus {
	OPEN = 'Open',
	IN_PROGRESS = 'In Progress',
	REVIEW = 'Review',
	DONE = 'Done'
}
