import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from "@angular/cdk/overlay";
import { RouterModule } from '@angular/router';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MarkdownModule } from 'ngx-markdown';

import { AuthorSearchComponent } from '../author/author-search.component';
import { AuthorLookupComponent } from '../author/author-lookup.component';
import { BookmarksButtonComponent } from '../bookmarks/bookmarks-button.component';
import { CategorySearchComponent } from '../category/category-search.component';
import { CategoryLookupComponent } from '../category/category-lookup.component';
import { FixedTableComponent } from '../table/fixed-table.component';
import { FixedExpandableTableComponent } from '../table/fixed-expandable-table.component';
import { FixedInfoTableComponent } from '../table/fixed-info-table.component';
import { FixedSourceTableComponent } from '../table/fixed-source-table.component';
import { InfoTableComponent } from '../info/info-table.component';
import { InfoTableDialogComponent } from '../info/info-table-dialog.component';
import { OrganizationLookupComponent } from '../organization/organization-lookup.component';
import { OrganizationSearchComponent } from '../organization/organization-search.component';
import { ReportCardComponent } from '../report-card/report-card.component';
import { SourceTableComponent } from '../source/source-table.component';
import { SourceTableDialogComponent } from '../source/source-table-dialog.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { StoryAddButtonComponent } from '../story/story-add-button.component';
import { StoryTableComponent } from '../story/story-table.component';
import { StoryTableDialogComponent } from '../story/story-table-dialog.component';
import { TaglookupComponent } from '../taglookup/taglookup.component';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogDirective } from '../confirm-dialog/confirm-dialog.directive';
import { ConfirmNavigateDirective } from 'src/app/directives/confirm-navigate.directive';

import { CategoryPathPipe } from 'src/app/pipes/category-path.pipe';
import { HostnamePipe } from 'src/app/pipes/hostname.pipe';
import { AuthorPipe } from 'src/app/pipes/author.pipe';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DragDropModule,
		OverlayModule,
		ReactiveFormsModule,
		RouterModule,
		FlexLayoutModule,
		FontAwesomeModule,
		MarkdownModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatIconModule,
		MatInputModule,
		MatPaginatorModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatTableModule
	],
	declarations: [
		AuthorSearchComponent,
		AuthorLookupComponent,
		BookmarksButtonComponent,
		AuthorPipe,
		CategoryPathPipe,
		HostnamePipe,
		CategorySearchComponent,
		CategoryLookupComponent,
		ConfirmDialogComponent,
		ConfirmDialogDirective,
		ConfirmNavigateDirective,
		FixedTableComponent,
		FixedExpandableTableComponent,
		FixedSourceTableComponent,
		FixedInfoTableComponent,
		InfoTableComponent,
		InfoTableDialogComponent,
		OrganizationLookupComponent,
		OrganizationSearchComponent,
		ReportCardComponent,
		SourceTableComponent,
		SourceTableDialogComponent,
		SpinnerComponent,
		StoryAddButtonComponent,
		StoryTableComponent,
		StoryTableDialogComponent,
		TaglookupComponent
	],
	exports: [
		AuthorSearchComponent,
		AuthorLookupComponent,
		BookmarksButtonComponent,
		AuthorPipe,
		CategoryPathPipe,
		HostnamePipe,
		CategorySearchComponent,
		CategoryLookupComponent,
		ConfirmDialogComponent,
		ConfirmDialogDirective,
		ConfirmNavigateDirective,
		FixedTableComponent,
		FixedExpandableTableComponent,
		FixedSourceTableComponent,
		FixedInfoTableComponent,
		InfoTableComponent,
		InfoTableDialogComponent,
		OrganizationLookupComponent,
		OrganizationSearchComponent,
		ReportCardComponent,
		SourceTableComponent,
		SpinnerComponent,
		StoryAddButtonComponent,
		StoryTableComponent,
		StoryTableDialogComponent,
		TaglookupComponent
	]
})
export class SharedModule { }