import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { Subscription, of, switchMap } from 'rxjs';
import { plainToClass } from 'class-transformer';

import { Reference, ReferenceStatus } from 'src/app/model/reference';
import { ReferenceService } from 'src/app/service/reference.service';
import { SourceService } from 'src/app/service/source.service';
import { LoginService } from 'src/app/service/login.service';
import { Errors } from 'src/app/model/errors';

import { Tag, TagType } from 'src/app/model/tag';

@Component({
	selector: 'app-reference',
	templateUrl: './reference.component.html',
	styleUrls: ['./reference.component.scss'],
	providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class ReferenceComponent implements OnInit, OnDestroy {
	reference: Reference
	private subscriptions: Subscription[] = [];

	ranks: number[] = [1, 2, 3, 4, 5];
	statusEnum = ReferenceStatus;
	status = [];
	tagType: TagType = TagType.Reference;

	inProgress: boolean = false;

	constructor(private referenceService: ReferenceService,
				private sourceService: SourceService,
				private loginService: LoginService,
				private snackBar: MatSnackBar,
				private activatedRoute: ActivatedRoute,
				private router: Router,
				private location: Location) {
		//
		// create source status enums
		this.status = Object.values(ReferenceStatus).map(status => ({ value: status }));

	}

	ngOnInit(): void {
		this.initializeReference();

		const paramsSubscription = this.activatedRoute.params
			.pipe(
				switchMap(params => {
					const refId = params['id'];
					return refId ? this.referenceService.getById(refId) : of(null);
				})
			)
			.subscribe({
				next: (response) => {
					this.reference = response ? plainToClass(Reference, response) : this.initializeReference();
				},
				error: (error) => {
					this.handleError(error);
				}
			});

		this.subscriptions.push(paramsSubscription);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	public onSave(): void {
		this.referenceService.save(this.reference).subscribe({
			next: () => {
				this.snackBar.open('Reference saved', 'Dismiss', {
					duration: 2000
				});
				this.router.navigate(['reference/list']);
			},
			error: (error) => {
				this.handleError(error);
			}
		});
	}

	public onUpdate(): void {
		this.referenceService.update(this.reference).subscribe({
			next: () => {
				this.snackBar.open('Reference saved', 'Dismiss', {
					duration: 2000
				});
				this.router.navigate(['reference/list']);
			},
			error: (error) => {
				this.handleError(error);
			}
		});
	}

	public onAddToSources(): void {
		this.router.navigate(['/source/add'], { queryParams: { referenceId: this.reference.id } });
	}

	public onDelete(): void {
		this.referenceService.delete(this.reference.id).subscribe({
			next: () => {
				this.router.navigate(['reference/list']);
			},
			error: (error) => {
				console.error(error);
				this.snackBar.open(error.error.name, 'Dismiss', {
					duration: 1000
				});
			}
		});
	}

	public onCancel(): void {
		this.location.back();
	}

	public onAddTag(tag: Tag): void {
		this.reference.addTag(tag);
	}

	public onRemoveTag(tag: Tag): void {
		this.reference.removeTag(tag);	
	}

	public onFetchTitle(): void {
		//
		// check to see if it exists
		this.inProgress = true;
		
		this.referenceService.getByReference(this.reference.reference).subscribe({
			next: (response) => {
				this.reference = response;
				this.inProgress = false;
			},
			error: (error) => {
				if (error.error.code == Errors.DOCUMENT_NOT_FOUND) {
					//
					// is it a source
					const sourceId = 0; //Source.createIdentifier(this.reference.reference);
					this.sourceService.getById(sourceId).subscribe(
						source => {
							this.router.navigate(['/source', source.id]);
						},
						error => {
							this.sourceService.fetchUrl(this.reference.reference).subscribe(
								doc => {
									const parser = new DOMParser();
									const document = parser.parseFromString(doc, 'text/html');
									const title = document.getElementsByTagName('title');
									
									if (title && title.length > 0) {
										this.reference.title = title[0].textContent;
									}
									
									this.inProgress = false;
								},
								error => {
									this.snackBar.open(error.statusText, 'Dismiss', {
										duration: 2000
									});
									
									this.inProgress = false;
								}
							);							
						}
					);
				}
				else {
					this.snackBar.open(error.error.message, 'Dismiss', {
						duration: 2000
					});
					
					this.inProgress = false;
				}
			}
		});
	}

	private initializeReference(): Reference {
		const newReference = new Reference();
		newReference.status = ReferenceStatus.QUEUE;
		this.reference = newReference;
		return newReference;
	}

	private handleError(error: any): void {
		console.error(error);
		this.snackBar.open(error.message, 'Dismiss', {
			duration: 2000
		});
	}
}
