<h3>Author</h3>
<div fxLayout="column" fxLayoutAlign="space-evenly start" fxLayoutGap="5px">
	<div fxLayout="row" fxLayoutAlign="space-evenly start" fxLayoutGap="5px">	
		<form class="form-group" fxLayout="column" fxLayoutAlign="space-between none" fxLayoutGap="5px">
			<div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="5px">
			    <mat-form-field>
			        <input matInput placeholder="First Name" [(ngModel)]="author.firstName" [ngModelOptions]="{standalone: true}">
			    </mat-form-field>
			 
			    <mat-form-field>
			        <input matInput placeholder="Middle Name" [(ngModel)]="author.middleName" [ngModelOptions]="{standalone: true}">
			    </mat-form-field>
			
			    <mat-form-field>
			        <input matInput placeholder="Last Name" [(ngModel)]="author.lastName" [ngModelOptions]="{standalone: true}">
				</mat-form-field>
			</div>

			<mat-form-field>
				<mat-label>Description</mat-label>
				<textarea matInput placeholder="Description" [(ngModel)]="author.description"
					[ngModelOptions]="{standalone: true}">
				</textarea>
			</mat-form-field>

			<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px">
				<button *ngIf="author.id == null" mat-stroked-button type="button" (click)="onSave()">
					<mat-icon>save</mat-icon> Save
				</button>
				
				<button *ngIf="author.id != null" mat-stroked-button type="button" (click)="onUpdate()">
					<mat-icon>save</mat-icon> Update
				</button>
				
				<button *ngIf="author.id != null" confirm-dialog mat-stroked-button type="button" (confirmed)="onDelete()">
					<mat-icon>delete</mat-icon> Delete
				</button>
				
				<button mat-stroked-button type="button" (click)="onCancel()">
					<mat-icon>cancel</mat-icon> Cancel
				</button>
			</div>
		</form>

		<app-report-card [reputationList]="author.reputationList" [editable]="true"></app-report-card>
	</div>
</div>
