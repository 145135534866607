import { Component, OnInit, ViewChild } from '@angular/core';
import { SourceTableComponent } from './source-table.component';

@Component({
	selector: 'app-source-table-dialog',
	templateUrl: './source-table-dialog.component.html',
	styleUrls: ['./source-table-dialog.component.scss']
})
export class SourceTableDialogComponent implements OnInit {

	@ViewChild('sourceTable')
	sourceTable: SourceTableComponent;
	
	constructor() { }

	ngOnInit(): void {
	}

}
