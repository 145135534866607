<h4>About</h4>

<div>
	<blockquote class="quote">
		"All truths are easy to understand once they are discovered; the point is to discover them." - <em>Galileo
			Galilei</em>
	</blockquote>
</div>

<p>
	Welcome to Respedia, a unique platform that helps readers use their critical thinking skills to explore diverse political and scientific perspectives and navigate the complex media landscape.
	It features a wide range of articles from across the political spectrum.</p>

<p>
	Each article, author, and organization featured is evaluated with a report card assessment.
	Since nearly all sources carry some form of bias, whether monetary or ideological, this rating system serves as a guide to help determine the level of trust to assign.
</p>

<p>
	This platform addresses critical issues like propaganda, war, human rights, energy, finite resources, global warming, and the destruction of Mother Earth&mdash;topics that significantly affect the world.
	It offers a space for laying bare facts, promoting questioning and critique across all political and scientific viewpoints.
</p>

<p>
	Prepare to be challenged, enlightened, and likely unsettled. Most importantly, prepare to think.
</p>