import { Author } from './author';
import { Category } from './category';
import { Organization } from './organization';

export enum ReportCardType {
    TOP = 'top',
    BOTTOM = 'bottom'
}

export enum DisplayType {
  Top = 'top',
  Bottom = 'bottom'
}

export interface ReportCard<T extends ReportCardGrade> {
	category: Category;
	topGrades: T[];
	bottomGrades: T[];
	type: 'author' | 'organization';
}

export interface ReportCardGrade {
	id: number;
	rank: number;
	weight: number;
}

export interface AuthorGrade extends ReportCardGrade {
	author: Author;
}

export interface OrganizationGrade extends ReportCardGrade {
	organization: Organization;
}

export type AuthorReportCard = ReportCard<AuthorGrade>;
export type OrganizationReportCard = ReportCard<OrganizationGrade>;