<div class="search-box">
  <div class="search-container">
    <button mat-icon-button (click)="toggleInputVisibility()">
      <mat-icon>search</mat-icon>
    </button>
    <mat-form-field class="search-input" [ngClass]="{'visible': isInputVisible}">
      <input matInput [formControl]="searchField" [matAutocomplete]="auto" placeholder="Search authors">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let author of authors | async" [value]="author" (onSelectionChange)="onSelect(author)">
          {{ author.firstName }} {{ author.lastName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>

