import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Story } from 'src/app/model/story';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-story-list',
  templateUrl: './story-list.component.html',
  styleUrls: ['./story-list.component.scss']
})
export class StoryListComponent implements OnInit {
	storyColumns: string[] = ['title', 'category', 'actions'];
	
	constructor(private loginService: LoginService,
				private router: Router) {
	 }

	ngOnInit(): void {

	}

	public onStorySelected(story: Story): void {
		this.router.navigate(['/story', story.id]);
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

}
