import { Info } from './info';
import { Source } from './source';
import { Taggable } from './tag';

export class Category extends Taggable {
	id: number;
	treeId: number;

	name: string;
	path: string;
	description: string;
	image: string;
	
	sourceList: Source[] = [];
	infoList: Info[] = [];
}

export class CategoryTreeNode {
	id: number;
	name: string;
	categoryId: number;
	children: CategoryTreeNode[] = [];

	constructor(node?: CategoryTreeNode) {
		if (node) {
			this.id = node.id;
			this.categoryId = node.categoryId
			this.name = node.name;
			this.children = node.children ? node.children.map((child: any) => new CategoryTreeNode(child)) : [];
		}
	}

	addChild(child: CategoryTreeNode): void {
		this.children.push(child);
	}

	findNodeById(id: number): CategoryTreeNode | null {
		if (this.id === id) {
			return this;
		}
		for (const child of this.children) {
			const found = child.findNodeById(id);
			if (found) {
				return found;
			}
		}
		return null;
	}

	removeChildById(id: number): boolean {
		const index = this.children.findIndex(child => child.id === id);
		if (index !== -1) {
			this.children.splice(index, 1);
			return true;
		}
		return false;
	}
}

export interface CategoryFilters {
	story: boolean;
}
