import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { of, switchMap } from 'rxjs';
import { plainToClass } from 'class-transformer';

import { CategoryService } from 'src/app/service/category.service';
import { Category, CategoryTreeNode } from 'src/app/model/category';
import { Info } from 'src/app/model/info';
import { CategoryCommonComponent } from './category-common.compnent';
import { SourceTableDialogComponent } from '../source/source-table-dialog.component';
import { InfoTableDialogComponent } from '../info/info-table-dialog.component';
import { Source } from 'src/app/model/source';

import { StorageService } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';

import { copyByProperties } from 'src/app/model/util/util';
import { Tag, TagType } from 'src/app/model/tag';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-category',
	templateUrl: './category-edit.component.html',
	styleUrls: ['./category-edit.component.scss', './category-common.component.scss']
})
export class CategoryEditComponent extends CategoryCommonComponent implements OnInit {
	category: Category;
	categoryTreeNode: CategoryTreeNode;
	parentId: number;

	tagType = TagType.All;

	constructor(protected categoryService: CategoryService,
				protected storageService: StorageService,
				protected navigationService: NavigationService,	
				protected snackBar: MatSnackBar,
				private dialog: MatDialog,
				protected activatedRoute: ActivatedRoute,
				protected router: Router) {
		
		super(categoryService, storageService, snackBar, navigationService, activatedRoute, router);

		//
		// check for parent
		if (this.router.getCurrentNavigation().extras.state) {
			const categoryTreeId = this.router.getCurrentNavigation().extras.state.categoryTreeId as number;
			if (categoryTreeId) {
				this.categoryService.getTreeNodesById(categoryTreeId).subscribe(
					response => {
						this.categoryTreeNode = new CategoryTreeNode(response);
						this.category.treeId = this.categoryTreeNode.id;
					},
					error => {
						console.error(error);
					}
				)
			}
			
			this.parentId = this.router.getCurrentNavigation().extras.state.parentId as number;
		}
	}

	ngOnInit(): void {
		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			this.clearCategoryFilters();
		}

		this.activatedRoute.params.pipe(
			switchMap(params => {
				const id = params['id'];
				return id ? this.categoryService.getById(Number(id)) : of(null);
			}),
			switchMap(categoryResponse => {
				//
				// if no id was passed then create a new category
				if (!categoryResponse) {
					this.category = new Category();
					
					return of(null);
				}
				
				this.category = plainToClass(Category, categoryResponse);
				this.setCategoryFilters(this.category.tags);

				return this.categoryService.getTreeNodesById(this.category.treeId);
			})
		).subscribe({
			next: (treeNodeResponse) => {
				this.processTreeNodeResponse(treeNodeResponse);
			},
			error: (error) => this.handleError(error),
			complete: () => this.done()
		});
	}

	public onAddTag(tag: Tag): void {
		this.category.addTag(tag);
	}

	public onRemoveTag(tag: Tag): void {
		this.category.removeTag(tag);	
	}

	public onSave(): void {
		if (!this.parentId) {
			this.saveCategory();
		}
		else {
			this.saveChildCategory();
		}
	}

	private saveCategory(): void {
		this.categoryService.save(this.category).subscribe({
			next: (response) => {
				this.category = plainToClass(Category, response);
				this.snackBar.open('Category saved', 'Dismiss', {
					duration: 2000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});		
	}

	private saveChildCategory(): void {
		this.categoryService.saveChild(this.category, this.parentId).subscribe({
			next: (response) => {
				this.category = plainToClass(Category, response);
				this.snackBar.open('Category saved', 'Dismiss', {
					duration: 2000
				});
			},
			error: (error) => {
				this.snackBar.open(error.message, 'Dismiss', {
					duration: 2000
				});
			}
		});		
	}
	
	public onUpdate(): void {
		this.categoryService.update(this.category).subscribe({
			next: (response) => {
				plainToClass(Category, response)
				this.snackBar.open('Category updated', 'Dismiss', {
					duration: 2000
				});			
			},
			error: (error) => {
				console.error(error);
				this.snackBar.open(error.error.message, 'Dismiss', {
					duration: 2000
				});
			}
		});
	}
	
	public onDelete(): void {
		this.categoryService.delete(this.category.id).subscribe({
			next: () => {
				console.log('Deleted');
				this.router.navigate(['category/list']);
			},
			error: error => {
				this.snackBar.open(error.error.message, 'Dismiss', {
					duration: 2000
				});
			}
		});
	}

	public onAddCategory(): void {
		//
		// pass tree and parent
		this.router.routeReuseStrategy.shouldReuseRoute = function() {
			return false;
		};
		this.router.navigate(['category/edit', ''], { state: { 
			categoryTreeId: this.category.treeId,
			parentId: this.category.id }
		});
	}

	public onClick(event: any, index: number): void {
		event.stopPropagation();
	}

	public onInfoSelect(selectedInfo: Info): void {
		if (this.category.infoList == null) {
			this.category.infoList = [];
		}

		//
		// check to see if it's already been add
		const index = this.category.infoList.findIndex(info => info.id == selectedInfo.id);
		if (index > -1) {
			this.snackBar.open('Info already in Story', 'OK', {
				duration: 1000
			});

			return;
		}
		
		//
		// add to list
		this.category.infoList.push(selectedInfo);
	}

	public onSourceDialog(): void {
		const dialogRef = this.dialog.open(SourceTableDialogComponent);
		
		dialogRef.afterClosed().subscribe((result: Source[]) => {
			if (result) {
//				if (!this.category.sourceList) {
//					this.category.sourceList = [];
//				}

				const sourceList: Source[] = this.category.sourceList ? [...this.category.sourceList] : [];
				result.forEach(source => {
					const src = copyByProperties(source, ['id', 'title']);
					sourceList.push(source);
				});
				this.category.sourceList = sourceList;
			}
		});
	}
	
	public onInfoDialog(): void {
		const dialogRef = this.dialog.open(InfoTableDialogComponent);
		
		dialogRef.afterClosed().subscribe((result: Info[]) => {
			if (result) {
				const infoList: Info[] = this.category.infoList ? [...this.category.infoList] : [];
				result.forEach(selected => {
					const source = copyByProperties(selected.source, ['id', 'title']);
					const info = copyByProperties(selected, ['id', 'summary', 'detail', 'tags']);
					info.source = source;


					infoList.push(info);
				});
				this.category.infoList = infoList;
			}
		});
	}
}
