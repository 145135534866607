import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, switchMap, debounceTime, distinctUntilChanged, filter, catchError } from 'rxjs/operators';

import { Story } from 'src/app/model/story';
import { Info } from 'src/app/model/info';
import { StoryService } from 'src/app/service/story.service';

@Component({
	selector: 'app-story-add-button',
	templateUrl: './story-add-button.component.html',
	styleUrls: ['./story-add-button.component.scss']
})
export class StoryAddButtonComponent implements OnInit {

	isOpen: boolean = false;
	label: string;
	
	stories: Observable<Story[]>;
	
	searchField: FormControl = new FormControl();
	
	@Input()
	info: Info;
	
	@Output()
	selected: EventEmitter<StoryButtonEvent> = new EventEmitter<StoryButtonEvent>();

	constructor(private storyService: StoryService) { }

	ngOnInit(): void {
		this.stories = this.searchField.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(title => title.length > 0),
			switchMap(title => this.storyService.searchByTitle(title)
				.pipe(
					catchError(error => {
						if (error.status === 404) {
							return of([]);
						} else {
							// Handle other errors
						}
					})
				)
			)
		);
	}


	public displayFn(story: Story): string {
		return story != null ? story.title : '';
	}

	public select(event: Story): void {
		this.isOpen = !this.isOpen;
		this.searchField.reset();
		this.selected.emit(new StoryButtonEvent(event, this.info));
	}
}

export class StoryButtonEvent {
	story: Story;
	info: Info;

	
	constructor(story: Story, info: Info) {
		this.story = story;
		this.info = info;
	} 
}