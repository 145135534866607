<div fxLayout="row" fxLayoutAlign="space-between center" > 
	<h3>Source</h3>
	<fa-icon *ngIf="source?.id && source?.rank"
		[icon]="[getIcon(source.rank)[0], getIcon(source.rank)[1]]"
		[style.color]="getIcon(source.rank)[2]" size="xl">
	</fa-icon>
</div>
<div fxLayout="column" fxLayoutAlign="space-evenly start">
	<form class="form-group source-form" fxFill>
		<mat-form-field class="source-field" appearance="outline">
			<mat-label>Title</mat-label>
			<input *ngIf="!showUrl; else reference" matInput [(ngModel)]="source.title"
				[ngModelOptions]="{standalone: true}" readonly>
			<ng-template #reference>
				<input matInput placeholder="Reference" [(ngModel)]="source.reference"
					[ngModelOptions]="{standalone: true}" readonly>		
			</ng-template>
			<fa-icon fxHide.xs class="source-copy" matSuffix matTooltip="Copy: {{source.reference}}" matTooltipPosition="left"
				[icon]="['fas', 'copy']" size="lg" (click)="copyUrl()"></fa-icon>
			<a matSuffix mat-stroked-button class="source-url" [href]="source.reference" target="_blank" rel="noopener noreferrer" matTooltipPosition="left">
				<fa-icon [icon]="['fas', 'arrow-up-right-from-square']" matTooltip="Visit source" size="lg"></fa-icon>
			</a>
		</mat-form-field>
	
		<div fxLayout="column" fxLayoutAlign="space-between none" fxLayoutGap="5px">
			<div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="5px">
				<!-- Organization -->
				<mat-form-field fxFlex="30" fxFlex.xs="80" appearance="outline">
					<mat-label>Organization</mat-label>
					<input matInput readonly
						value="{{source.organization?.name}}">	
				</mat-form-field>
					
				<!-- Authors -->
				<app-author-lookup matInput fxFlex fxHide.xs
					[authors]="source.authors"
					[readOnly]="true">
				</app-author-lookup>
					
				<!-- Date -->
				<mat-form-field class="source-date" fxFlex="nogrow">
					<mat-label>Date</mat-label>
					<input matInput placeholder="Date" readonly
						value="{{source.date | date : 'yyyy-MM-dd'}}">
				</mat-form-field>
			</div>

			<!-- Authors xs-->
			<app-author-lookup matInput fxHide fxShow.xs
				[authors]="source.authors"
				[readOnly]="true">
			</app-author-lookup>

			<!-- Description -->
			<mat-form-field  fxFlex="grow">
				<mat-label>Description</mat-label>
				<textarea matInput placeholder="Description" readonly
					value="{{source.description}}"></textarea>
			</mat-form-field>
		</div>

		<!-- tags -->
		<app-taglookup matInput [tags]="source.tags" [readOnly]="true"></app-taglookup>
		
		<!-- edit button -->
		<div *ngIf="isLoggedIn()" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" fxFill>
			<app-bookmarks-button (selected)="onBookmark($event)"></app-bookmarks-button>
			<button *ngIf="source.id != null" mat-stroked-button color="primary" type="button" 
				[routerLink]="['/source/edit', source.id ]">
				<mat-icon>edit</mat-icon> Edit
			</button>
		</div>
	</form>
	<span class="section-title">Excerpts</span>
	<app-fixed-info-table *ngIf="source.infoList?.length" [dataSource]="source.infoList" fxFill></app-fixed-info-table>
</div>