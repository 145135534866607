import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

	constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) { 
		this.data.message = this.data.message ?? "Are you sure you want to delete this item?";
	}

}

export interface ConfirmDialogData {
	message: string;
}
