<table mat-table [dataSource]="dataSource" class="mat-elevation-z1" style="width: 100%">
	<ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
		<th mat-header-cell *matHeaderCellDef>
			{{column.header}}
		</th>
		<td [ngSwitch]="column.type" mat-cell *matCellDef="let row">
			<span *ngSwitchCase="ColumnType.DATE">
				{{column.cell(row) | date:'MM/dd/yyyy'}}
			</span>
			<span *ngSwitchCase="ColumnType.STRING">
				{{column.cell(row)}}
			</span>
			<mat-chip-listbox *ngSwitchCase="ColumnType.TAGS">
				<ng-container *ngFor="let tag of column.cell(row)">
					<mat-chip-option color="accent">{{tag}}</mat-chip-option>
				</ng-container>				
			</mat-chip-listbox>
			<span *ngSwitchDefault>
				{{column.cell(row)}}{{column.type}}
			</span>
		</td>
	</ng-container>

	<!-- row defintions -->
	<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
	<tr class="item-row" mat-row *matRowDef="let row; columns: displayColumns" (click)="onSelect(row)"></tr>

	
	<!-- no results -->
    <tr class="mat-row" *matNoDataRow>
		<td class="mat-cell" [colSpan]="displayColumns.length">
  			No results found
		</td>
	</tr>
</table>



