import { Tag, Taggable } from "./tag";

export class Reference extends Taggable {
	id: number;
	title: string;
	reference: string;
	rank: number;
	status: ReferenceStatus;
}

export enum ReferenceStatus {
	OPEN = 'Open',
	QUEUE = 'Queue'
}

export interface ReferenceFilters {
	status?: ReferenceStatus;
	tags?: Tag[];
	rank?: number;
	pageIndex?: number;
}