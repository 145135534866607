import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../model/category';

@Pipe({
	name: 'categoryPath'
})
export class CategoryPathPipe implements PipeTransform {

	transform(value: unknown, ...args: unknown[]): unknown {
		const category = value as Category;
		if (category.path && category.name != category.path) {
			const path = category.path.replace(':', '&rarr;');
			
			return path;
		}
		else {
			return '';
		}
	}
}
