import { Reputation } from './reputation';

export class Author {
	id: number;
	firstName: string;
	middleName: string;
	lastName: string;
	description: string;
	reputationList: Reputation[] = [];  

	constructor(name: string) {
		const tokens = name.match(/\S+/g);
		if (tokens) {
			this.firstName = tokens[0];
			this.middleName = tokens.length > 2 ? tokens[1] : null;
			this.lastName = tokens.length > 2 ? tokens[2] : tokens[1];
		}
	}
}
