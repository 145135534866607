import { Component, OnInit, ViewEncapsulation, ViewChildren, QueryList, Input } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { setMetaTags } from 'src/app/component/shared/meta-tags.util';

import { StoryService } from 'src/app/service/story.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Story, StoryBitStoryType, StoryBitType } from 'src/app/model/story';

import SwiperCore, { Navigation, Pagination, Virtual } from "swiper";
import { EventsParams, SwiperComponent } from 'swiper/angular';
import { catchError, map, of, switchMap } from 'rxjs';

SwiperCore.use([Navigation, Pagination, Virtual]);

@Component({
	selector: 'app-story',
	templateUrl: './story.component.html',
	styleUrls: ['./story.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class StoryComponent implements OnInit {
	
	@ViewChildren(SwiperComponent)
	swiper?: QueryList<SwiperComponent>;
	
	@Input() id: number | null = null;
	
	story: Story = new Story();

	StoryBitType = StoryBitType;

	constructor(private storyService: StoryService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private meta: Meta,
		private title: Title) { }

	ngOnInit(): void {
		const storyId = this.activatedRoute.snapshot.params['id'] || this.id;

		if (storyId != null) {
			this.storyService.getById(storyId).pipe(
				map(storyResponse => new Story(storyResponse)),
				switchMap(story => {
					const storyBit = story.bitList[0] instanceof StoryBitStoryType
						? story.bitList[0]
						: null;
					if (storyBit) {
						return this.storyService.getById(storyBit.storyBit.id).pipe(
							map(nestedStoryResponse => {
								const nestedStory = new Story(nestedStoryResponse);
								storyBit.storyBit = nestedStory;
								return story;
							})
						);
					}
					else {
						return of(story);
					}
				}),
				catchError(error => {
					console.error(error);
					return of(new Story());
				})
			).subscribe(story => {
				this.story = story;
				setMetaTags(this.title, story.title, this.meta, story.description, story.image);
			});
		}
	}

	public onPrev(id: string): void {
		const swiper = this.swiper.find(swiper => swiper.id == id);
		swiper.swiperRef.slidePrev(200);
	}

	public onNext(id: string): void {
		const swiper = this.swiper.find(swiper => swiper.id == id);
		swiper.swiperRef.slideNext(200);
	}

	public hasPrevious(id: string): boolean {
		const swiper = this.swiper.find(swiper => swiper.id == id);
		
		return swiper ? !swiper.swiperRef.isBeginning : false;
	}

	public hasNext(id: string): boolean {
		const swiper = this.swiper.find(swiper => swiper.id == id);
		
		return swiper ? !swiper.swiperRef.isEnd : true;
	}

	public onSlideChange(params: EventsParams['slideChangeTransitionStart']): void {
		const index = params[0].activeIndex;
		let story: Story = null;

		//
		// check if the next bit is a story
		if (this.story.bitList.length > index + 1) {
			const nextStoryBit = this.story.bitList[index + 1];
			if (nextStoryBit instanceof StoryBitStoryType) {
				story = nextStoryBit.storyBit;
			}
		}

		//
		// load story if hasn't yet
		if (story && !story.bitList) {
			this.storyService.getById(story.id).subscribe({
				next: (response) => {
					const currentStoryBit = this.story.bitList[index + 1];
					if (currentStoryBit instanceof StoryBitStoryType) {
						currentStoryBit.storyBit = response;
					}
				},
				error: (error) => {
					console.error(error);
				}
			});
		}
	}
	
	public renderFraction(currentClass: string, totalClass: string): string {
		return `
			<div class="story-pagination">
				<span class="${currentClass}"></span> / <span class="${totalClass}"></span>
			</div>
	    `;
	}

}
