import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Organization } from '../../model/organization';
import { OrganizationService } from '../../service/organization.service';
import { LoginService } from 'src/app/service/login.service';
import { StorageProperty } from 'src/app/service/storage.service';

import { ErrorHelper } from 'src/app/helpers/error-helper';

@Component({
	selector: 'app-organization',
	templateUrl: './organization.component.html',
	styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
	organization: Organization;
	sites: string;

	filtersProperty: StorageProperty = StorageProperty.ORGANIZATION_FILTERS;

	constructor(private organizationService: OrganizationService,
		private loginService: LoginService,
		private snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute) { }

	ngOnInit() {
		this.organization = new Organization();
		const id = this.activatedRoute.snapshot.params['id'];

		if (id) {
			this.organizationService.getById(id).subscribe({
				next: (response) => {
					this.organization = response;
					this.sites = this.organization.sites?.join(', ') || '';
				},
				error: (error) => {
					ErrorHelper.handleError(error, this.snackBar);
				}
			});
		}
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}
}
