import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { FormControl } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { startWith, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, catchError } from 'rxjs/operators';

import { Category } from 'src/app/model/category';
import { CategoryService } from 'src/app/service/category.service';

@Component({
	selector: 'app-category-lookup',
	templateUrl: './category-lookup.component.html',
	styleUrls: ['./category-lookup.component.scss']
})
export class CategoryLookupComponent implements OnInit, OnDestroy  {
	@Input()
	category: Category;
	
	@Input()
	label: string = 'Category';

	@Input()
	readOnly: boolean = false;
	
	@Input()
	clickable: boolean = true;
	
	categories: Observable<Category[]>;
	
	categoryControl: FormControl = new FormControl();

	@ViewChild('categoryInput')
	categoryInput: ElementRef<HTMLInputElement>;
	
	@ViewChild('categoryAutocomplete')
	categoryAutocomplete: MatAutocomplete;

	@Output()
	selected: EventEmitter<Category> = new EventEmitter<Category>();

	@Output()
	removed: EventEmitter<Category> = new EventEmitter<Category>();

	separatorKeyCodes: number[] = [ENTER, COMMA];

	@Output()
	select: EventEmitter<Category> = new EventEmitter<Category>();

	private destroy$ = new Subject<void>();

	constructor(private categoryService: CategoryService,
				protected router: Router) { }

	public ngOnInit(): void {
		this.categories = this.categoryControl.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(name => name && name.length > 0),
			switchMap(name => this.categoryService.searchByName(name.toUpperCase()).pipe(
				catchError(error => {
					console.error('Error fetching categories:', error);
					return of([]);
				})
			)),
			takeUntil(this.destroy$)
		);
	}
	
	public ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
	
	public onSelect(category: Category): void {
		this.category = category;
		this.categoryControl.setValue(category.name);
		this.select.emit(category);
	}
	
	public displayFn(category: Category): string {
		return category ? category.name : '';
	}

	public onCategory(event: MatAutocompleteSelectedEvent): void {
		this.category = event.option.value;
		this.categoryInput.nativeElement.value = '';
		this.categoryControl.setValue(null);
		this.selected.emit(event.option.value);
	}	

	public onClick(category: Category): void {
		if (this.clickable) {
			this.router.navigate(['organization', category.id]);
		}
	}

	public add(event: MatChipInputEvent): void {
		if (!this.categoryAutocomplete.isOpen) {
			const input = event.input;
			
			//
			// reset input
			if (input) {
				input.value = '';
			}
			
			this.categoryControl.setValue(null);
		}
	}
	
	public remove(category: Category): void {
		this.category = null;
		this.removed.emit(category);
	}
}
