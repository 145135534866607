<div fxLayout="column" fxLayoutAlign="space-evenly start" class="story-table">
	<ng-container>
		<app-category-search></app-category-search>
	</ng-container>

	<table fxFlex="auto" mat-table [dataSource]="dataSource" class="data-table mat-elevation-z1" style="min-width: 600px">
		<!-- Checkbox Column -->
		<ng-container matColumnDef="select" *ngIf="selectable">
			<th mat-header-cell *matHeaderCellDef >
				<mat-checkbox (change)="$event ? onSelectedToggle() : null"
					[checked]="isAllSelected()"
					[indeterminate]="!isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row" >
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="onSelect(row)"
					[checked]="isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

	    <ng-container matColumnDef="title">
	        <th mat-header-cell *matHeaderCellDef> Title </th>
	        <td mat-cell *matCellDef="let story"> {{story.title}} </td>
	    </ng-container>
	
	    <ng-container matColumnDef="category">
	        <th mat-header-cell *matHeaderCellDef> Category </th>
	        <td mat-cell *matCellDef="let story"> {{story.category?.name}} </td>
	    </ng-container>

		<ng-container matColumnDef="actions" *ngIf="isLoggedIn()">
			<th mat-header-cell *matHeaderCellDef fxHide.xs> Actions </th>
			<td mat-cell *matCellDef="let story" fxHide.xs>
				<a [routerLink]="['/story/edit', story.id]">
					<mat-icon aria-hidden="false" aria-label="Edit" size="20">edit</mat-icon>
				</a>
				
				<mat-icon aria-hidden="false" aria-label="View">visibility</mat-icon>
			</td>
		</ng-container>

	    <tr mat-header-row *matHeaderRowDef="storyColumns"></tr>
	    <tr mat-row [routerLink]="['/story', row.id]" *matRowDef="let row; columns: storyColumns;"></tr>
	    <tr class="mat-row" *matNoDataRow>
 			<td class="mat-cell" [colSpan]="storyColumns.length">
   				No results found
			</td>
		</tr>
	</table>
	
	<div class="list-pager" fxLayoutAlign="space-between center" fxFlexOffset="1">
		<mat-paginator  fxFlexAlign="end"
			[length]="resultsLength"
			[pageSizeOptions]="[10, 20, 50]"
			[pageIndex]="pageIndex"
			(page)="onPage($event)">
		</mat-paginator>
		
		<button *ngIf="addable" mat-stroked-button [routerLink]="['/story/add']">
			<mat-icon>add</mat-icon>
		</button>
	</div>
</div>