import { Info } from './info';
import { Category } from './category';
import { User } from './user';
import { Identifiable } from './identifiable';

import { plainToClass } from 'class-transformer';
import { Organization } from './organization';

export class Story implements Identifiable {
	id: number;
	title: string;
	description: string;
	image: string;
	category: Category;
	bitList: StoryBit[] = [];
	
	published: boolean = false;
	owner: User;
	
	constructor(story?: Story) {
		if (story) {
			this.id = story.id;
			this.title = story.title;
			this.description = story.description;
			this.image = story.image;
			this.category = story.category;
			this.published = story.published;
			this.owner = story.owner;
		}

		if (story && story.bitList) {
			this.bitList = story.bitList.map(bit => {
				// Check the type and convert to the appropriate class.
				switch (bit.type) {
					case 'StoryBitInfoType':
						return plainToClass(StoryBitInfoType, bit);
					case 'StoryBitStoryType':
						return plainToClass(StoryBitStoryType, bit);
					default:
						// Handle the case where the type is not recognized or not provided.
						throw new Error("Unrecognized StoryBit type");
				}
			});
		}
		else {
			this.bitList = [];
		}
	}
	
	public addInfo(info: Info): void {
		const order = this.bitList.length + 1;
		const storyBit = new StoryBitInfoType(info, order);

		this.bitList.push(storyBit);
	}
	
	public addStory(story: Story): void {
		const order = this.bitList.length + 1;
		const storyBit = new StoryBitStoryType(story, order);

		this.bitList.push(storyBit);
	}
	
	public getOrganizations(): Organization[] {
		const organizations: Set<number> = new Set();
		const organizationList: Organization[] = [];

		this.bitList.forEach((bit: StoryBit) => {
			if (bit instanceof StoryBitInfoType) {
				const info = (bit as StoryBitInfoType).infoBit;
				if (info && info.source && info.source.organization) {
					const organization = info.source.organization;
					if (!organizations.has(organization.id)) {
						organizations.add(organization.id);
						organizationList.push(organization);
					}
				}
			}
		});

		return organizationList;
	}
}

export abstract class StoryBit {
	id: number;
	description: string;
	order: number;
	image: string;

	type: StoryBitType;
	
	abstract getImage(): string;
	abstract equals(other: StoryBit): boolean;
}

export class StoryBitInfoType extends StoryBit {
	constructor(public infoBit: Info, order: number) {
        super();
        this.type = StoryBitType.SOURCE_BIT;
        this.order = order;
    }
    
    public getImage(): string {
		//
		// Check if the bit has its own image or revert to the source image
        return this.image ? this.image : this.infoBit?.source?.image || null;
    }    
    
	equals(other: StoryBit): boolean {
		if (other instanceof StoryBitInfoType) {
			return this.infoBit.id === other.infoBit.id;
		}
		return false;
	}
}

export class StoryBitStoryType extends StoryBit {
	constructor(public storyBit: Story, order: number) {
        super();
        this.type = StoryBitType.STORY;
        this.order = order;
    }

    public getImage(): string {
        return this.storyBit?.bitList?.[0]?.getImage();
    }   

    equals(other: StoryBit): boolean {
        if (other instanceof StoryBitStoryType) {
            return this.storyBit.id === other.storyBit.id;
        }
        return false;
    }
}

export enum StoryBitType {
	SOURCE_BIT = 'StoryBitInfoType',
	STORY = 'StoryBitStoryType'
}

export interface StoryFilters {
	categoryId?: number;
}
