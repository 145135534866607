<button class="btn btn-primary add-op" mat-stroked-button type="button"
	(click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
	<mat-icon>add</mat-icon><mat-icon>auto_stories</mat-icon>
</button>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
	<mat-form-field class="story-add-button">
		<input type="text" placeholder="Search stories ..." matInput 
			[formControl]="searchField" 
			[matAutocomplete]="auto">
			
		<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [panelWidth]="'auto'"
			[displayWith]="displayFn"
			(optionSelected)='select($event.option.value)'>
			<mat-option *ngFor="let story of stories | async" [value]="story">
				{{story.title}} 
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</ng-template>