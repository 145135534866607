<div class="page-header" fxLayout="row" fxLayoutAlign="space-between start">
	<h3>Organizations</h3>
	<!-- search -->
	<app-organization-search (organizationSelected)="onOrganizationSelected($event)"></app-organization-search>
</div>
<div class="organization-list" fxLayout="column" fxLayoutAlign="space-evenly start" fxLayoutGap="5px">
	<table mat-table [dataSource]="dataSource" class="data-table mat-elevation-z1">
	    <ng-container matColumnDef="name">
	        <th mat-header-cell *matHeaderCellDef> Name </th>
	        <td mat-cell *matCellDef="let org"> {{org.name}} </td>
	    </ng-container>
	
	    <ng-container matColumnDef="site">
	        <th mat-header-cell *matHeaderCellDef> Site </th>
	        <td mat-cell *matCellDef="let org"> {{org.site}} </td>
	    </ng-container>
	
	    <tr mat-header-row *matHeaderRowDef="orgColumns"></tr>
	    <tr mat-row [routerLink]="['/organization', row.id]" *matRowDef="let row; columns: orgColumns;"></tr> 
	</table>

	<div class="list-pager" fxLayoutAlign="space-between center" fxFlexOffset="1">
		<mat-paginator
			[length]="resultsLength"
			[pageSizeOptions]="[10, 20, 50]"
			[pageIndex]="pageIndex"
			(page)="onPage($event)">
		</mat-paginator>
		
		<button *ngIf="isLoggedIn()" mat-stroked-button [routerLink]="['/organization/add']" >
		    <mat-icon>add</mat-icon> Add
		</button>
	</div>
</div>