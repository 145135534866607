import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter, map } from 'rxjs/operators';

import { LoginService } from './service/login.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	private metadataMapping = {
		'home': { title: 'Respedia - Home', description: 'Essential facts you might not know...', imageUrl: 'https://cdn.respedia.org/images/respedia_logo.png' },
		'author/list': { title: 'Respedia - Authors', description: null, imageUrl: null },
		'author/add': { title: 'Respedia - New Author', description: null, imageUrl: null },
		'^author/\\d+$': { title: 'Respedia - Author', description: null, imageUrl: null },
		'bookmarks/list': { title: 'Respedia - Bookmarks', description: null, imageUrl: null },
		'bookmarks/add': { title: 'Respedia - New Bookmarks', description: null, imageUrl: null },
		'^bookmarks/\\d+$': { title: 'Respedia - Bookmarks', description: null, imageUrl: null },
		'category/list': { title: 'Respedia - Categories', description: null, imageUrl: null },
		'category/add': { title: 'Respedia - New Category', description: null, imageUrl: null },
		'^category/\\d+$': { title: 'Respedia - Category', description: null, imageUrl: null },
		'organization/list': { title: 'Respedia - Organizations', description: null, imageUrl: null },
		'organization/add': { title: 'Respedia - New Organization', description: null, imageUrl: null },
		'^organization/\\d+$': { title: 'Respedia - Organization', description: null, imageUrl: null },
		'reference/list': { title: 'Respedia - References', description: null, imageUrl: null },
		'^reference/\\d+$': { title: 'Respedia - Reference', description: null, imageUrl: null },
		'source/list': { title: 'Respedia - Sources', description: null, imageUrl: null },
		'source/add': { title: 'Respedia - New Source', description: null, imageUrl: null },
		'^source/edit/\\d+$': { title: 'Respedia - Edit Source', description: null, imageUrl: null },
		'story/list': { title: 'Respedia - Stories', description: null, imageUrl: null },
		'story/add': { title: 'Respedia - New Story', description: null, imageUrl: null },
		'^story/edit/\\d+$': { title: 'Respedia - Edit Story', description: null, imageUrl: null }
	};
	
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private meta: Meta,
		private title: Title,
		private loginService: LoginService
	) {
		//
		// set title and meta data
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map(route => {
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter(route => route.outlet === 'primary'),
				map(route => route.snapshot.url.join('/'))
			)
			.subscribe(url => {
				const metadataEntry = Object.entries(this.metadataMapping).find(([pattern, metadata]) => {
					const regex = new RegExp(pattern);
					return regex.test(url);
				});

				const metadata = metadataEntry?.[1] || { title: 'Respedia' };
				const hasDescription = 'description' in metadata;
				const hasImageUrl = 'imageUrl' in metadata;

				this.title.setTitle(metadata.title);

				const metaTags = [{ name: 'og:title', content: metadata.title }];

				if (hasDescription && metadata.description) {
					metaTags.push({ name: 'og:description', content: metadata.description });
				}

				if (hasImageUrl && metadata.imageUrl) {
					metaTags.push({ name: 'og:image', content: metadata.imageUrl });
				}

				this.meta.addTags(metaTags);
			});
	}
	
	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}
	
	public logout(): void {
		this.loginService.logout();
	}
}
