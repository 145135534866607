<h3>Story Edit</h3>

<div fxLayout="column" fxLayoutAlign="space-evenly start">
	<form class="form-group story-edit-form">
		<div fxLayout="row" fxLayoutAlign="space-between none" fxLayoutGap="5px">
			<!-- Title -->
		    <mat-form-field fxFlex="75">
		        <input matInput placeholder="Title" [(ngModel)]="story.title" [ngModelOptions]="{standalone: true}">
		    </mat-form-field>
		
			<!-- Category -->
			<app-category-lookup
				[category]="story.category"
				(selected)="onCategorySelect($event)"
				(removed)="onCategoryRemove($event)">
			</app-category-lookup>
		
			<!-- Published -->
			<mat-checkbox [(ngModel)]="story.published" labelPosition="before" [ngModelOptions]="{standalone: true}">
				Published
			</mat-checkbox>
		</div>
	 	<!--  description -->
		<mat-form-field class="source-field">
			<textarea matInput placeholder="Description" [(ngModel)]="story.description"
				[ngModelOptions]="{standalone: true}">
			</textarea>
		</mat-form-field>
	
		<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" fxFill>
			<button mat-stroked-button color="primary" (click)="onInfoAdd()">
				<mat-icon>add</mat-icon> Add Source Bit
			</button>
			
			<button mat-stroked-button color="primary" (click)="onStoryAdd()">
				<mat-icon>add</mat-icon> Add Story
			</button>
			
			<button *ngIf="story.id == null" mat-stroked-button type="button" (click)="onSave()">
			  <mat-icon>save</mat-icon> Save
			</button>
			
			<button *ngIf="story.id != null" mat-stroked-button type="button" (click)="onUpdate()">
			  <mat-icon>save</mat-icon> Update
			</button>
			
			<button *ngIf="story.id != null" mat-stroked-button type="button" confirm-dialog (confirmed)="onDelete()">
			  <mat-icon>delete</mat-icon> Delete
			</button>
		</div>
	</form>

	<!-- story bit list -->
	<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="5px" fxFill>
		<div cdkDropList class="info-list" (cdkDropListDropped)="drop($event)" fxFlex>
			<div *ngFor="let storyBit of story.bitList; let i = index" cdkDrag class="info-box" [ngClass]="{'selected': selectedBit?.id === storyBit.id}">
				<div style="cursor: move" cdkDragHandle>
					<svg width="24px" fill="currentColor" viewBox="0 0 24 24">
						<path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
						<path d="M0 0h24v24H0z" fill="none"></path>
					</svg>
				</div>
				<span (click)="onClick(i)">
					<ng-container>
					    <ng-container *ngIf="storyBit.description; else titleOrSummary">
					        <i>{{ storyBit.description }}</i>
					    </ng-container>
					    <ng-template #titleOrSummary>
					        <ng-container *ngIf="storyBit.storyBit?.title; else justSummary">
					            <b><i>{{ storyBit.storyBit.title }}</i></b>
					        </ng-container>
					        <ng-template #justSummary>
					            {{ storyBit.infoBit?.summary }}
					        </ng-template>
					    </ng-template>
				</ng-container>
				</span>
				<mat-icon aria-hidden="false" aria-label="Delete" size="20" style="cursor: default" (click)="onDeleteInfo(storyBit)">delete</mat-icon>
			</div>
		</div>

		<!--  story bit detail -->
		<div fxFlex>
			<div *ngIf="selectedBit != null" fxLayout="column" fxLayoutAlign="space-evenly start">
				<div class="source-bit-image">
					<img [src]="selectedBit.image ? selectedBit.image : selectedBit.infoBit.source.image" alt="Selected Bit Image">
				</div>
				<mat-form-field fxFill>
					<input matInput placeholder="Description" [(ngModel)]="selectedBit.description" [ngModelOptions]="{standalone: true}">
				</mat-form-field>
				<mat-form-field fxFill>
					<input matInput placeholder="Image" [(ngModel)]="selectedBit.image" [ngModelOptions]="{standalone: true}">
				</mat-form-field>

				<ng-container [ngSwitch]="selectedBit.type">
					<!-- Story -->
					<ng-container *ngSwitchCase="StoryBitType.STORY">
						<span class="source-bit-summary">
							<mat-icon>auto_stories</mat-icon>&nbsp; {{selectedBit.storyBit.title}}
						</span>
					</ng-container>
					
					<!-- Source bit -->
					<ng-container *ngSwitchCase="StoryBitType.SOURCE_BIT">
						<span class="source-bit-summary">{{selectedBit.infoBit.summary}}</span>
						<markdown class="story-bit-detail" [data]="selectedBit.infoBit.detail"></markdown>
						<span class="source-bit-reference">{{selectedBit.infoBit.source.reference}}</span>					
					</ng-container>
					
					<!-- TODO: make source bit default? -->
					<ng-container *ngSwitchDefault>
						<span class="source-bit-summary">|{{selectedBit.infoBit.type}}|</span>
						<markdown class="story-bit-detail" [data]="selectedBit.infoBit.detail"></markdown>
						<span class="source-bit-reference">{{selectedBit.infoBit.source.reference}}</span>		
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</div>

