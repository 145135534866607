import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Info } from 'src/app/model/info';

@Component({
	selector: 'app-info-accordian',
	templateUrl: './info-accordian.component.html',
	styleUrls: ['./info-accordian.component.scss']
})
export class InfoAccordianComponent implements OnInit {
	
	@Input()
	infoList: Info[];

	@Input()
	editable: boolean = false;
	
	@Output()
	delete: EventEmitter<Info> = new EventEmitter<Info>();

	constructor() { }

	ngOnInit(): void {
	}

	onDrop(event: CdkDragDrop<Info>): void {
		moveItemInArray(this.infoList, event.previousIndex, event.currentIndex);
	}
	
	onDelete(info: Info): void {
		this.delete.emit(info);
	}
}
