<button class="btn btn-primary add-op" mat-stroked-button type="button"
	(click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
	<mat-icon>add</mat-icon><mat-icon>bookmark</mat-icon>
</button>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
	<mat-form-field class="bookmarks-button">
		<input type="text" placeholder="Search bookmarks ..." matInput 
			[formControl]="searchField" 
			[matAutocomplete]="auto">
			
		<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [panelWidth]="'auto'"
			[displayWith]="displayFn"
			(optionSelected)='select($event.option.value)'>
			<mat-option *ngFor="let bookmark of bookmarks | async" [value]="bookmark">
				{{bookmark.label}} 
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</ng-template>


