import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { Identifiable } from 'src/app/model/identifiable';

export interface FixedTableColumn<T extends Identifiable> {
	columnDef: string;
	header: string;
	type?: ColumnType;
	cell(row: T): any; 
}

export enum ColumnType {
	DATE = 'date',
	STRING = 'string',
	TAGS = 'tags'
}

@Component({
	selector: 'app-fixed-table',
	templateUrl: './fixed-table.component.html',
	styleUrls: ['./fixed-table.component.scss']
})
export class FixedTableComponent<T extends Identifiable> implements OnInit {
	@Input()
	dataSource: T[] = [];
	
	@Output()
	selected: EventEmitter<T> = new EventEmitter();
	
	columns: FixedTableColumn<T>[] = [];
	displayColumns = this.columns.map(column => column.columnDef);
	
	ColumnType = ColumnType;
	
	constructor() { 

	}

	ngOnInit(): void {
		this.displayColumns = this.columns.map(column => column.columnDef);
	}

	public onSelect(item: T): void {
		this.selected.emit(item);
	}
}

