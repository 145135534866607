import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	private backButton: boolean = false;

	constructor(private router: Router) {
		this.router.events.pipe(
			filter(event => event instanceof NavigationStart))
			.subscribe(
				(event: NavigationStart) => {
					this.backButton = event.navigationTrigger == 'popstate' ? true : false;
				}
			);
	}
	
	public isBackButton(): boolean {
		return this.backButton;
	}
}