<ng-container *ngFor="let info of infoList; let i = index">
	<mat-expansion-panel class="headers-align">
		<mat-expansion-panel-header>
			<mat-panel-title >
				{{info.summary}}
			</mat-panel-title>
			<mat-panel-description>
				<button mat-icon-button color="primary" (click)="onClick($event, i)" [disableRipple]="true">
					<mat-icon>clear</mat-icon>
				</button>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<markdown [data]="info.detail"></markdown>
		<span>
			<a [routerLink]="['/source', info.source.id]">{{info.source.reference}}</a>
		</span>
	</mat-expansion-panel>
</ng-container>