import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StoryComponent } from './story.component';
import { StoryListComponent } from './story-list.component';
import { StoryEditComponent } from './story-edit.component';

const routes: Routes = [
	{ path: 'story/list', component: StoryListComponent },
	{ path: 'story/add', component: StoryEditComponent },
	{ path: 'story/:id', component: StoryComponent },
	{ path: 'story/edit/:id', component: StoryEditComponent }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})
export class StoryRoutingModule { }