<div class="page-header">
	<h3>Organization</h3>
</div>
<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
    <!-- Fields and Report Card -->
    <div fxLayout.xs="column" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="50px" fxLayoutGap.xs="10px">
        <form fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="5px" fxFlex="30">
            <!-- Name -->
            <mat-form-field>
				<mat-label>Name</mat-label>
                <input matInput placeholder="Name" [value]="organization.name" readonly>
            </mat-form-field>
            
            <!-- Site -->    
            <mat-form-field>
				<mat-label>Site</mat-label>
                 <input matInput placeholder="Site" [value]="sites" readonly>
            </mat-form-field>

			<!-- Description -->
			<mat-form-field appearance="outline">
				<mat-label>Description</mat-label>
				<textarea matInput placeholder="Description" [value]="organization.description" readonly>
				</textarea>
			</mat-form-field>

			<!-- edit button -->
			<button fxFlexAlign="end" *ngIf="isLoggedIn()" mat-stroked-button type="button" [routerLink]="['/organization/edit', organization.id]">
                <mat-icon>edit</mat-icon> Edit
            </button>
        </form>
        
        <!-- Report Card -->
        <div fxFlex="70" >
            <app-report-card [reputationList]="organization.reputationList" [editable]="false"></app-report-card>
        </div>
	</div>
	
	<div *ngIf="organization.id" fxFill>
		<span class="section-title-small">Sources</span>
		<app-source-table [organization]="organization" [filtersProperty]="filtersProperty" [lazy]="true"></app-source-table>
	</div>
</div>