import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

export class ErrorHelper {

	static handleError(error: any, snackBar: MatSnackBar): void {
		console.error(JSON.stringify(error));

		let errorMessage = 'An unknown error occurred'; // Default error message

		if (error.error && error.error.message) {
			// The error is an object containing a 'message' field.
			errorMessage = error.error.message;
		} else if (error.status === 400 && error.error instanceof Array) {
			// The error is a 400 validation error, structured as an array.
			errorMessage = error.error
				.map((e: any) => `${e.field}: ${e.errors.join(', ')}`)
				.join(' | ');
		} else if (error.message) {
			// The error itself contains the 'message' field.
			errorMessage = error.message;
		} else if (typeof error.error === 'string') {
			// The error is a plain text string.
			errorMessage = error.error;
		}

		snackBar.open(errorMessage, 'Dismiss', {
			duration: 5000
		});
	}
}



