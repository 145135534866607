import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { Author } from 'src/app/model/author';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { startWith, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AuthorService } from 'src/app/service/author.service';

@Component({
	selector: 'app-author-lookup',
	templateUrl: './author-lookup.component.html',
	styleUrls: ['./author-lookup.component.scss']
})
export class AuthorLookupComponent implements OnInit {
	@Input()
	authors: Author[] = [];
	
	@Input()
	label: string = 'Authors';
	
	@Input()
	readOnly: boolean = false;
	
	@Input()
	clickable: boolean = true;
	
	@Output()
	selected: EventEmitter<Author> = new EventEmitter();

	@Output()
	removed: EventEmitter<Author> = new EventEmitter();
	
	@ViewChild('authorInput')
	authorInput: ElementRef<HTMLInputElement>;
	
	@ViewChild('authorComponentAutocomplete')
	authorAutocomplete: MatAutocomplete;
	
	authorsObs: Observable<Author[]>;
	authorControl: FormControl = new FormControl();
	
	separatorKeyCodes: number[] = [ENTER, COMMA];
	
	constructor(private authorService: AuthorService,
				protected router: Router) { }

	ngOnInit() {
        this.authorsObs = this.authorControl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            distinctUntilChanged(),
            filter( name => name.length > 0),
            switchMap(name => this.authorService.searchByName(name))
        );
	}
	
	public onAuthorSelected(event: MatAutocompleteSelectedEvent): void {
		this.authors.push(event.option.value);
		this.authorInput.nativeElement.value = '';
		this.authorControl.setValue('');
		
		this.selected.emit(event.option.value);
	}
	
	public onClick(author: Author): void {
		if (this.clickable) {
			this.router.navigate(['author', author.id]);
		}
	}
	
	public add(event: MatChipInputEvent): void {
		if (!this.authorAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;
			
			if ((value || '').trim()) {
				if (this.authors == null) {
					this.authors = [];
				}
				
				const author = new Author(value);
				this.authors.push(author);
			}
			
			if (input) {
				input.value = '';
			}

		}
	}

	remove(author: Author): void {
		const index = this.authors.indexOf(author);
		if (index >= 0) {
			this.authors.splice(index, 1);
		}
		
		this.removed.emit(author);
	}

    displayAuthor(author: Author): string {
        return author ? (author.firstName + ' ' + author.lastName) : '';
    }
}
