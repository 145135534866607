import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Reference, ReferenceFilters, ReferenceStatus } from 'src/app/model/reference';
import { ReferenceService } from 'src/app/service/reference.service';
import { StorageService, StorageProperty } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { LoginService } from 'src/app/service/login.service';

import { PaginatorComponent } from '../paging/paginator.component';
import { Tag, TagType, TagUtility } from 'src/app/model/tag';

@Component({
  selector: 'app-reference',
  templateUrl: './reference-list.component.html',
  styleUrls: ['./reference-list.component.scss']
})
export class ReferenceListComponent extends PaginatorComponent<Reference> implements OnInit {
	columns: string[] = ['reference', 'tags', 'actions'];

	filters: ReferenceFilters = { tags: []}

	statusEnum = ReferenceStatus;
	statusList = [];
	
	tagType = TagType.Reference;
	
	ranks: number[] = [1, 2, 3, 4, 5];
	rankk: number;

	constructor(private service: ReferenceService,
				private router: Router,
				private storageService: StorageService,
				private navigationService: NavigationService,
				private snackBar: MatSnackBar,
				private loginService: LoginService) {
		super();
		
		//
		// create source status enums
		this.statusList = Object.values(ReferenceStatus).map(status => ({ value: status }));
	}
	
	ngOnInit(): void {
		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			//
			// filters
			this.filters = this.storageService.getItem<ReferenceFilters>(StorageProperty.REFERENCE_FILTERS);
			if (!this.filters) {
				this.filters = { tags: []}
			}
			else {
				this.pageIndex = this.filters.pageIndex;
			}		
		}
		else {
			this.storageService.clearItem(StorageProperty.REFERENCE_FILTERS);
		}

		super.ngOnInit();
	}

	public filterByTags(): void {
		this.storageService.setItem(StorageProperty.REFERENCE_FILTERS, this.filters);

		//
		// reset list
		this.reset();
	}

	public onAddToSources(reference: Reference): void {
		this.router.navigate(['/source/add'], { queryParams: { referenceId: reference.id } });
	}

	public onStatusChange(status: string): void {
		if (status) {
			this.filters.status = status as ReferenceStatus;
		}
		else {
			delete this.filters.status;
		}
	}

	public onRankChange(rank: number): void {
		if (rank) {
			this.filters.rank = rank;
		}
		else {
			delete this.filters.rank;
		}
	}

	public onAddTag(tag: Tag): void {
		this.filters.tags = TagUtility.addTag(tag, this.filters.tags);
	}

	public onRemoveTag(tag: Tag): void {
		this.filters.tags = TagUtility.removeTag(tag, this.filters.tags);
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	protected getData(): void {
		this.isLoading = true;
		this.service.getList(this.pageIndex + 1, this.pageSize, this.filters).subscribe({
			next: (response) => {
				this.loadData(response);			
			},
			error: (error) => {
				this.isLoading = false;
				console.error('Error:', error.message);
				this.snackBar.open(error.message, 'Dismiss', {
					duration: 2000
				});
			},
			complete: () => {
				this.isLoading = false;
			}
		});	
	}

    protected onPageChange(pageIndex: number): void {
		this.filters.pageIndex = pageIndex;
		this.storageService.setItem(StorageProperty.REFERENCE_FILTERS, this.filters);
	}

}
