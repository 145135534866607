import { Identifiable } from './identifiable';

import { Source } from './source';
import { Tag, Taggable } from './tag';

export class Info extends Taggable implements Identifiable {
	id: number;
	summary: string;
	detail: string;
	date: string;
	infoType: InfoType;
	source: Source;
	image: string;

}

export enum InfoType {
	DISINFORMATION = 'Disinformation',
	FACT = 'Fact',
	EXCERPT = 'Excerpt',
	FALSEHOOD = 'Falsehood',
	QUOTE = 'Quote'
}

export interface InfoFilters {
	tags?: Tag[];
	sourceTags?: Tag[];
	pageIndex?: number;
}