import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { startWith, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import { OrganizationService } from 'src/app/service/organization.service';
import { Organization } from 'src/app/model/organization';

@Component({
	selector: 'app-organization-lookup',
	templateUrl: './organization-lookup.component.html',
	styleUrls: ['./organization-lookup.component.scss']
})
export class OrganizationLookupComponent implements OnInit {
	@Input()
	organization: Organization;
	
	@Input()
	label: string = 'Organization';

	@Input()
	readOnly: boolean = false;
	
	@Input()
	clickable: boolean = true;

	organizations: Observable<Organization[]>;

	organizationControl: FormControl = new FormControl();

	@ViewChild('organizationInput')
	organizationInput: ElementRef<HTMLInputElement>;
	
	@ViewChild('organizationAutocomplete')
	organizationAutocomplete: MatAutocomplete;

	@Output()
	selected: EventEmitter<Organization> = new EventEmitter<Organization>();

	@Output()
	removed: EventEmitter<Organization> = new EventEmitter<Organization>();
	
	@Output()
	new: EventEmitter<Organization> = new EventEmitter<Organization>();

	separatorKeyCodes: number[] = [ENTER, COMMA];

	constructor(private organizationService: OrganizationService,
				protected router: Router) { }

	ngOnInit() {
		this.organizations = this.organizationControl.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			distinctUntilChanged(),
			filter(name => name != null && name.length > 0),
			switchMap(name => this.organizationService.searchByName(name))
		);
	}
	
	public displayFn(organization: Organization): string {
		return organization ? organization.name : '';
	}

	public onOrganization(event: MatAutocompleteSelectedEvent): void {
		this.organization = event.option.value;
		this.organizationInput.nativeElement.value = '';
		this.organizationControl.setValue(null);

		this.selected.emit(event.option.value);
	}	

	public onClick(organization: Organization): void {
		if (this.clickable) {
			this.router.navigate(['organization', organization.id]);
		}
	}

	public add(event: MatChipInputEvent): void {
		if (!this.organizationAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;
			
			if ((value || '').trim()) {
				const organization = new Organization();
				organization.name = value.trim();
				this.organization = organization;
				
				this.new.emit(this.organization);
			}
			
			//
			// reset input
			if (input) {
				input.value = '';
			}
			
			this.organizationControl.setValue(null);
		}
	}
	
	public remove(organization: Organization): void {
		this.organization = null;
		this.removed.emit(organization);
	}
}
