<h3>Source</h3>
<app-spinner *ngIf="inProgress" overlay="true"></app-spinner>
<div fxLayout="column" fxLayoutAlign="space-evenly start">
<form class="form-group source-form">
	<mat-form-field class="source-field">
		<input matInput placeholder="Reference" [(ngModel)]="source.reference"
			[ngModelOptions]="{standalone: true}">
		<button *ngIf="!source.id" mat-button matSuffix mat-stroked-button (click)="onGetDetails()">Fetch</button>
		<a *ngIf="source.id" mat-raised-button matSuffix mat-stroked-button 
			[href]="source.reference" target="_blank" rel="noopener noreferrer">Link</a>
	</mat-form-field>

	<mat-form-field class="source-field">
		<input matInput placeholder="Alternative Reference" [(ngModel)]="source.altReference"
			[ngModelOptions]="{standalone: true}">
	</mat-form-field>

	<mat-form-field class="source-field"> <input matInput
		placeholder="Title" [(ngModel)]="source.title"
		[ngModelOptions]="{standalone: true}"> </mat-form-field>

	<mat-form-field class="source-field"> <textarea
		matInput placeholder="Description" [(ngModel)]="source.description"
		[ngModelOptions]="{standalone: true}"></textarea> </mat-form-field>

	<div fxLayout="row" fxLayoutAlign="space-around end" fxLayoutGap="5px" fxFill>
		<!--Organization -->
		<app-organization-lookup [organization]="source.organization"
			(selected)="onOrganization($event)"
			(removed)="onRemoveOrganization()"
			(new)="onNewOrganization($event)"></app-organization-lookup>
		
		<!--Date -->
		<mat-form-field class="source-field">
			<input matInput
				[matDatepicker]="src_date" placeholder="Date"
				[(ngModel)]="source.date" [ngModelOptions]="{standalone: true}">
			<mat-datepicker-toggle matSuffix [for]="src_date"></mat-datepicker-toggle>
			<mat-datepicker #src_date></mat-datepicker>
		</mat-form-field>

		<!-- Status -->
<!--		<mat-form-field>-->
<!--			<mat-select placeholder="Status" [(ngModel)]="source.status" [ngModelOptions]="{standalone: true}">-->
<!--				<mat-option	*ngFor="let type of status" [value]="type.value">{{type.value}}</mat-option>-->
<!--			</mat-select>-->
<!--		</mat-form-field>-->

		<!-- Rank -->
		<mat-form-field>
			<mat-select placeholder="Rank" [(ngModel)]="source.rank" [ngModelOptions]="{standalone: true}">
				<mat-option>None</mat-option>
				<mat-option	*ngFor="let rank of ranks" [value]="rank">{{rank}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<!-- Authors -->
	<div fxLayout="row" fxLayoutGap="5px">
		<app-author-lookup matInput [authors]="source.authors" fxFlex="75"></app-author-lookup>
	    <mat-form-field *ngIf="details">
			<mat-select placeholder="Authors" (selectionChange)="onAddAuthor($event.value)">
				<mat-option *ngFor="let author of details.authors" [value]="author">
					{{ author }}
				</mat-option>
			</mat-select>
		</mat-form-field>		
	</div>

	<!-- tags -->
	<app-taglookup
		matInput
		[tagType]="tagType"
		[tags]="source.tags" 
		[readOnly]="false" 
		[clickable]="false"
		(add)="onAddTag($event)"
		(remove)="onRemoveTag($event)">
	</app-taglookup>

	<!-- entities -->
	<fieldset *ngIf="details">
	    <legend style="font-weight: 400;">Suggested entity tags</legend>
	    <mat-form-field>
			<mat-select placeholder="Filter" [(ngModel)]="entityMinCount" [ngModelOptions]="{standalone: true}">
				<mat-option *ngFor="let bucket of entityFrequencies" [value]="bucket">
					{{ bucket }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		
		<button mat-stroked-button type="button" (click)="onAddAllExistingEntities()">
			<mat-icon>add</mat-icon> Add Existing
		</button>
	
	    <mat-chip-listbox>
			<ng-container *ngFor="let entity of details.entities">
		        <mat-chip-row
		        	*ngIf="entity.count >= entityMinCount"
		            [removable]="true" 
		            (removed)="onRemoveEntity(entity.label)" 
		            (click)="onAddEntity(entity.label)"
		            [ngStyle]="{'background-color': entity.existing === 1 ? 'tan' : ''}">
		            {{ entity.label }} ({{ entity.count }})
		            <mat-icon matChipRemove>cancel</mat-icon>
		        </mat-chip-row>
	        </ng-container>
	    </mat-chip-listbox>       
	</fieldset>

	<!-- keywords -->
	<fieldset *ngIf="details">
	    <legend>Suggested keyword tags</legend>
	    <mat-form-field>
			<mat-select placeholder="Filter" [(ngModel)]="keywordMinCount" [ngModelOptions]="{standalone: true}">
				<mat-option *ngFor="let bucket of keywordFrequencies" [value]="bucket">
					{{ bucket }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		
		<button mat-stroked-button type="button" (click)="onAddAllExistingKeywords()">
			<mat-icon>add</mat-icon> Add Existing
		</button>

		<mat-chip-listbox>
		    <ng-container *ngFor="let keyword of details.keywords">
		        <mat-chip-row 
		            *ngIf="keyword.count >= keywordMinCount"
		            [removable]="true" 
		            (removed)="onRemoveKeyword(keyword.keyword)" 
		            (click)="onAddKeyword(keyword.keyword)"
		            [ngStyle]="{'background-color': keyword.existing === 1 ? 'tan' : ''}">
		            {{ keyword.keyword }} ({{ keyword.count }})
		            <mat-icon matChipRemove>cancel</mat-icon>
		        </mat-chip-row>
		    </ng-container>
		</mat-chip-listbox>     
	</fieldset>

	<!-- image -->
	<mat-form-field class="source-field"> <input matInput
		placeholder="Image" [(ngModel)]="source.image"
		[ngModelOptions]="{standalone: true}"> </mat-form-field>
		
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" fxFill>
		<app-bookmarks-button (selected)="onBookmark($event)"></app-bookmarks-button>

		<button mat-stroked-button type="button" type="button" [disabled]="source.id == null" (click)="addInfo()">
			<mat-icon>add</mat-icon> Add Info
		</button>

		<button *ngIf="source.id == null" mat-stroked-button type="button" (click)="onSave()">
		  <mat-icon>save</mat-icon> Save
		</button>
		
		<button *ngIf="source.id != null" mat-stroked-button type="button" (click)="onUpdate()">
			<mat-icon>save</mat-icon> Update
		</button>

		<button *ngIf="source.id != null" mat-stroked-button type="button" confirm-dialog name="source" (confirmed)="onDelete()">
			<mat-icon>delete</mat-icon> Delete
		</button>

		<button mat-stroked-button type="button" (click)="onCancel()">
			<mat-icon>cancel</mat-icon> Cancel
		</button>
	</div>
</form>


	<br/>

	<app-fixed-info-table [dataSource]="source.infoList" [editable]="true" (edit)="editInfo($event)" (delete)="deleteInfo($event)"></app-fixed-info-table>

</div>