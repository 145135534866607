import { Component, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Router } from '@angular/router';
import { CategoryService } from 'src/app/service/category.service';
import { StorageService, StorageProperty } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { LoginService } from 'src/app/service/login.service';
import { Category } from 'src/app/model/category';

import { PaginatorComponent } from '../paging/paginator.component';

@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent extends PaginatorComponent<Category> implements OnInit {
	categoryList: Category[] = [];
	categoryColumns: string[] = ['name', 'description'];

	constructor(
		private categoryService: CategoryService,
		private router: Router,
		private storageService: StorageService,
		private navigationService: NavigationService,
		private snackBar: MatSnackBar,
		private loginService: LoginService) { 
			super();
		}

	public ngOnInit(): void {
		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			//
			// page
			const pageIndex = this.storageService.getItem<number>(StorageProperty.CATEGORY_PAGE_INDEX);
			if (pageIndex) {
				this.pageIndex = pageIndex;
			}
		}
		else {
			this.storageService.clearItem(StorageProperty.CATEGORY_PAGE_INDEX);
		}

		super.ngOnInit();
	}

	protected getData(): void {
		this.isLoading = true;

		// Replace this call with the appropriate call to your CategoryService
		this.categoryService.getCateogryList(this.pageIndex + 1, this.pageSize).subscribe({
			next: (response) => {
				this.loadData(response);
			},
			error: (error) => {
				console.error('Error:', error.message);
				this.snackBar.open(error.message, 'Dismiss', {
					duration: 2000
				});
			},
			complete: () => {
				this.isLoading = false;
			}
		});
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	public onAdd(): void {
		this.router.navigate(['/category', '']);
	}

	public onView(id: number): void {
		this.router.navigate(['/category', id]);
	}

	public onCategorySelected(category: Category): void {
		this.router.navigate(['/category', category.id]);
	}

	protected onPageChange(pageIndex: number): void {
		this.storageService.setItem(StorageProperty.CATEGORY_PAGE_INDEX, pageIndex);
	}
}
