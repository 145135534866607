import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { plainToClass } from 'class-transformer';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { SourceTableDialogComponent } from '../source/source-table-dialog.component';
import { InfoTableDialogComponent } from '../info/info-table-dialog.component';

import { Bookmarks } from 'src/app/model/bookmarks';
import { BookmarksService } from 'src/app/service/bookmarks.service';
import { Source } from 'src/app/model/source';
import { Info } from 'src/app/model/info';
import { Tag, TagType } from 'src/app/model/tag';

import { ErrorHelper } from 'src/app/helpers/error-helper';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
	selector: 'app-bookmarks',
	templateUrl: './bookmarks.component.html',
	styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit, OnDestroy {
	bookmarks: Bookmarks;

	tagType: TagType = TagType.All;
	hasLoadedExcerpts = false;

	routeSubscription: Subscription;

	constructor(private bookmarksService: BookmarksService,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private router: Router) { }

	public ngOnInit(): void {
		this.bookmarks = new Bookmarks();

		this.routeSubscription = this.activatedRoute.params.subscribe({
			next: ({ id }) => {
				if (id) {
					this.bookmarksService.getById(id).subscribe({
						next: (response) => {
							this.bookmarks = plainToClass(Bookmarks, response);
						},
						error: (error) => {
							ErrorHelper.handleError(error, this.snackBar);
						}
					});
				}
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public ngOnDestroy(): void {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}

	public onSave(): void {
		this.bookmarksService.save(this.bookmarks).subscribe({
			next: (response) => {
				this.bookmarks = plainToClass(Bookmarks, response);
				this.snackBar.open('Bookmarks saved', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onUpdate(): void {
		this.bookmarksService.update(this.bookmarks).subscribe({
			next: (response) => {
				this.bookmarks = plainToClass(Bookmarks, response);
				this.snackBar.open('Bookmarks updated', 'Dismiss', {
					duration: 1000
				});
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onDelete(): void {
		this.bookmarksService.delete(this.bookmarks.id).subscribe({
			next: () => {
				this.router.navigate(['bookmarks/list']);
			},
			error: (error) => {
				ErrorHelper.handleError(error, this.snackBar);
			}
		});
	}

	public onAddTag(tag: Tag): void {
		this.bookmarks.addTag(tag);
	}

	public onRemoveTag(tag: Tag): void {
		this.bookmarks.removeTag(tag);	
	}

	public onSourceSelected(source: Source): void {
		this.router.navigate(['source', source.id]);
	}

	public onSourceDialog(): void {
		const dialogRef = this.dialog.open(SourceTableDialogComponent);

		dialogRef.afterClosed().subscribe((result: Source[]) => {
			if (result) {
				//
				// filter out duplicates
				const currentSourceIds = new Set(this.bookmarks.sources?.map(src => src.id));
				const newSources = result.filter(source => !currentSourceIds.has(source.id));
				const newSourceIds = newSources.map(source => source.id);

				//
				// if updating an existing bookmarks, use the updateSources API
				if (this.bookmarks.id != null && newSourceIds.length > 0) {
					this.bookmarksService.updateSources(this.bookmarks.id, newSourceIds).subscribe({
						next: () => {
							this.bookmarks.sources = [...this.bookmarks.sources || [], ...newSources];
							this.snackBar.open('Bookmarks updated', 'Dismiss', {
								duration: 1000
							});
						},
						error: (error) => {
							ErrorHelper.handleError(error, this.snackBar);
						}
					});
				}
				else {
					// If bookmarks.id is null, just update the sources list locally.
					this.bookmarks.sources = [...this.bookmarks.sources || [], ...newSources];
				}
			}
		});
	}
	
	public onInfoDialog(): void {
		const dialogRef = this.dialog.open(InfoTableDialogComponent);
		
		dialogRef.afterClosed().subscribe((result: Info[]) => {
			if (result) {
				//
				// filter out duplicates
				const currentInfoIds = new Set(this.bookmarks.sourceBits?.map(info => info.id));
				const newInfos = result.filter(info => !currentInfoIds.has(info.id));
				const newInfoIds = newInfos.map(info => info.id);

				//
				// if updating an existing bookmarks, use the updateSources API
				if (this.bookmarks.id != null && newInfoIds.length > 0) {
					this.bookmarksService.updateSourceBits(this.bookmarks.id, newInfoIds).subscribe({
						next: () => {
							this.bookmarks.sourceBits = [...this.bookmarks.sourceBits || [], ...newInfos];
							this.snackBar.open('Bookmarks updated', 'Dismiss', {
								duration: 1000
							});
						},
						error: (error) => {
							ErrorHelper.handleError(error, this.snackBar);
						}
					});
				}
				else {
					// If bookmarks.id is null, just update the sources list locally.
					this.bookmarks.sourceBits = [...this.bookmarks.sourceBits || [], ...newInfos];
				}
				
				
//				result.forEach(info => {
//					if (!this.bookmarks.sourceBits?.find(infoBit => infoBit.id == info.id)) {
//						infoList.push(info);
//					}
////					const source = copyByProperties(selected.source, ['id', 'title']);
////					const info = copyByProperties(selected, ['id', 'summary',  'detail', 'tags']);
//					
////					info.source = source;
////					infoList.push(info);
//				});
//				
//				this.bookmarks.sourceBits = this.bookmarks.sourceBits || [];
//				this.bookmarks.sourceBits = infoList;
			}
		});
	}
	
	public onTabChanged(event: MatTabChangeEvent): void {
		if (event.index === 1 && !this.hasLoadedExcerpts) {
			if (this.bookmarks.id) {
				this.bookmarksService.getExcerptsById(this.bookmarks.id).subscribe({
					next: (data: Info[]) => {
						this.bookmarks.sourceBits = data;
						this.hasLoadedExcerpts = false;
					},
					error: (error) => {
						ErrorHelper.handleError(error, this.snackBar);
					}
				});
			}
		}
	}
}
