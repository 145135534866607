<div fxLayout="column" fxLayoutAlign="space-evenly start">
	<form class="form-group source-form" fxFill>
		<div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="5px">
			<!-- Tags -->
			<app-taglookup matInput fxFlex="53" fxFlex.xs="100"
				[fxFlex]="(fixedAuthor || fixedOrganization) ? 73 : 53"
				[label]="'Filter by tags...'"
				[readOnly]="false"
				[tags]="filters?.tags"
				[tagType]="tagType" 
				(add)="onAddTag($event)"
				(remove)="onRemoveTag($event)">
			</app-taglookup>
			
			<!--Organization -->
			<app-organization-lookup fxFlex="20" fxHide.xs *ngIf="!fixedOrganization"
				[label]="'Filter by organization...'"
				[organization]="filters.organization"
				(selected)="onOrganization($event)"
				(removed)="onOrganizationRemoved()">
			</app-organization-lookup>
			
			<!--Author -->
			<app-author-lookup fxFlex="20" fxHide.xs *ngIf="!fixedAuthor"
				[label]="'Filter by author...'"
				[authors]="authors"
				(selected)="onAuthor($event)"
				(removed)="onAuthorRemoved($event)">
			</app-author-lookup>

			<!-- Rank -->
			<mat-form-field fxFlex="7" fxHide.xs>
			    <mat-select placeholder="Rank" [(ngModel)]="filters.rank" [ngModelOptions]="{standalone: true}"
			        (selectionChange)="onRankChange($event.value)">
			        <!-- Custom trigger to show icon or "None" -->
			        <mat-select-trigger>
			            <fa-icon *ngIf="filters.rank" [icon]="[getIcon(filters.rank)[0], getIcon(filters.rank)[1]]" [style.color]="getIcon(filters.rank)[2]" size="xl"></fa-icon>
			            <span *ngIf="!filters.rank">None</span>
			        </mat-select-trigger>
			
			        <mat-option value="">None</mat-option>
			        <mat-option *ngFor="let rank of ranks" [value]="rank">
			            <fa-icon [icon]="[getIcon(rank)[0], getIcon(rank)[1]]" [style.color]="getIcon(rank)[2]" size="xl"></fa-icon>
			        </mat-option>
			    </mat-select>
			</mat-form-field>

			<div class="search-button">
				<button mat-button mat-stroked-button (click)="fetch()">
					<fa-icon [icon]="['fas', 'search']"></fa-icon>
				</button>
			</div>
		</div>
	</form>

	<table mat-table [dataSource]="dataSource" class="data-table mat-elevation-z1" style="min-width: 100%">
		<!-- Checkbox Column -->
		<ng-container matColumnDef="select" *ngIf="selectable">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? onSelectedToggle() : null"
					[checked]="isAllSelected()"
					[indeterminate]="!isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="onSelect(row)"
					[checked]="isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<!--reference -->
	    <ng-container matColumnDef="reference" >
	        <th mat-header-cell *matHeaderCellDef> Source </th>
	        <td mat-cell *matCellDef="let source"><a [routerLink]="['/source', source.id]">{{source.title}}</a> </td>
	    </ng-container>
	    
	    <!--organization -->
	    <ng-container matColumnDef="organization" >
	        <th mat-header-cell *matHeaderCellDef  fxHide.xs> Organization </th>
	        <td mat-cell *matCellDef="let source" fxHide.xs> {{source.organization != null ? source.organization.name : ''}} </td>
	    </ng-container>
	    
	    <!--date -->
	    <ng-container matColumnDef="date" >
	        <th mat-header-cell *matHeaderCellDef fxHide.xs> Date </th>
	        <td mat-cell *matCellDef="let source" fxHide.xs> {{source.date | date:'MM/dd/yyyy'}} </td>
	    </ng-container>

		<!--rank -->
	    <ng-container matColumnDef="rank" >
	        <th mat-header-cell *matHeaderCellDef></th>
	        <td mat-cell *matCellDef="let source">
				<fa-icon *ngIf="source?.id && source?.rank"
					[icon]="[getIcon(source.rank)[0], getIcon(source.rank)[1]]"
					[style.color]="getIcon(source.rank)[2]" size="xl">
				</fa-icon>				
			</td>
	    </ng-container>

	    <tr mat-header-row *matHeaderRowDef="sourceColumns"></tr>
	    <tr mat-row *matRowDef="let row; columns: sourceColumns;"></tr>
	    <tr class="mat-row" *matNoDataRow>
 			<td class="mat-cell" [colSpan]="sourceColumns.length">
   				No results found
			</td>
		</tr>
	</table>
	
	<div class="list-pager" fxLayoutAlign="space-between center" fxFlexOffset="1">
		<mat-paginator fxFlexOffset="1"
			[length]="resultsLength"
			[pageSizeOptions]="[10, 20, 50]"
			[pageIndex]="pageIndex"
			(page)="onPage($event)">
		</mat-paginator>

		<ng-template [ngIf]="addable">
			<button mat-stroked-button [routerLink]="['/source/add']">
				<mat-icon>add</mat-icon> Add
			</button>
		</ng-template>
	</div>
</div>