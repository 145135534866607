import { Component, Input, OnInit } from '@angular/core';

import { Info } from 'src/app/model/info';

@Component({
	selector: 'app-info-list',
	templateUrl: './info-list.component.html',
	styleUrls: ['./info-list.component.scss']
})
export class InfoListComponent implements OnInit {
	@Input()
	infoList: Info[] = [];
	
	constructor() { }

	ngOnInit(): void {
	}

	public onClick(event: any, index: number): void {
		event.stopPropagation();
		this.infoList.splice(index, 1);
	}
}
