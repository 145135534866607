import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/service/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { concatMap, finalize, switchMap, throwError } from 'rxjs';
import { plainToClass } from 'class-transformer';

import { StorageService } from 'src/app/service/storage.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { LoginService } from 'src/app/service/login.service';

import { Category } from 'src/app/model/category';
import { Source } from 'src/app/model/source';
import { CategoryCommonComponent } from './category-common.compnent';
import { Reputation } from 'src/app/model/reputation';
import { AuthorReportCard, OrganizationReportCard, DisplayType } from 'src/app/model/report-card';

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss', './category-common.component.scss']
})
export class CategoryComponent extends CategoryCommonComponent implements OnInit {
	
	authorReportCard: AuthorReportCard;
	organzationReportCard: OrganizationReportCard;
	
	public getIcon = Reputation.getIcon;
	
	top: DisplayType = DisplayType.Top
	bottom: DisplayType = DisplayType.Bottom;
	
	constructor(protected categoryService: CategoryService,
				protected storageService: StorageService,
				protected snackBar: MatSnackBar,
				protected navigationService: NavigationService,
				protected loginService: LoginService,
				protected activatedRoute: ActivatedRoute,
				protected router: Router) { 
		super(categoryService, storageService, snackBar, navigationService, activatedRoute, router);
	}

	ngOnInit(): void {
		//
		// if coming from a back button restore filters and page, otherwise clear properties
		if (this.navigationService.isBackButton()) {
			this.clearCategoryFilters();
		}

		this.activatedRoute.params.pipe(
			switchMap(params => {
				const id = params['id'];
				return id ? this.categoryService.getById(Number(id)) : throwError('Invalid ID');
			}),
			concatMap(categoryResponse => {
				this.category = categoryResponse ? plainToClass(Category, categoryResponse) : new Category();
				this.setCategoryFilters(this.category.tags);

				return this.categoryService.getTreeNodesById(this.category.treeId);
			}),
			concatMap(treeNodeResponse => {
				this.processTreeNodeResponse(treeNodeResponse);
				const categoryId = this.category ? this.category.id : null;
				return categoryId ? this.categoryService.getOrganizationsReportCard(categoryId) : throwError('Invalid Category ID');
			})
		).subscribe({
			next: (reportCardResponse) => {
				this.organzationReportCard = reportCardResponse;
				this.done();
			},
			error: (error) => this.handleError(error)
		});
	}

	public isLoggedIn(): boolean {
		return this.loginService.isLoggedIn();
	}

	public onEdit(): void {
		this.router.navigate(['category/edit', this.category.id]);
	}

	public onTabChanged(index: number) {
		//
		// load author report card
		if (index == 1 && !this.authorReportCard) {
			this.start();
			this.categoryService.getAuthorsReportCard(this.category.id)
				.pipe(
					finalize(() => this.done())
				)
				.subscribe({
					next: (response) => {
						this.authorReportCard = response;
					},
					error: (error) => this.handleError(error)
				});
		}
	}

	public onSourceSelected(source: Source): void {
		this.router.navigate(['source', source.id]);
	}

}
