import { Directive, Input, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from 'src/app/component/confirm-dialog/confirm-dialog.component';
import { Source } from 'src/app/model/source';

@Directive({
	selector: '[confirm-navigate]'
})
export class ConfirmNavigateDirective {
	@Input() source: Source

	constructor(private dialog: MatDialog) { }

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent): void {
		event.preventDefault(); // Prevent the default link navigation

		//
		// If an altReference is provided, confirm with the user first
		if (this.source.altReference) {
			const dialogRef = this.dialog.open(ConfirmDialogComponent, {
				width: '250px',
				data: { message: "Do you want to navigate to the archived link?" }
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					window.open(this.source.altReference, '_blank', 'noopener,noreferrer');
				}
				else {
					window.open(this.source.reference, '_blank', 'noopener,noreferrer');
				}
			});
		}
		else {
			// If no altReference, navigate directly
			window.open(this.source.reference, '_blank', 'noopener,noreferrer');
		}
	}
}
