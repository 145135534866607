<div class="page-header">
	<h3>Category</h3>
</div>
<div fxLayout="column" fxLayoutAlign="space-evenly start">
	<div fxLayout="row" fxLayoutAlign="space-evenly start" fxLayoutGap="10px" fxFill>
		<form class="form-group source-form" fxFlex="80" fxFlex.xs="100">
			<div fxLayout="column" fxLayoutAlign="space-evenly start">
				<mat-form-field class="source-field">
					<input matInput placeholder="Name" [(ngModel)]="category.name" [ngModelOptions]="{standalone: true}" [readonly]="true" >
				</mat-form-field>
			 
				<mat-form-field class="source-field">
					<textarea	matInput placeholder="Description" [(ngModel)]="category.description"
						[ngModelOptions]="{standalone: true}" style="resize: none" [readonly]="true">
					</textarea>
				</mat-form-field>
			</div>
	
			<!-- tags -->
			<app-taglookup matInput [tags]="category.tags"></app-taglookup>
		
			<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" fxFill>
				<button *ngIf="category.id != null && isLoggedIn()" mat-stroked-button color="primary" type="button" (click)="onEdit()">
					<em class="fa fa-plus"></em> Edit
				</button>
			</div>
		</form>
		
		<div class="category-tree-container" fxFlex="20"fxHide.xs>
			<span class="section-title">Category Tree</span>
			<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="category-tree">
				<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
					<span [class.category-tree-current-node]="node.categoryId == category.id" class="category-tree-node" (click)="onCategoryClick(node.categoryId)">
						{{node.name}}
					</span>
				</mat-tree-node>
			
				<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
				    <div class="mat-tree-node">
				      <button mat-icon-button matTreeNodeToggle
				              [attr.aria-label]="'Toggle ' + node.name">
				        <mat-icon class="mat-icon-rtl-mirror">
				          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
				        </mat-icon>
				      </button>
				      <span [class.category-tree-current-node]="node.categoryId == category.id" class="category-tree-node" (click)="onCategoryClick(node.categoryId)">
				      	{{node.name}}
				      </span>
				    </div>
					<div [class.category-tree-invisible]="!treeControl.isExpanded(node)" role="group">
						<ng-container matTreeNodeOutlet></ng-container>
					</div>
				</mat-nested-tree-node>
			</mat-tree>	
		</div>
	</div>
	
	<mat-tab-group fxFill (selectedIndexChange)="onTabChanged($event)">
		<!-- Organizations report card -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>category</mat-icon>&nbsp; Organizations
			</ng-template>
			<ng-template matTabContent>
				<div *ngIf="(isLoading | async) == false"
					class="report-card-container"
					fxLayout="row" fxLayout.xs="column"
					fxLayoutAlign="center start"
					fxLayoutAlign.xs="start stretch"
					fxLayoutGap="20px"
					fxLayoutGap.xs="10px"> 
					<!-- Top authors -->
					<div class="report-card" fxFlex="35" fxFlex.lt-md="100%">
						<div class="table-header">
							Top Organizations
						</div>
						<app-category-report-card [reportCard]="organzationReportCard" [displayType]="top"></app-category-report-card>
					</div>
	
					<!-- Bottom authors -->
					<div class="report-card" fxFlex="35" fxFlex.lt-md="100%">
						<div class="table-header">
							Worst Organizations
						</div>
							<app-category-report-card [reportCard]="organzationReportCard" [displayType]="bottom"></app-category-report-card>
					</div>
				</div>
			</ng-template>		
		</mat-tab>

		<!-- Authors report card-->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>history_edu</mat-icon>&nbsp; Authors
			</ng-template>
			<ng-template matTabContent>
				<div *ngIf="(isLoading | async) == false"
					class="report-card-container"
					fxLayout="row" fxLayout.xs="column"
					fxLayoutAlign="center start"
					fxLayoutAlign.xs="start stretch"
					fxLayoutGap="20px"
					fxLayoutGap.xs="10px">  
					<!-- Top authors -->
					<div class="report-card" fxFlex="35" fxFlex.xs="100%">
						<div class="table-header">
							Top Authors
						</div>
							<app-category-report-card [reportCard]="authorReportCard" [displayType]="top"></app-category-report-card>
					</div>
	
					<!-- Bottom authors -->
					<div class="report-card" fxFlex="35" fxFlex.xs="100%">
						<div class="table-header">
							Worst Authors
						</div>
						<app-category-report-card [reportCard]="authorReportCard" [displayType]="bottom"></app-category-report-card>
					</div>
				</div>
			</ng-template>		
		</mat-tab>

		<!-- sources -->
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>article</mat-icon>&nbsp; Sources
			</ng-template>
			<ng-template matTabContent >
				<app-source-table *ngIf="(isLoading | async) == false" [filtersProperty]="filtersProperty" [tags]="category.tags"></app-source-table>
			</ng-template>
		</mat-tab>
	</mat-tab-group>	
</div>
