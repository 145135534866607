<mat-form-field class="source-field">
	<mat-label>{{label}}</mat-label>
	<mat-chip-grid #tagList>
		<mat-chip-row *ngFor="let tag of tags"
			[removable]="!readOnly"
			(removed)="removeTag(tag)"
			[selectable]="true"
			(click)="onClick(tag)">
			{{tag.name}}
			<mat-icon *ngIf="!readOnly" matChipRemove>cancel</mat-icon>
		</mat-chip-row>
		<input #tagsInput placeholder="Search tags..."
			(keyup.enter)="onEnter()"
			[formControl]="tagsControl"
			[readonly]="readOnly"
			[matAutocomplete]="tagsComponentAutocomplete"
			[matChipInputFor]="tagList"
			[matChipInputSeparatorKeyCodes]="separatorKeyCodes"
			[matChipInputAddOnBlur]="true"
			(matChipInputTokenEnd)="addTag($event)" />
	</mat-chip-grid>
	
	<mat-autocomplete #tagsComponentAutocomplete="matAutocomplete" (optionSelected)="onTagSelected($event)">
		<mat-option *ngFor="let tag of tagsObs | async" [value]="tag">
			{{tag.name}}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>