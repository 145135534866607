export function encode(reference: string) : string {
	const id = reference.toLowerCase().split('').reduce(function(a, b) {
		a = ((a << 5) - a) + b.charCodeAt(0); return a & a}, 0);

	return Math.abs(id).toString(32);
}

export function copyByProperties<T> (object: T, props: string[]) : T {
	let objectCopy = <T>{};
	
	for (let prop of props) {
		if (object.hasOwnProperty(prop)) {
			objectCopy[prop] = object[prop];
		}
	}
//	for (var key in object) {
//		if (object.hasOwnProperty(key)) {
//			objectCopy[key] = object[key];
//		}
//	}
	
	return objectCopy;
}