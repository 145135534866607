<h3>Reference</h3>
<app-spinner *ngIf="inProgress" overlay="true"></app-spinner>
<div >
	<form class="form-group" fxLayout="column" fxLayoutAlign="space-evenly start" style="width: 80%">
		<!-- Reference -->
		<mat-form-field class="source-field">
			<input matInput placeholder="Reference" [(ngModel)]="reference.reference"
				[ngModelOptions]="{standalone: true}">
		</mat-form-field>

		<!-- Title -->
		<mat-form-field class="source-field">
			<input matInput placeholder="Title" [(ngModel)]="reference.title"
				[ngModelOptions]="{standalone: true}">
			<button *ngIf="isLoggedIn()" [disabled]="!reference.reference" mat-button matSuffix mat-stroked-button (click)="onFetchTitle()">Fetch</button>
		</mat-form-field>

		<!-- tags -->
		<app-taglookup 
			matInput
			[tagType]="tagType"
			[tags]="reference.tags"
			[readOnly]="!isLoggedIn()"
			(add)="onAddTag($event)"
			(remove)="onRemoveTag($event)">
		</app-taglookup>

		<div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="5px">
			<!-- Status -->
			<mat-form-field>
				<mat-select placeholder="Status" [(ngModel)]="reference.status" [ngModelOptions]="{standalone: true}">
					<mat-option	*ngFor="let type of status" [value]="type.value">{{type.value}}</mat-option>
				</mat-select>
			</mat-form-field>
	
			<!-- Rank -->
			<mat-form-field>
				<mat-select placeholder="Rank" [(ngModel)]="reference.rank" [ngModelOptions]="{standalone: true}">
					<mat-option>None</mat-option>
					<mat-option	*ngFor="let rank of ranks" [value]="rank">{{rank}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		
		
		<div *ngIf="isLoggedIn()" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" fxFill>
			<button *ngIf="reference.id == null" mat-stroked-button color="primary" (click)="onSave()">
				<mat-icon>save</mat-icon> Save
			</button>
			<ng-container *ngIf="reference.id != null">
				<button mat-stroked-button color="primary" (click)="onUpdate()">
					<mat-icon>save</mat-icon> Update
				</button>

				<button mat-stroked-button type="button" (click)="onAddToSources()">
					<mat-icon>add</mat-icon> As Source
				</button>
				
				<button mat-stroked-button type="button" confirm-dialog (confirmed)="onDelete()">
					<mat-icon>delete</mat-icon> Delete
				</button>

				<button mat-stroked-button type="button" (click)="onCancel()">
					<mat-icon>cancel</mat-icon> Cancel
				</button>

			</ng-container>
		</div>	
	</form>
</div>
