<h3>References</h3>
<div fxLayout="column" fxLayoutAlign="space-evenly start" >
	<form fxFill >
		<div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="5px">
			<!-- Tags -->
			<app-taglookup matInput fxFlex="70"
				[tags]="filters?.tags"
				[tagType]="tagType"
				[readOnly]="false"
				(add)="onAddTag($event)"
				(remove)="onRemoveTag($event)">
			</app-taglookup>
				
			<!-- Status -->
			<mat-form-field fxFlex="15">
				<mat-select placeholder="Status" [(ngModel)]="filters.status" [ngModelOptions]="{standalone: true}"
					(selectionChange)="onStatusChange($event.value)">
					<mat-option value="">None</mat-option>
					<mat-option	*ngFor="let type of statusList" [value]="type.value">{{type.value}}</mat-option>
				</mat-select>
			</mat-form-field>

			<!-- Rank -->
			<mat-form-field fxFlex="15">
				<mat-select placeholder="Rank" [(ngModel)]="filters.rank" [ngModelOptions]="{standalone: true}"
					(selectionChange)="onRankChange($event.value)">
					<mat-option value="">None</mat-option>
					<mat-option	*ngFor="let rank of ranks" [value]="rank">{{rank}}</mat-option>
				</mat-select>
			</mat-form-field>

			<button mat-button mat-stroked-button (click)="filterByTags()">
				<mat-icon>search</mat-icon>
			</button>
		</div>
	</form>

	<app-spinner *ngIf="isLoading" [overlay]=true>	</app-spinner>
	<table fxFlex="auto" mat-table [dataSource]="dataSource" class="data-table mat-elevation-z1">
	    <ng-container matColumnDef="reference">
	        <th mat-header-cell *matHeaderCellDef> Reference </th>
	        <td mat-cell *matCellDef="let reference"> {{reference.title}} </td>
	    </ng-container>
	
	    <ng-container matColumnDef="tags">
			<th mat-header-cell *matHeaderCellDef fxHide.xs> Tags </th>
			<td mat-cell *matCellDef="let reference" fxHide.xs>
			{{reference.tags}}
<!-- 				<mat-chip-list> -->
<!-- 					<ng-container *ngFor="let tag of reference.tags"> -->
<!-- 						<mat-chip color="primary">{{tag}}</mat-chip> -->
<!-- 					</ng-container> -->
<!-- 				</mat-chip-list> -->
			</td>
	    </ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef> Actions </th>
			<td mat-cell *matCellDef="let reference">
				<a (click)="onAddToSources(reference)">
					<mat-icon aria-hidden="false" aria-label="Plus" size="20">add</mat-icon>
				</a>
			</td>
		</ng-container>

	    <tr mat-header-row *matHeaderRowDef="columns"></tr>
	    <tr mat-row [routerLink]="['/reference', row.id]" *matRowDef="let row; columns: columns"></tr>
	</table>

	<div class="list-pager" fxLayoutAlign="space-between center" fxFlexOffset="1">
		<mat-paginator
			[length]="resultsLength"
			[pageSizeOptions]="[10, 20, 50]"
			[pageIndex]="pageIndex"
			(page)="onPage($event)">
		</mat-paginator>
		
		<button *ngIf="isLoggedIn()" mat-stroked-button [routerLink]="['/reference/add']">
			<mat-icon>add</mat-icon> Add
		</button>
	</div>
</div>
