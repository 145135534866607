<div class="page-header">
	<h3>Author</h3>
</div>
<div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
    <!-- Fields and Report Card -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="50px" fxLayoutGap.xs="10px">
        <!-- Fields Container -->
        <div fxFlex="50" fxLayout="column">
            <!-- Form containing Name and Description Fields -->
            <form fxLayout="column" fxLayoutGap="10px">
                <!-- Name Fields in a Row -->
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
                    <mat-form-field fxFlex fxHide.xs>
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="First Name" value="{{author.firstName}}" readonly>
                    </mat-form-field>

                    <mat-form-field fxFlex fxHide.xs>
                        <mat-label>Middle Name</mat-label>
                        <input matInput placeholder="Middle Name" value="{{author.middleName}}" readonly>
                    </mat-form-field>

                    <mat-form-field fxFlex fxHide.xs>
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Last Name" value="{{author.lastName}}" readonly>
                    </mat-form-field>
                    
					<mat-form-field fxFlex fxShow="false" fxShow.xs="true">
						<mat-label>Name</mat-label>
						<input matInput placeholder="Full Name" [value]="author | author" readonly>
					</mat-form-field>
                </div>

                <!-- Description Field -->
                <mat-form-field fxFlex>
                    <mat-label>Description</mat-label>
                    <textarea matInput placeholder="Description" [(ngModel)]="author.description" [ngModelOptions]="{standalone: true}">
                    </textarea>
                </mat-form-field>

                <!-- Edit Button -->
                <div *ngIf="isLoggedIn()" fxLayout="row" fxLayoutAlign="end" style="padding-top: 10px;">
                    <button mat-stroked-button type="button" [routerLink]="['/author/edit', author.id]">
                        <mat-icon>edit</mat-icon> Edit
                    </button>
                </div>
            </form>

        </div>

        <!-- Report Card -->
        <div fxFlex="50">
            <app-report-card [reputationList]="author.reputationList" [editable]="false"></app-report-card>
        </div>
    </div>

    <!-- Sources Section -->
    <div *ngIf="author.id" fxFill>
        <span class="section-title-small">Sources</span>
        <app-source-table [author]="author" [filtersProperty]="filtersProperty" [lazy]="true" fxFill></app-source-table>
    </div>
</div>
