import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorComponent } from './author.component';
import { AuthorListComponent } from './author-list.component';
import { AuthorEditComponent } from './author-edit.component';

const routes: Routes = [
	{ path: 'author/list', component: AuthorListComponent },
	{ path: 'author/add', component: AuthorEditComponent },
	{ path: 'author/:id', component: AuthorComponent },
	{ path: 'author/edit/:id', component: AuthorEditComponent }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})
export class AuthorRoutingModule { }